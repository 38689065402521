import { PermissionModule } from 'src/app/shared/models/index-models';
import { LOGIN_PERMISSION_MODULE, DASHBOARD_PERMISSION_MODULE, SYSTEM_USER_PERMISSION_MODULE, TRANSACTION_PERMISSION_MODULE, INVENTORY_PERMISSION_MODULE, MASTER_DATA_PERMISSION_MODULE, PROGRAM_PERMISSION_MODULE, REPORT_PERMISSION_MODULE, SETTING_PERMISSION_MODULE } from './permission-module/index-permission-module';

export const PERMISSION_MODULES: PermissionModule[] = [
    /*==================================================
    =.chb-view for view, .auto-chb-view for others that need auto viewable
    ==================================================*/
    /*==================================================
    = The order of each module based on the navigation menu of the portal.
    ==================================================*/
    ...LOGIN_PERMISSION_MODULE,
    ...DASHBOARD_PERMISSION_MODULE,
    ...SYSTEM_USER_PERMISSION_MODULE,
    ...TRANSACTION_PERMISSION_MODULE,
    ...INVENTORY_PERMISSION_MODULE,
    ...MASTER_DATA_PERMISSION_MODULE,
    ...PROGRAM_PERMISSION_MODULE,
    ...REPORT_PERMISSION_MODULE,
    ...SETTING_PERMISSION_MODULE,
];
