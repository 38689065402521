import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Profile } from 'src/app/shared/app-state/profile.model';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    errMsg = null;
    profile: Profile;
    constructor(private fb: FormBuilder, private _loginService: LoginService) {}

    ngOnInit() {
        this.createLoginForm();
    }

    createLoginForm() {
        this.loginForm = this.fb.group({
            userCode: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    form(control) {
        return this.loginForm.get(control).value;
    }

    onLoginBtn() {
        this.submitted = true;
        this.errMsg = null;
        if (this.submitted && this.loginForm.invalid) {
            return;
        }
        const body = {
            username: this.form('userCode').trim(),
            password: this.form('password').trim(),
            type: 'DMS'
        };
        this._loginService.requestToken().subscribe(
            (res: any) => {
                const auth = `Bearer ${res.token}`;
                this.login(auth, body);
            },
            (err) => (this.errMsg = err.error.message)
        );
    }

    login(token: string, body: object) {
        this._loginService.login(token, body).subscribe(
            async (res: any) => {
                this.profile = await res;

                /***** Set Token to LocalStorage *****/
                localStorage.setItem('token', `Bearer ${this.profile.accessToken}`);
                window.location.href = '/';
            },
            (error) => {
                this.errMsg = error.error.message;
            }
        );
    }
}
