import { PermissionModule } from "src/app/shared/models/role-permission";
import { environment } from "src/environments/environment";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

const env = environment.env;

export const MASTER_DATA_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    MASTER DATA TITLE
    ==================================================*/
    {
        id: 'MASTER-DATA-TITLE',
        name: 'Master Data',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    MASTER DATA (OUTLET)
    ==================================================*/
    {
        id: 'MASTER-OUTLET',
        name: 'Outlet',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-OUTLET-LIST',
                name: 'Outlet List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-OUTLET-READ',
                        name: 'View',
                        code: CODE.OUTLET_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-OUTLET-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-OUTLET-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-OUTLET-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /*==================================================
            NEW OUTLET REQUEST
            ==================================================*/
            {
                id: 'NEW-OUTLET-REQUEST',
                name: 'New Outlet Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET',
                        name: 'Request List',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET-READ',
                                name: 'View',
                                code: CODE.NEW_OUTLET_REQUEST_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET-UPDATE',
                                name: 'Edit',
                                code: CODE.NEW_OUTLET_REQUEST_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET-HISTORY',
                        name: 'History',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET-HISTORY-READ',
                                name: 'View',
                                code: CODE.NEW_OUTLET_REQUEST_HISTORY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-APPROVAL-REQUEST-NEW-OUTLET-HISTORY-UPDATE',
                                name: 'Edit',
                                code: CODE.NEW_OUTLET_REQUEST_HISTORY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            {
                id: 'MASTER-OUTLET-GROUP',
                name: 'Outlet Group Program',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-GROUP-READ',
                        name: 'View',
                        code: CODE.OUTLET_GROUP.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-GROUP-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_GROUP.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-GROUP-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_GROUP.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-GROUP-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_GROUP.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'MASTER-OUTLET-CONTRACT',
                name: 'Outlet Contract',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-CONTRACT-READ',
                        name: 'View',
                        code: CODE.OUTLET_CONTRACT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-CONTRACT-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_CONTRACT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-CONTRACT-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_CONTRACT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (DEALER)
    ==================================================*/
    {
        id: 'MASTER-DEALER-LIST',
        name: 'Dealer',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-FIRM-DEALER-READ',
                name: 'View',
                code: CODE.DEALER_LIST.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-FIRM-DEALER-CREATE',
                name: 'Create',
                code: CODE.DEALER_LIST.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-FIRM-DEALER-UPDATE',
                name: 'Edit',
                code: CODE.DEALER_LIST.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-FIRM-DEALER-DELETE',
                name: 'Active/Inactive',
                code: CODE.DEALER_LIST.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    MASTER DATA (DOWNLINE)
    ==================================================*/
    {
        id: 'MASTER-DOWNLINE-LIST',
        name: 'Downline',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-EMPLOYEE-DOWNLINE-READ',
                name: 'View',
                code: CODE.DOWNLINE_LIST.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-EMPLOYEE-DOWNLINE-CREATE',
                name: 'Create',
                code: CODE.DOWNLINE_LIST.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-EMPLOYEE-DOWNLINE-UPDATE',
                name: 'Edit',
                code: CODE.DOWNLINE_LIST.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-EMPLOYEE-DOWNLINE-DELETE',
                name: 'Active/Inactive',
                code: CODE.DOWNLINE_LIST.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    MASTER DATA (EMPLOYEE)
    ==================================================*/
    {
        id: 'MASTER-EMPLOYEE',
        name: 'Employee',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-EMPLOYEE-LIST',
                name: 'Employee List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-EMPLOYEE-EMPLOYEE-READ',
                        name: 'View',
                        code: CODE.EMPLOYEE_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-EMPLOYEE-EMPLOYEE-CREATE',
                        name: 'Create',
                        code: CODE.EMPLOYEE_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-EMPLOYEE-EMPLOYEE-UPDATE',
                        name: 'Edit',
                        code: CODE.EMPLOYEE_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-EMPLOYEE-EMPLOYEE-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.EMPLOYEE_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'MASTER-DEVICE-CONTRACT',
                name: 'Device Contract',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-DEVICE-CONTRACT-READ',
                        name: 'View',
                        code: CODE.EMPLOYEE_DEVICE_CONTRACT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-DEVICE-CONTRACT-CREATE',
                        name: 'Create',
                        code: CODE.EMPLOYEE_DEVICE_CONTRACT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-DEVICE-CONTRACT-UPDATE',
                        name: 'Edit',
                        code: CODE.EMPLOYEE_DEVICE_CONTRACT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (CALL PLAN)
    ==================================================*/
    {
        id: 'MASTER-CALL-PLAN',
        name: 'Call Plan',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** CALL PLAN LIST *****/
            {
                id: 'MASTER-CALL-PLAN-LIST',
                name: 'Call Plan List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-COVERAGE-CALL-PLAN-READ',
                        name: 'View',
                        code: CODE.CALL_PLAN_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-COVERAGE-CALL-PLAN-CREATE',
                        name: 'Create',
                        code: CODE.CALL_PLAN_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-COVERAGE-CALL-PLAN-UPDATE',
                        name: 'Edit',
                        code: CODE.CALL_PLAN_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-COVERAGE-CALL-PLAN-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.CALL_PLAN_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** AUDIT PLAN LIST *****/
            {
                id: 'MASTER-AUDIT-PLAN-LIST',
                name: 'Audit Plan List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-SCHEDULE-AUDIT-PLAN-READ',
                        name: 'View',
                        code: CODE.AUDIT_PLAN_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-SCHEDULE-AUDIT-PLAN-CREATE',
                        name: 'Create',
                        code: CODE.AUDIT_PLAN_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-SCHEDULE-AUDIT-PLAN-UPDATE',
                        name: 'Edit',
                        code: CODE.AUDIT_PLAN_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-SCHEDULE-AUDIT-PLAN-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.AUDIT_PLAN_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** MANAGE ROUTE *****/
            {
                id: 'MASTER-CALL-PLAN-MANAGE-ROUTE',
                name: 'Manage Route',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    /***** PLAN BY DAY *****/
                    {
                        id: 'MASTER-CALL-PLAN-BY-DAY',
                        name: 'Plan by Day',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-COVERAGE-PLAN-BY-DAY-READ',
                                name: 'View',
                                code: CODE.CALL_PLAN_BY_DAY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    /***** PLAN BY DATE *****/
                    {
                        id: 'MASTER-CALL-PLAN-BY-DATE',
                        name: 'Plan by Date',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-SCHEDULE-PLAN-BY-DATE-READ',
                                name: 'View',
                                code: CODE.CALL_PLAN_BY_DATE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-SCHEDULE-PLAN-BY-DATE-UPDATE',
                                name: 'Edit',
                                code: CODE.CALL_PLAN_BY_DATE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    /***** COVERAGE *****/
                    {
                        id: 'MASTER-CALL-PLAN-COVERAGE',
                        name: 'Coverage',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-OUTLET-COVERAGE',
                                name: 'View',
                                code: CODE.CALL_PLAN_COVERAGE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    /***** DAY OFF & HOLIDAY *****/
                    {
                        id: 'MASTER-CALL-PLAN-DAY-OFF',
                        name: 'Day Off and Holiday',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-HOLIDAY-READ',
                                name: 'View',
                                code: CODE.CALL_PLAN_HOLIDAY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-HOLIDAY-CREATE',
                                name: 'Create',
                                code: CODE.CALL_PLAN_HOLIDAY.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-HOLIDAY-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.CALL_PLAN_HOLIDAY.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (MATERIAL)
    ==================================================*/
    {
        id: 'MASTER-MATERIAL',
        name: 'Material',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /*==================================================
            MASTER DATA (PRODUCT)
            ==================================================*/
            {
                id: 'MASTER-PRODUCT',
                name: 'Product',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-PRODUCT-LIST',
                        name: 'Product List',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-PRODUCT-READ',
                                name: 'View',
                                code: CODE.PRODUCT_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PRODUCT-CREATE',
                                name: 'Create',
                                code: CODE.PRODUCT_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PRODUCT-UPDATE',
                                name: 'Edit',
                                code: CODE.PRODUCT_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PRODUCT-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.PRODUCT_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-PRODUCT-GROUP',
                        name: 'Product Group',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-GROUP-READ',
                                name: 'View',
                                code: CODE.PRODUCT_GROUP.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-GROUP-CREATE',
                                name: 'Create',
                                code: CODE.PRODUCT_GROUP.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-GROUP-UPDATE',
                                name: 'Edit',
                                code: CODE.PRODUCT_GROUP.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-GROUP-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.PRODUCT_GROUP.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },

            /*==================================================
            MASTER DATA (COMPONENT)
            ==================================================*/
            {
                id: 'MASTER-COMPONENT',
                name: 'Component',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-COMPONENT-ASSET',
                        name: 'Asset',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-ASSET-READ',
                                name: 'View',
                                code: CODE.ASSET_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-ASSET-CREATE',
                                name: 'Create',
                                code: CODE.ASSET_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-ASSET-UPDATE',
                                name: 'Edit',
                                code: CODE.ASSET_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-ASSET-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.ASSET_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-COMPONENT-POSM',
                        name: 'POSM',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-POSM-READ',
                                name: 'View',
                                code: CODE.POSM_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-POSM-CREATE',
                                name: 'Create',
                                code: CODE.POSM_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-POSM-UPDATE',
                                name: 'Edit',
                                code: CODE.POSM_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-POSM-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.POSM_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-COMPONENT-KEG',
                        name: 'Empty Keg/Bottle',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-EMPTY-KEG-READ',
                                name: 'View',
                                code: CODE.EMPTY_KEG_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-EMPTY-KEG-CREATE',
                                name: 'Create',
                                code: CODE.EMPTY_KEG_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-EMPTY-KEG-UPDATE',
                                name: 'Edit',
                                code: CODE.EMPTY_KEG_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-EMPTY-KEG-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.EMPTY_KEG_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-COMPONENT-RING',
                        name: 'Ring Pull',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-REDEEM-ITEM-READ',
                                name: 'View',
                                code: CODE.RING_PULL_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-REDEEM-ITEM-CREATE',
                                name: 'Create',
                                code: CODE.RING_PULL_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-REDEEM-ITEM-UPDATE',
                                name: 'Edit',
                                code: CODE.RING_PULL_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-REDEEM-ITEM-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.RING_PULL_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-COMPONENT-PALLET',
                        name: 'Pallet',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-PALLET-READ',
                                name: 'View',
                                code: CODE.PALLET_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PALLET-CREATE',
                                name: 'Create',
                                code: CODE.PALLET_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PALLET-UPDATE',
                                name: 'Edit',
                                code: CODE.PALLET_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-PALLET-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.PALLET_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-COMPONENT-SIGNAGE',
                        name: 'Signage',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-SIGNAGE-READ',
                                name: 'View',
                                code: CODE.SIGNAGE_LIST.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-SIGNAGE-CREATE',
                                name: 'Create',
                                code: CODE.SIGNAGE_LIST.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-SIGNAGE-UPDATE',
                                name: 'Edit',
                                code: CODE.SIGNAGE_LIST.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-SIGNAGE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.SIGNAGE_LIST.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },

            /*==================================================
            MASTER DATA (DEVICE)
            ==================================================*/
            {
                id: 'MASTER-DEVICE',
                name: 'Device',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-DEVICE-ASSET-READ',
                        name: 'View',
                        code: CODE.DEVICE_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-DEVICE-ASSET-CREATE',
                        name: 'Create',
                        code: CODE.DEVICE_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-DEVICE-ASSET-UPDATE',
                        name: 'Edit',
                        code: CODE.DEVICE_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-DEVICE-ASSET-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.DEVICE_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },

            /*==================================================
            MASTER DATA (SAP MATERIAL)
            ==================================================*/
            {
                id: 'MASTER-SAP-MATERIAL',
                name: 'SAP Material',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PRODUCT-SAP-READ',
                        name: 'View',
                        code: CODE.SAP_MATERIAL_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-SAP-CREATE',
                        name: 'Create',
                        code: CODE.SAP_MATERIAL_LIST.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-SAP-UPDATE',
                        name: 'Edit',
                        code: CODE.SAP_MATERIAL_LIST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-SAP-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.SAP_MATERIAL_LIST.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (KPI TARGET)
    ==================================================*/
    {
        id: 'MASTER-KPI-TARGET',
        name: 'KPI Target',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-KPI-TARGET-DEALER',
                name: 'Dealer',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-KPI-TARGET-DEALER-VOLUMN',
                        name: 'Sale Volume & Sale Amount',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-KPI-TARGET-DEALER-SALE-READ',
                                name: 'View',
                                code: CODE.KPI_DEALER_VOLUME.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-KPI-TARGET-DEALER-SALE-CREATE',
                                name: 'Create',
                                code: CODE.KPI_DEALER_VOLUME.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-KPI-TARGET-DEALER-POSM',
                        name: 'POSM Target',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-KPI-TARGET-DEALER-POSM-READ',
                                name: 'View',
                                code: CODE.KPI_DEALER_POSM.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-KPI-TARGET-DEALER-POSM-CREATE',
                                name: 'Create',
                                code: CODE.KPI_DEALER_POSM.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            {
                id: 'MASTER-KPI-TARGET-SALES-REP',
                name: 'Downline',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-KPI-TARGET-SALES-REP-VOLUMN',
                        name: 'Sale Volume & Sale Amount',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-KPI-TARGET-SALES-REP-SALE-READ',
                                name: 'View',
                                code: CODE.KPI_DOWNLINE_VOLUME.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-KPI-TARGET-SALES-REP-SALE-CREATE',
                                name: 'Create',
                                code: CODE.KPI_DOWNLINE_VOLUME.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (MOA)
    ==================================================*/
    {
        id: 'MASTER-MOA',
        name: 'MOA',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-POSITION-BUDGET-READ',
                name: 'View',
                code: CODE.MOA_LIST.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-POSITION-BUDGET-CREATE',
                name: 'Create',
                code: CODE.MOA_LIST.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-POSITION-BUDGET-UPDATE',
                name: 'Edit',
                code: CODE.MOA_LIST.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-POSITION-BUDGET-DELETE',
                name: 'Active/Inactive',
                code: CODE.MOA_LIST.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    MASTER DATA (OUTLET PRICE GROUP) 
    ==================================================*/
    {
        id: 'MASTER-OUTLET-PRICE-GROUP',
        name: 'Outlet Price Group',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-OUTLET-PRICE-GROUP-LIST',
                name: 'Price Group',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-READ',
                        name: 'View',
                        code: CODE.OUTLET_PRICE_GROUP.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_PRICE_GROUP.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_PRICE_GROUP.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_PRICE_GROUP.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'MASTER-OUTLET-PRICE-GROUP-MANAGE',
                name: 'Manage Price',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-MANAGE-READ',
                        name: 'View',
                        code: CODE.OUTLET_MANAGE_PRICE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-MANAGE-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_MANAGE_PRICE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-MANAGE-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_MANAGE_PRICE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-MANAGE-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_MANAGE_PRICE.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'MASTER-OUTLET-PRICE-GROUP-EFFECTIVE',
                name: 'Manage Price Effective',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-PRODUCT-PENDING-READ',
                        name: 'View',
                        code: CODE.OUTLET_MANAGE_PRICE_EFFECTIVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-PRODUCT-PENDING-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_MANAGE_PRICE_EFFECTIVE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-PRICE-GROUP-PRODUCT-PENDING-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_MANAGE_PRICE_EFFECTIVE.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    MASTER DATA (MAP)
    ==================================================*/
    {
        id: 'MASTER-MAP',
        name: 'Map',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-MAP-READ',
                name: 'View',
                code: CODE.MAP_LIVE.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /* ===== Don't remove this, FUTURE ENHANCEMENT ===== */
    /*==================================================
    MASTER DATA (TRADE ASSET)
    ==================================================*/
    {
        id: 'MASTER-TRADE-ASSET',
        name: 'Trade Asset',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-TRADE-ASSET-READ',
                name: 'View',
                code: CODE.FRIDGE.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-TRADE-ASSET-CREATE',
                name: 'Create',
                code: CODE.FRIDGE.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-TRADE-ASSET-UPDATE',
                name: 'Edit',
                code: CODE.FRIDGE.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-TRADE-ASSET-DELETE',
                name: 'Active/Inactive',
                code: CODE.FRIDGE.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    MASTER DATA (BA/SA)
    ==================================================*/
    {
        id: 'MASTER-BA-SA',
        name: 'BA/SA',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'MASTER-BA-SA-READ',
                name: 'View',
                code: CODE.BA_SA.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-BA-SA-CREATE',
                name: 'Create',
                code: CODE.BA_SA.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-BA-SA-UPDATE',
                name: 'Edit',
                code: CODE.BA_SA.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'MASTER-BA-SA-DELETE',
                name: 'Active/Inactive',
                code: CODE.BA_SA.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },
    /* =============================================== */
]