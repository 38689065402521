
import { Action } from '@ngrx/store';
import { Profile } from './profile.model';


export const ADD_PROFILE = '[PROFILE] Add';
export const REMOVE_PROFILE = '[PROFILE] Remove';


export class AddProfile implements Action {
    readonly type = ADD_PROFILE;

    constructor(public payload: Profile) { }
}

export class RemoveProfile implements Action {
    readonly type = REMOVE_PROFILE;

    constructor(public payload: number) { }
}


export type Actions = AddProfile | RemoveProfile;
