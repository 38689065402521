export const TRANSLATE_FILE_STATUS_LOG = {
    /*==================== TRANSLATE / FILE STATUS / DMS SAP LOG ====================*/
    TRANSLATION: {
        code: 'WEB:WORD:TRANSLATE',
        read: 'WEB:WORD:TRANSLATE:R',
        create: 'WEB:WORD:TRANSLATE:C',
        edit: 'WEB:WORD:TRANSLATE:U',
        delete: 'WEB:WORD:TRANSLATE:D',
        all: ['WEB:WORD:TRANSLATE:R', 'WEB:WORD:TRANSLATE:C', 'WEB:WORD:TRANSLATE:U', 'WEB:WORD:TRANSLATE:D'],
    },
    FILE_STATUS: {
        code: 'WEB:IE:IE',
        read: 'WEB:IE:IE:R',
        create: 'WEB:IE:IE:C',
        edit: 'WEB:IE:IE:U',
        delete: 'WEB:IE:IE:D',
        all: ['WEB:IE:IE:R', 'WEB:IE:IE:C', 'WEB:IE:IE:U', 'WEB:IE:IE:D'],
    },
    DMS_SAP_LOG: {
        code: 'WEB:RR:RR',
        read: 'WEB:RR:RR:R',
        create: 'WEB:RR:RR:C',
        edit: 'WEB:RR:RR:U',
        delete: 'WEB:RR:RR:D',
        all: ['WEB:RR:RR:R', 'WEB:RR:RR:C', 'WEB:RR:RR:U', 'WEB:RR:RR:D'],
    },
}