export const INBOUND_ORDER_PERMISSION_CODE = {
    /*==================== PURCHASE ORDER ====================*/
    PURCHASE_ORDER: {
        code: 'WEB:PO:ORDER',
        read: 'WEB:PO:ORDER:R',
        create: 'WEB:PO:ORDER:C',
        edit: 'WEB:PO:ORDER:U',
        delete: 'WEB:PO:ORDER:D',
        all: ['WEB:PO:ORDER:R', 'WEB:PO:ORDER:C', 'WEB:PO:ORDER:U', 'WEB:PO:ORDER:D'],
    },
    PURCHASE_ORDER_SALE: {
        code: 'WEB:PO:SALE',
        read: 'WEB:PO:SALE:R',
        create: 'WEB:PO:SALE:C',
        edit: 'WEB:PO:SALE:U',
        delete: 'WEB:PO:SALE:D',
        all: ['WEB:PO:SALE:R', 'WEB:PO:SALE:C', 'WEB:PO:SALE:U', 'WEB:PO:SALE:D'],
    },
    PURCHASE_ORDER_GOODS_RECEIVE: {
        code: 'WEB:PO:DELIVERY',
        read: 'WEB:PO:DELIVERY:R',
        create: 'WEB:PO:DELIVERY:C',
        edit: 'WEB:PO:DELIVERY:U',
        delete: 'WEB:PO:DELIVERY:D',
        all: ['WEB:PO:DELIVERY:R', 'WEB:PO:DELIVERY:C', 'WEB:PO:DELIVERY:U', 'WEB:PO:DELIVERY:D'],
    },
    PURCHASE_ORDER_INVOICE: {
        code: 'WEB:PO:INVOICE',
        read: 'WEB:PO:INVOICE:R',
        create: 'WEB:PO:INVOICE:C',
        edit: 'WEB:PO:INVOICE:U',
        delete: 'WEB:PO:INVOICE:D',
        all: ['WEB:PO:INVOICE:R', 'WEB:PO:INVOICE:C', 'WEB:PO:INVOICE:U', 'WEB:PO:INVOICE:D'],
    },
    PURCHASE_ORDER_PAYMENT: {
        code: 'WEB:PO:PAYMENT',
        read: 'WEB:PO:PAYMENT:R',
        create: 'WEB:PO:PAYMENT:C',
        edit: 'WEB:PO:PAYMENT:U',
        delete: 'WEB:PO:PAYMENT:D',
        all: ['WEB:PO:PAYMENT:R', 'WEB:PO:PAYMENT:C', 'WEB:PO:PAYMENT:U', 'WEB:PO:PAYMENT:D'],
    },
    /*==================== GRATIS CLAIM ====================*/
    GRATIS_CLAIM_APPROVAL: {
        code: 'WEB:GT:APPROVAL',
        read: 'WEB:GT:APPROVAL:R',
        create: 'WEB:GT:APPROVAL:C',
        edit: 'WEB:GT:APPROVAL:U',
        delete: 'WEB:GT:APPROVAL:D',
        all: ['WEB:GT:APPROVAL:R', 'WEB:GT:APPROVAL:C', 'WEB:GT:APPROVAL:U', 'WEB:GT:APPROVAL:D'],
    },
    GRATIS_CLAIM_HISTORY: {
        code: 'WEB:GT:HISTORY',
        read: 'WEB:GT:HISTORY:R',
        create: 'WEB:GT:HISTORY:C',
        edit: 'WEB:GT:HISTORY:U',
        delete: 'WEB:GT:HISTORY:D',
        all: ['WEB:GT:HISTORY:R', 'WEB:GT:HISTORY:C', 'WEB:GT:HISTORY:U', 'WEB:GT:HISTORY:D'],
    },
    GRATIS_CLAIM: {
        code: 'WEB:GT:ORDER',
        read: 'WEB:GT:ORDER:R',
        create: 'WEB:GT:ORDER:C',
        edit: 'WEB:GT:ORDER:U',
        delete: 'WEB:GT:ORDER:D',
        all: ['WEB:GT:ORDER:R', 'WEB:GT:ORDER:C', 'WEB:GT:ORDER:U', 'WEB:GT:ORDER:D'],
    },
    GRATIS_CLAIM_SALE: {
        code: 'WEB:GT:SALE',
        read: 'WEB:GT:SALE:R',
        create: 'WEB:GT:SALE:C',
        edit: 'WEB:GT:SALE:U',
        delete: 'WEB:GT:SALE:D',
        all: ['WEB:GT:SALE:R', 'WEB:GT:SALE:C', 'WEB:GT:SALE:U', 'WEB:GT:SALE:D'],
    },
    GRATIS_CLAIM_GOODS_RECEIVE: {
        code: 'WEB:GT:DELIVERY',
        read: 'WEB:GT:DELIVERY:R',
        create: 'WEB:GT:DELIVERY:C',
        edit: 'WEB:GT:DELIVERY:U',
        delete: 'WEB:GT:DELIVERY:D',
        all: ['WEB:GT:DELIVERY:R', 'WEB:GT:DELIVERY:C', 'WEB:GT:DELIVERY:U', 'WEB:GT:DELIVERY:D'],
    },
    GRATIS_CLAIM_INVOICE: {
        code: 'WEB:GT:INVOICE',
        read: 'WEB:GT:INVOICE:R',
        create: 'WEB:GT:INVOICE:C',
        edit: 'WEB:GT:INVOICE:U',
        delete: 'WEB:GT:INVOICE:D',
        all: ['WEB:GT:INVOICE:R', 'WEB:GT:INVOICE:C', 'WEB:GT:INVOICE:U', 'WEB:GT:INVOICE:D'],
    },
    GRATIS_CLAIM_PAYMENT: {
        code: 'WEB:GT:PAYMENT',
        read: 'WEB:GT:PAYMENT:R',
        create: 'WEB:GT:PAYMENT:C',
        edit: 'WEB:GT:PAYMENT:U',
        delete: 'WEB:GT:PAYMENT:D',
        all: ['WEB:GT:PAYMENT:R', 'WEB:GT:PAYMENT:C', 'WEB:GT:PAYMENT:U', 'WEB:GT:PAYMENT:D'],
    },
    /*==================== RING PULL CLAIM ====================*/
    RING_PULL_CLAIM: {
        code: 'WEB:RP:ORDER',
        read: 'WEB:RP:ORDER:R',
        create: 'WEB:RP:ORDER:C',
        edit: 'WEB:RP:ORDER:U',
        delete: 'WEB:RP:ORDER:D',
        all: ['WEB:RP:ORDER:R', 'WEB:RP:ORDER:C', 'WEB:RP:ORDER:U', 'WEB:RP:ORDER:D'],
    },
    RING_PULL_CLAIM_SALE: {
        code: 'WEB:RP:SALE',
        read: 'WEB:RP:SALE:R',
        create: 'WEB:RP:SALE:C',
        edit: 'WEB:RP:SALE:U',
        delete: 'WEB:RP:SALE:D',
        all: ['WEB:RP:SALE:R', 'WEB:RP:SALE:C', 'WEB:RP:SALE:U', 'WEB:RP:SALE:D'],
    },
    RING_PULL_CLAIM_GOODS_RECEIVE: {
        code: 'WEB:RP:DELIVERY',
        read: 'WEB:RP:DELIVERY:R',
        create: 'WEB:RP:DELIVERY:C',
        edit: 'WEB:RP:DELIVERY:U',
        delete: 'WEB:RP:DELIVERY:D',
        all: ['WEB:RP:DELIVERY:R', 'WEB:RP:DELIVERY:C', 'WEB:RP:DELIVERY:U', 'WEB:RP:DELIVERY:D'],
    },
    RING_PULL_CLAIM_INVOICE: {
        code: 'WEB:RP:INVOICE',
        read: 'WEB:RP:INVOICE:R',
        create: 'WEB:RP:INVOICE:C',
        edit: 'WEB:RP:INVOICE:U',
        delete: 'WEB:RP:INVOICE:D',
        all: ['WEB:RP:INVOICE:R', 'WEB:RP:INVOICE:C', 'WEB:RP:INVOICE:U', 'WEB:RP:INVOICE:D'],
    },
    RING_PULL_CLAIM_PAYMENT: {
        code: 'WEB:RP:PAYMENT',
        read: 'WEB:RP:PAYMENT:R',
        create: 'WEB:RP:PAYMENT:C',
        edit: 'WEB:RP:PAYMENT:U',
        delete: 'WEB:RP:PAYMENT:D',
        all: ['WEB:RP:PAYMENT:R', 'WEB:RP:PAYMENT:C', 'WEB:RP:PAYMENT:U', 'WEB:RP:PAYMENT:D'],
    },
}