import { PermissionModule } from "src/app/shared/models/role-permission";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

export const SETTING_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    SETTING TITLE
    ==================================================*/
    {
        id: 'SETTING-TITLE',
        name: 'Setting',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    MASTER CONFIG
    ==================================================*/
    {
        id: 'MASTER-CONFIG',
        name: 'Master Config',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** OUTLET CONFIG *****/
            {
                id: 'MASTER-CONFIG-OUTLET',
                name: 'Outlet',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-CONFIG-OUTLET-BUSINESS',
                        name: 'Business Nature',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CLASSIFICATION-BUSINESS-NATURE-READ',
                                name: 'View',
                                code: CODE.BUSINESS_NATURE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-BUSINESS-NATURE-CREATE',
                                name: 'Create',
                                code: CODE.BUSINESS_NATURE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-BUSINESS-NATURE-UPDATE',
                                name: 'Edit',
                                code: CODE.BUSINESS_NATURE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-BUSINESS-NATURE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.BUSINESS_NATURE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-OUTLET-CHENNEL',
                        name: 'Channel Type',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CLASSIFICATION-CHANNEL-TYPE-READ',
                                name: 'View',
                                code: CODE.CHANNEL_TYPE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-CHANNEL-TYPE-CREATE',
                                name: 'Create',
                                code: CODE.CHANNEL_TYPE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-CHANNEL-TYPE-UPDATE',
                                name: 'Edit',
                                code: CODE.CHANNEL_TYPE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-CHANNEL-TYPE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.CHANNEL_TYPE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-OUTLET-OUTLET-TYPE',
                        name: 'Outlet Type',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CLASSIFICATION-OUTLET-TYPE-READ',
                                name: 'View',
                                code: CODE.OUTLET_TYPE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-OUTLET-TYPE-CREATE',
                                name: 'Create',
                                code: CODE.OUTLET_TYPE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-OUTLET-TYPE-UPDATE',
                                name: 'Edit',
                                code: CODE.OUTLET_TYPE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-OUTLET-TYPE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.OUTLET_TYPE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-OUTLET-VOLUME-CLASS',
                        name: 'Volume Classification',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CLASSIFICATION-VOLUME-CLASS-READ',
                                name: 'View',
                                code: CODE.VOLUME_CLASS.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-VOLUME-CLASS-CREATE',
                                name: 'Create',
                                code: CODE.VOLUME_CLASS.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-VOLUME-CLASS-UPDATE',
                                name: 'Edit',
                                code: CODE.VOLUME_CLASS.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CLASSIFICATION-VOLUME-CLASS-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.VOLUME_CLASS.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            /***** DEALER CONFIG *****/
            {
                id: 'MASTER-CONFIG-DEALER',
                name: 'Dealer',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-CONFIG-DEALER-REGION',
                        name: 'Dealer Region',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-REGION-DEALER-REGION-READ',
                                name: 'View',
                                code: CODE.DEALER_REGION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-REGION-DEALER-REGION-CREATE',
                                name: 'Create',
                                code: CODE.DEALER_REGION.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-REGION-DEALER-REGION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.DEALER_REGION.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            /***** DOWNLINE & EMPLOYEE *****/
            {
                id: 'MASTER-CONFIG-DOWNLINE-EMPLOYEE',
                name: 'Downline & Employee',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-CONFIG-DEPARTMENT-MASTER',
                        name: 'Department Master',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-DEPARTMENT-READ',
                                name: 'View',
                                code: CODE.DEPARTMENT_MASTER.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-DEPARTMENT-CREATE',
                                name: 'Create',
                                code: CODE.DEPARTMENT_MASTER.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-DEPARTMENT-UPDATE',
                                name: 'Edit',
                                code: CODE.DEPARTMENT_MASTER.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-DEPARTMENT-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.DEPARTMENT_MASTER.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-POSITION-MASTER',
                        name: 'Position Master',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-POSITION-READ',
                                name: 'View',
                                code: CODE.EMPLOYEE_POSITION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-POSITION-CREATE',
                                name: 'Create',
                                code: CODE.EMPLOYEE_POSITION.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-POSITION-UPDATE',
                                name: 'Edit',
                                code: CODE.EMPLOYEE_POSITION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-POSITION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.EMPLOYEE_POSITION.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            /***** MATERIAL *****/
            {
                id: 'MASTER-CONFIG-MATERIAL',
                name: 'Material',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-CONFIG-TREE-COMPANY',
                        name: 'Hierarchy Lv1',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-TREE-COMPANY-READ',
                                name: 'View',
                                code: CODE.TREE_COMPANY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-COMPANY-CREATE',
                                name: 'Create',
                                code: CODE.TREE_COMPANY.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-COMPANY-UPDATE',
                                name: 'Edit',
                                code: CODE.TREE_COMPANY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-COMPANY-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.TREE_COMPANY.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-TREE-DIVISION',
                        name: 'Hierarchy Lv2',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-TREE-DIVISION-READ',
                                name: 'View',
                                code: CODE.TREE_DIVISION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DIVISION-CREATE',
                                name: 'Create',
                                code: CODE.TREE_DIVISION.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DIVISION-UPDATE',
                                name: 'Edit',
                                code: CODE.TREE_DIVISION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DIVISION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.TREE_DIVISION.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-TREE-CATEGORY',
                        name: 'Hierarchy Lv3',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-TREE-CATEGORY-READ',
                                name: 'View',
                                code: CODE.TREE_CATEGORY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-CATEGORY-CREATE',
                                name: 'Create',
                                code: CODE.TREE_CATEGORY.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-CATEGORY-UPDATE',
                                name: 'Edit',
                                code: CODE.TREE_CATEGORY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-CATEGORY-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.TREE_CATEGORY.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-TREE-BRAND',
                        name: 'Hierarchy Lv4',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-TREE-BRAND-READ',
                                name: 'View',
                                code: CODE.TREE_BRAND.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-BRAND-CREATE',
                                name: 'Create',
                                code: CODE.TREE_BRAND.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-BRAND-UPDATE',
                                name: 'Edit',
                                code: CODE.TREE_BRAND.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-BRAND-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.TREE_BRAND.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-TREE-DISPALY-GROUP',
                        name: 'Display Group',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-PRODUCT-TREE-DISPALY-GROUP-READ',
                                name: 'View',
                                code: CODE.DISPLAY_GROUP.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DISPALY-GROUP-CREATE',
                                name: 'Create',
                                code: CODE.DISPLAY_GROUP.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DISPALY-GROUP-UPDATE',
                                name: 'Edit',
                                code: CODE.DISPLAY_GROUP.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-PRODUCT-TREE-DISPALY-GROUP-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.DISPLAY_GROUP.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            /***** INVENTORY *****/
            {
                id: 'MASTER-CONFIG-INVENTORY',
                name: 'Inventory',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'MASTER-CONFIG-WAREHOUSE',
                        name: 'Warehouse',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-WAREHOUSE-READ',
                                name: 'View',
                                code: CODE.WAREHOUSE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-WAREHOUSE-CREATE',
                                name: 'Create',
                                code: CODE.WAREHOUSE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-WAREHOUSE-UPDATE',
                                name: 'Edit',
                                code: CODE.WAREHOUSE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-WAREHOUSE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.WAREHOUSE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-ZONE-DEALER',
                        name: 'Zone',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEALER-READ',
                                name: 'View',
                                code: CODE.ZONE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEALER-CREATE',
                                name: 'Create',
                                code: CODE.ZONE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEALER-UPDATE',
                                name: 'Edit',
                                code: CODE.ZONE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEALER-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.ZONE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-VAN',
                        name: 'Van',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-VAN-READ',
                                name: 'View',
                                code: CODE.VAN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-VAN-CREATE',
                                name: 'Create',
                                code: CODE.VAN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-VAN-UPDATE',
                                name: 'Edit',
                                code: CODE.VAN.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-VAN-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.VAN.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-DOWNLINE-STORAGE',
                        name: 'Downline Storage',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-STORAGE-READ',
                                name: 'View',
                                code: CODE.DOWNLINE_STORAGE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-STORAGE-CREATE',
                                name: 'Create',
                                code: CODE.DOWNLINE_STORAGE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-STORAGE-UPDATE',
                                name: 'Edit',
                                code: CODE.DOWNLINE_STORAGE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-STORAGE-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.DOWNLINE_STORAGE.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-INVENTORY-CLASS',
                        name: 'Inventory Class',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-INVENTORY-CLASSIFICATION-READ',
                                name: 'View',
                                code: CODE.INVENTORY_CLASS.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-INVENTORY-CLASSIFICATION-CREATE',
                                name: 'Create',
                                code: CODE.INVENTORY_CLASS.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-INVENTORY-CLASSIFICATION-UPDATE',
                                name: 'Edit',
                                code: CODE.INVENTORY_CLASS.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-INVENTORY-CLASSIFICATION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.INVENTORY_CLASS.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            /***** GENERAL CONFIG *****/
            {
                id: 'MASTER-CONFIG-GENERAL',
                name: 'General Config',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    // {
                    //     id: 'MASTER-CONFIG-GENERAL-BANK',
                    //     name: 'Bank',
                    //     code: '',
                    //     classes: '',
                    //     isCheck: false,
                    //     visible: true,
                    //     subModules: [
                    //         {
                    //             id: 'WEB-BANK-READ',
                    //             name: 'View',
                    //             code: CODE.BANK.read,
                    //             classes: 'chb-view',
                    //             isCheck: false,
                    //             visible: true,
                    //         },
                    //         {
                    //             id: 'WEB-BANK-CREATE',
                    //             name: 'Create',
                    //             code: CODE.BANK.create,
                    //             classes: 'auto-chb-view',
                    //             isCheck: false,
                    //             visible: true,
                    //         },
                    //         {
                    //             id: 'WEB-BANK-UPDATE',
                    //             name: 'Edit',
                    //             code: CODE.BANK.edit,
                    //             classes: 'auto-chb-view',
                    //             isCheck: false,
                    //             visible: true,
                    //         },
                    //         {
                    //             id: 'WEB-BANK-DELETE',
                    //             name: 'Active/Inactive',
                    //             code: CODE.BANK.delete,
                    //             classes: 'auto-chb-view',
                    //             isCheck: false,
                    //             visible: true,
                    //         },
                    //     ],
                    // },
                    {
                        id: 'MASTER-CONFIG-GENERAL-REGION',
                        name: 'Region',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-REGION-REGION-READ',
                                name: 'View',
                                code: CODE.REGION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-REGION-REGION-CREATE',
                                name: 'Create',
                                code: CODE.REGION.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-REGION-REGION-UPDATE',
                                name: 'Edit',
                                code: CODE.REGION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-REGION-REGION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.REGION.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-GENERAL-GEO',
                        name: 'Geo',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-GEO-READ',
                                name: 'View',
                                code: CODE.GEO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-GEO-CREATE',
                                name: 'Create',
                                code: CODE.GEO.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-GEO-UPDATE',
                                name: 'Edit',
                                code: CODE.GEO.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-GEO-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.GEO.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-GENERAL-ZONE-DEAFULT',
                        name: 'Default Zone',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEFAULT-READ',
                                name: 'View',
                                code: CODE.DEFAULT_ZONE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEFAULT-CREATE',
                                name: 'Create',
                                code: CODE.DEFAULT_ZONE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-FIRM-WAREHOUSE-ZONE-DEFAULT-UPDATE',
                                name: 'Edit',
                                code: CODE.DEFAULT_ZONE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-GENERAL-REASON',
                        name: 'Reason',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CONFIG-REASON-READ',
                                name: 'View',
                                code: CODE.REASON.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONFIG-REASON-CREATE',
                                name: 'Create',
                                code: CODE.REASON.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONFIG-REASON-UPDATE',
                                name: 'Edit',
                                code: CODE.REASON.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONFIG-REASON-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.REASON.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-GENERAL-EXCHANGE-RATE',
                        name: 'Exchange Rate',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-EXCHANGE-RATE-READ',
                                name: 'View',
                                code: CODE.EXCHANGE_RATE.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-EXCHANGE-RATE-CREATE',
                                name: 'Create',
                                code: CODE.EXCHANGE_RATE.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-EXCHANGE-RATE-UPDATE',
                                name: 'Edit',
                                code: CODE.EXCHANGE_RATE.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'MASTER-CONFIG-GENERAL-CONVERSION',
                        name: 'Bound Location',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-CONVERSION-READ',
                                name: 'View',
                                code: CODE.CONVERSION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONVERSION-CREATE',
                                name: 'Create',
                                code: CODE.CONVERSION.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONVERSION-UPDATE',
                                name: 'Edit',
                                code: CODE.CONVERSION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-CONVERSION-DELETE',
                                name: 'Active/Inactive',
                                code: CODE.CONVERSION.delete,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            }
        ]
    },

    /*==================================================
    TRANSLATION
    ==================================================*/
    {
        id: 'MASTER-CONFIG-TRANSLATION',
        name: 'Translation',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-STATEMENT-TRANSLATE-READ',
                name: 'View',
                code: CODE.TRANSLATION.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-STATEMENT-TRANSLATE-CREATE',
                name: 'Create',
                code: CODE.TRANSLATION.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-STATEMENT-TRANSLATE-UPDATE',
                name: 'Edit',
                code: CODE.TRANSLATION.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-STATEMENT-TRANSLATE-DELETE',
                name: 'Active/Inactive',
                code: CODE.TRANSLATION.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    FILE STATUS
    ==================================================*/
    {
        id: 'MASTER-CONFIG-FILE-STATUS',
        name: 'File Status',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-IMPORT-EXPORT-FILE-READ',
                name: 'View',
                code: CODE.FILE_STATUS.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    DMS-SAP Log
    ==================================================*/
    {
        id: 'MASTER-CONFIG-DMS-SAP-LOG',
        name: 'DMS-SAP Log',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-REQUEST-RESPONSE-READ',
                name: 'View',
                code: CODE.DMS_SAP_LOG.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    }
]