import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { RouterEvent, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class pageStateService {
    constructor(private location: Location, private router: Router, private activatedRoute: ActivatedRoute) {}

    set(state: PageState) {
        localStorage.setItem('page-state', JSON.stringify(state));
    }

    get(pageCode: string) {
        const pageState = JSON.parse(localStorage.getItem('page-state'));
        if (pageState && pageState.pageCode === pageCode) return pageState;
        return null;
    }

    remove() {
        localStorage.removeItem('page-state');
    }

    refreshNoReloadPage() {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.location.path()]);
        });
    }

    sameUrlAction() {
        return this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd));
    }

    /*** store param by url ***/

    setParamToUrl(param: any) {
        let urlTree;
        urlTree = this.router.createUrlTree([], {
            queryParams: param,
            queryParamsHandling: 'merge'
        });
        this.router.navigateByUrl(urlTree);
    }

    getValueParamFromUrl(action) {
        return this.activatedRoute.snapshot.queryParamMap.get(action);
    }

    goBack() {
        this.location.back();
    }
}

interface PageState {
    pageCode: string;
    state: Object;
}
