import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/authorization/auth-layout/auth-layout.component';
import { LoginComponent } from './layouts/authorization/login/login.component';
import { ResetPasswordComponent } from './layouts/authorization/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './layouts/authorization/forgot-password/forgot-password.component';
import { AuthGuard } from 'src/app/shared/guards/index-guards';

const routes: Routes = [
    {
        path: '',
        loadChildren: './layouts/main/main.module#MainModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'reset/password',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'forgot-password',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            }
        ]
    },
    {
        path: 'not-found',
        loadChildren: './layouts/not-found/not-found.module#NotFoundModule'
    },
    {
        path: '**',
        redirectTo: 'not-found'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
export const components = [AuthLayoutComponent, LoginComponent, ResetPasswordComponent, ForgotPasswordComponent];
