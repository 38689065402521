import { Injectable } from '@angular/core';
import { MENU_PERMISSION_CODE } from './menu-permission-code';
import { PERMISSION_MODULES } from '../assigning/assign-permission-module';
import { CurrentUserService } from 'src/app/shared/helper-services/current-user.service';
import { SidebarItem } from 'src/app/shared/models/index-models';
import { Router } from '@angular/router';
import { pageStateService } from '../../page-state.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppAccessPermissionService {
    PERMISSION_CODE = {
        ...MENU_PERMISSION_CODE,
    };
    PERMISSION_MODULES = PERMISSION_MODULES;


    userPermCodes: string[];
    env = environment.env;
    constructor(private currentUser: CurrentUserService, private router: Router, private pgService: pageStateService) {
        /***** Get permission code from current user *****/
        this.currentUser.permissions.subscribe(res => { this.userPermCodes = res || []; });
    }

    /***** Check current user has passed permission code. */
    hasPermissions(permissionCodes: string[]) {
        const bool = permissionCodes.some(r => this.userPermCodes.indexOf(r) >= 0 || r == 'GENERAL');
        return bool;
    }

    /***** Check permission and redirect to accessible route *****/
    checkRoutePriority(mainUrl: string, routeOptions: SidebarItem[]) {
        let currentUrl = this.router.url;
        let found = false;
        if (mainUrl == currentUrl) {
            routeOptions.forEach(route => {
                if (route.visible && !found) {
                    found = true;
                    mainUrl = mainUrl == '/' ? '' : mainUrl;
                    const childUrl = route.url[0] == '/' ? route.url : `/${route.url}`;
                    const url = `${mainUrl}${childUrl}`;
                    this.router.navigate([url]);
                }
            });
        }
    }

    /***** Check if navigate to the same url, check route's priority *****/
    sameUrlCheckRoutePriority(mainUrl: string, routeOptions: SidebarItem[]) {
        return this.pgService.sameUrlAction().subscribe(() => {
            // this.pgService.remove();
            let currentUrl = this.router.url;
            if (currentUrl == mainUrl) {
                this.checkRoutePriority(mainUrl, routeOptions);
            }
        });
    }

    /***** Return all navigation menu items with its accessible state *****/
    getNavPermissions() {
        return [
            {
                text: 'Dashboard',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_DAHSBOARD.all),
                url: '/dashboard'
            },
            {
                text: 'System User',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_SYSTEM_USER.all),
                children: [
                    {
                        text: 'User',
                        visible: this.hasPermissions(this.PERMISSION_CODE.USER.all),
                        url: '/user'
                    },
                    {
                        text: 'Role',
                        visible: this.hasPermissions(this.PERMISSION_CODE.ROLE.all),
                        url: '/role'
                    },
                ]
            },
            {
                text: 'Transaction',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_TRANSACTION.all),
                children: [
                    {
                        text: 'Inbound Order',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_INBOUND_ORDER.all),
                        children: [
                            {
                                text: 'Purchase Order',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_PURCHASE_ORDER.all),
                                url: '/inbound-order'
                            },
                            {
                                text: 'Gratis Claim',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_GRATIS_CLAIM.all),
                                url: '/gratis-claim'
                            },
                            {
                                text: 'Ring Pull Claim',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_RING_PULL_CLAIM.all),
                                url: '/ring-pull-claim'
                            },
                            {
                                text: 'Return',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_INB_RETURN.all),
                                children: [
                                    {
                                        text: 'Market Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_INB_RETURN_MARKET.all),
                                        url: '/market-return'
                                    },
                                    {
                                        text: 'Purchase Return (Accident/Quality Complaint)',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_INB_RETURN_PURCHASE.all),
                                        url: '/return-order'
                                    },
                                    {
                                        text: 'Gratis Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_INB_RETURN_GRATIS.all),
                                        url: '/gratis-return'
                                    },
                                    {
                                        text: 'Ring Pull Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_INB_RETURN_RING.all),
                                        url: '/ring-pull-return'
                                    },
                                    {
                                        text: 'Empty Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_INB_RETURN_EMPTY.all),
                                        url: '/empties-return'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        text: 'Outbound Order',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_OUTBOUND_ORDER.all),
                        children: [
                            {
                                text: 'Sale Order',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_SALES_ORDER.all),
                                url: '/outbound-order'
                            },
                            {
                                text: 'Product Return',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_PRODUCT_RETURN.all),
                                url: '/product-return'
                            },
                            {
                                text: 'Product Rotation',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_PRODUCT_ROTATION.all),
                                url: '/product-rotation'
                            },
                            {
                                text: 'Empty Keg/Bottle Return',
                                visible: this.hasPermissions(this.PERMISSION_CODE.EMPTY_KEG_RETURN.all),
                                url: '/empty-keg-return'
                            },
                            {
                                text: 'Direct Sale',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_DIRECT_SALES.all),
                                url: '/van-transaction'
                            },
                            {
                                text: 'Signage',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_OUTBOUND_SIGNAGE.all),
                                children: [
                                    {
                                        text: 'Request',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_SIGNAGE_REQUEST.all),
                                        url: '/signage-request'
                                    },
                                    {
                                        text: 'Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.SIGNAGE_RETURN.all),
                                        url: '/signage-return'
                                    }
                                ]
                            },
                            {
                                text: 'Trade Asset',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_OUTBOUND_ASSET.all),
                                children: [
                                    {
                                        text: 'Request',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_ASSET_REQUEST.all),
                                        url: '/trade-assets-request'
                                    },
                                    {
                                        text: 'Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.ASSET_RETURN.all),
                                        url: '/trade-assets-return'
                                    }
                                ]
                            },
                            {
                                text: 'POSM',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_OUTBOUND_POSM.all),
                                url: '/posm-transaction'
                            },
                            /* ===== Don't remove this, FUTURE ENHANCEMENT ===== */
                            {
                                text: 'BA/SA',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_OUTBOUND_BA.all),
                                children: [
                                    {
                                        text: 'Request',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_BA_REQUEST.all),
                                        url: '/basa-request'
                                    },
                                    {
                                        text: 'Return',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV4_BA_REQUEST.all),
                                        url: '/basa-return'
                                    }
                                ]
                            },
                            /* ==================================================*/
                            // {
                            //     text: 'Ring Pull Submission',
                            //     visible: this.hasPermissions(this.PERMISSION_CODE.RING_PULL_SUBMISSION.all),
                            //     url: '/ring-pull-submission'
                            // },
                            // {
                            //     text: 'Ring Pull Redemption',
                            //     visible: this.hasPermissions(this.PERMISSION_CODE.RING_PULL_REDUMPTION.all),
                            //     url: '/ring-pull-redemption'
                            // },
                        ]
                    }
                ]
            },
            {
                text: 'Inventory',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_INVENTORY.all),
                children: [
                    {
                        text: 'Inventory On Hand',
                        visible: this.hasPermissions(this.PERMISSION_CODE.INVENTORY_ON_HAND.all),
                        url: '/inventory-on-hand'
                    },
                    {
                        text: 'Overall Inventory',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_OVERALL_INVENTORY.all),
                        url: '/overall-inventory'
                    },
                    {
                        text: 'Inventory Transaction',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_INVENTORY_TRANSACTION.all),
                        url: '/inventory-movement'
                    }
                ]
            },
            {
                text: 'Master Data',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_MASTER_DATA.all),
                children: [
                    {
                        text: 'Outlet',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_OUTLET.all),
                        children: [
                            {
                                text: 'Outlet List',
                                visible: this.hasPermissions(this.PERMISSION_CODE.OUTLET_LIST.all),
                                url: '/outlet'
                            },
                            {
                                text: 'Outlet Location',
                                visible: this.hasPermissions(this.PERMISSION_CODE.OUTLET_LIST.all),
                                url: '/outlet-location'
                            },
                            {
                                text: 'New Outlet Request',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_OUTBOUND_NEW_OUTLET.all),
                                url: '/outlet-request'
                            },
                            {
                                text: 'Outlet Group Program',
                                visible: this.hasPermissions(this.PERMISSION_CODE.OUTLET_GROUP.all),
                                url: '/outlet-group'
                            },
                            {
                                text: 'Outlet Contract',
                                visible: this.hasPermissions(this.PERMISSION_CODE.OUTLET_CONTRACT.all),
                                url: '/outlet-contract'
                            }
                        ]
                    },
                    {
                        text: 'Dealer',
                        visible: this.hasPermissions(this.PERMISSION_CODE.DEALER_LIST.all),
                        url: '/dealer'
                    },
                    {
                        text: 'Downline',
                        visible: this.hasPermissions(this.PERMISSION_CODE.DOWNLINE_LIST.all),
                        url: '/downline'
                    },
                    {
                        text: 'Employee',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_EMPLOYEE.all),
                        children: [
                            {
                                text: 'Employee List',
                                visible: this.hasPermissions(this.PERMISSION_CODE.EMPLOYEE_LIST.all),
                                url: '/employee-master'
                            },
                            {
                                text: 'Device Contract',
                                visible: this.hasPermissions(this.PERMISSION_CODE.EMPLOYEE_DEVICE_CONTRACT.all),
                                url: '/devices-contract'
                            }
                        ]
                    },
                    {
                        text: 'Call Plan',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_CALL_PLAN.all),
                        children: [
                            {
                                text: 'Call Plan List',
                                visible: this.hasPermissions(this.PERMISSION_CODE.CALL_PLAN_LIST.all),
                                url: '/sales-route'
                            },
                            {
                                text: 'Audit Plan',
                                visible: this.hasPermissions(this.PERMISSION_CODE.AUDIT_PLAN_LIST.all),
                                url: '/audit-plan'
                            },
                            {
                                text: 'Manage Route',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CALL_MANAGE_ROUTE.all),
                                url: '/manage-route'
                            }
                        ]
                    },
                    {
                        text: 'Material',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_MATERIAL.all),
                        children: [
                            {
                                text: 'Product',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_MATERIAL_PRODUCT.all),
                                url: '/product'
                            },
                            {
                                text: 'Component',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_MATERIAL_COMPONENT.all),
                                url: '/component'
                            },
                            {
                                text: 'Device',
                                visible: this.hasPermissions(this.PERMISSION_CODE.DEVICE_LIST.all),
                                url: '/device'
                            },
                            {
                                text: 'SAP Material',
                                visible: this.hasPermissions(this.PERMISSION_CODE.SAP_MATERIAL_LIST.all),
                                url: '/material'
                            }
                        ]
                    },
                    {
                        text: 'KPI Target',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_KPI.all),
                        children: [
                            {
                                text: 'Dealer',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_KPI_DEALER.all),
                                children: [
                                    {
                                        text: 'Sales Volume & Sales Amount',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.KPI_DEALER_VOLUME.all),
                                        url: '/dealer-sales-volume-amount'
                                    }
                                    // {
                                    //     text: 'POSM Target',
                                    //     visible: this.hasPermissions(this.PERMISSION_CODE.KPI_DEALER_POSM.all),
                                    //     url: '/dealer-posm-target'
                                    // }
                                ]
                            },
                            {
                                text: 'Downline',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_KPI_DOWNLINE.all),
                                children: [
                                    {
                                        text: 'Sales Volume & Sales Amount',
                                        visible: this.hasPermissions(this.PERMISSION_CODE.KPI_DOWNLINE_VOLUME.all),
                                        url: '/downline-sales-volume-amount'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        text: 'MOA',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MOA_LIST.all),
                        url: '/moa'
                    },
                    {
                        text: 'Outlet Price Group',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_OUTLET_PRICE_GROUP.all),
                        url: '/outlet-price-groups'
                    },
                    {
                        text: 'Map',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_MASTER_MAP.all),
                        children: [
                            {
                                text: 'Live Location',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MAP_LIVE.all),
                                url: '/map/live'
                            }
                            // {
                            //     text: 'Visited Location',
                            // visible: true,
                            //     url: '/map/visited'
                            // }
                        ]
                    },
                    {
                        text: 'Trade Asset',
                        visible: this.hasPermissions(this.PERMISSION_CODE.FRIDGE.all),
                        url: '/fridge'
                    },
                    {
                        text: 'BA/SA',
                        visible: this.hasPermissions(this.PERMISSION_CODE.BA_SA.all),
                        url: '/basa'
                    },
                ]
            },
            {
                text: 'Program',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_PROGRAM.all),
                children: [
                    {
                        text: 'Promotion',
                        visible: this.hasPermissions(this.PERMISSION_CODE.PROMOTION.all),
                        url: '/promotion'
                    },
                    {
                        text: 'Wedding',
                        visible: this.hasPermissions(this.PERMISSION_CODE.WEDDING.all),
                        url: '/wedding'
                    },
                    {
                        text: 'Outlet Incentive',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_PROGRAM_INCENTIVE.all),
                        children: [
                            {
                                text: 'Outlet Incentive Template',
                                visible: this.hasPermissions(this.PERMISSION_CODE.INCENTIVE_TEMPLATE.all),
                                url: '/incentive-template'
                            },
                            {
                                text: 'Outlet Incentive List',
                                visible: this.hasPermissions(this.PERMISSION_CODE.OUTLET_AGREEMENT.all),
                                url: '/outlet-agreement'
                            },
                            {
                                text: 'Outlet Incentive Reward',
                                visible: this.hasPermissions(this.PERMISSION_CODE.INCENTIVE_REWARD.all),
                                url: '/incentive-rewards'
                            }
                        ]
                    },
                    {
                        text: 'Survey',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_PROGRAM_SURVEY.all),
                        children: [
                            {
                                text: 'Survey',
                                visible: this.hasPermissions(this.PERMISSION_CODE.SURVEY.all),
                                url: '/survey/profile',
                            },
                            {
                                text: 'Survey History',
                                visible: this.hasPermissions(this.PERMISSION_CODE.SURVEY_HISTORY.all),
                                url: '/survey/history',
                            },
                        ]
                    },
                    {
                        text: 'Partnership',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_PROGRAM_PARTNERSHIP.all),
                        children: [
                            {
                                text: 'Partnership Template',
                                visible: this.hasPermissions(this.PERMISSION_CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.all),
                                url: '/partnership-incentive-template'
                            },
                            {
                                text: 'Partnership Agreement List',
                                visible: this.hasPermissions(this.PERMISSION_CODE.PARTNERSHIP_AGREEMENT.all),
                                url: '/partnership-outlet-agreement'
                            },
                            {
                                text: 'Partnership Reward',
                                visible: this.hasPermissions(this.PERMISSION_CODE.PARTNERSHIP_INCENTIVE_REWARD.all),
                                url: '/partnership-incentive-rewards'
                            }
                        ]
                    },
                ]
            },
            {
                text: 'Report',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_REPORT.all),
                children: [
                    {
                        text: 'Inbound Order',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_REPORT_INBOUND.all),
                        children: [
                            {
                                text: 'Purchase Order',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_IB_PURCHASE_ORDER.all),
                                url: '/report/report-menu/purchase-order'
                            },
                            {

                                text: 'Ring Pull',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_IB_RING_PULL.all),
                                url: '/report/report-menu/ringpull-inbound',

                            },
                            {
                                text: 'Gratis',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_IB_GRATIS.all),
                                url: '/report/report-menu/grantis-claim',
                            },
                            {
                                text: 'Sales Report',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_IB_SALES_REPORT.all),
                                url: '/report/report-menu/transaction',
                            },
                            {
                                text: 'Return',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_IB_RETURN.all),
                                url: '/report/report-menu/sap-return',
                            }
                        ]
                    },
                    {
                        text: 'Outbound Order',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_REPORT_OUTBOUND.all),
                        children: [
                            {
                                text: 'Sales Report',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_SALES_REPORT.all),
                                url: '/report/report-menu/sale-report'
                            },
                            {
                                text: 'KPI and Performance',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_KPI_PERFORMANCE.all),
                                url: '/report/report-menu/kpi-report'
                            },
                            {
                                text: 'Promotion and Agreement',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_PROMOTION_AGREEMENT.all),
                                url: '/report/report-menu/promotion-report'
                            },
                            {
                                text: 'AR/AP',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_AR_AP.all),
                                url: '/report/report-menu/ar-ap'
                            },
                            {
                                text: 'Trade Marketing',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_TRADE_MARKET.all),
                                url: '/report/report-menu/trade-market'
                            },
                            {
                                text: 'POSM',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_TOUCH_POINT.all),
                                url: '/report/report-menu/touch-point'
                            },
                            {
                                text: 'Inventory',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_INVENTORY.all),
                                url: '/report/report-menu/enventory'
                            },
                            {

                                text: 'Ring Pull Claim',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_RING_PULL.all),
                                url: '/report/report-menu/ringpull-outbound',

                            },
                            {
                                text: 'Gratis Claim',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_GRATIS.all),
                                url: '/report/report-menu/grantis-outbound',
                            },
                            // {
                            //     text: 'Gratis Claim (SD)',
                            //     visible: this.env !== 'staging',
                            //     url: '/report/report-menu/grantis-outbound-sd',
                            // },
                            {
                                text: 'Downline',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_DOWNLINE.all),
                                url: '/report/report-menu/downline',
                            },
                            {
                                text: 'Outlet',
                                visible: this.hasPermissions(this.PERMISSION_CODE.REP_OB_OUTLET.all),
                                url: '/report/report-menu/outlet',
                            },
                            {
                                text: 'Empty Keg/Bottle',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_EMPTY.all),
                                url: '/report/report-menu/empty-bottle',
                            },
                            {
                                text: 'Pallet',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_PALLET.all),
                                url: '/report/report-menu/pallet',
                            },
                            {
                                text: 'BA/SA',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_REP_OB_BA.all),
                                url: '/report/report-menu/ba-sa',
                            },
                        ]
                    },
                    {
                        text: 'System User',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_REPORT_USER.all),
                        url: '/report/report-menu/system-user'
                    },
                ]
            },
            {
                text: 'Setting',
                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV1_SETTING.all),
                children: [
                    {
                        text: 'Master Config',
                        visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV2_SETTING_MASTER.all),
                        children: [
                            {
                                text: 'Outlet',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_OUTLET.all),
                                url: '/outlet-config'
                            },
                            {
                                text: 'Dealer',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_DEALER.all),
                                url: '/dealer-config'
                            },
                            {
                                text: 'Downline & Employee',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_DONWLINE.all),
                                url: '/downline-config'
                            },

                            {
                                text: 'Material',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_MATERIAL.all),
                                url: '/master-config'
                            },
                            {
                                text: 'Inventory',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_INVENTORY.all),
                                url: '/inventory-config'
                            },
                            {
                                text: 'General Config',
                                visible: this.hasPermissions(this.PERMISSION_CODE.MENU_LV3_CONFIG_GENERAL.all),
                                url: '/setting-master-config'
                            }
                        ]
                    },
                    {
                        text: 'Translation',
                        visible: this.hasPermissions(this.PERMISSION_CODE.TRANSLATION.all),
                        url: '/translation'
                    },
                    {
                        text: 'File Status',
                        visible: this.hasPermissions(this.PERMISSION_CODE.FILE_STATUS.all),
                        url: '/file-status'
                    },
                    {
                        text: 'DMS-SAP Log',
                        visible: this.hasPermissions(this.PERMISSION_CODE.DMS_SAP_LOG.all),
                        url: '/error-log'
                    }
                ]
            }
        ] as SidebarItem[];
    }
}
