import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppAccessPermissionService } from 'src/app/shared/helper-services/app-permission/navigation/app-access-permission.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
    constructor(
        private permService: AppAccessPermissionService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        let permissionCodes = (route.data.permissionCodes || []) as Array<string>;
        return this.permService.hasPermissions(permissionCodes) ? true : this.router.navigate(['not-found']);;
    }
}