export const MATERIAL_PERMISSION_CODE = {
    /*==================== PRODUCT ====================*/
    PRODUCT_LIST: {
        code: 'WEB:PRO:PRODUCT',
        read: 'WEB:PRO:PRODUCT:R',
        create: 'WEB:PRO:PRODUCT:C',
        edit: 'WEB:PRO:PRODUCT:U',
        delete: 'WEB:PRO:PRODUCT:D',
        all: ['WEB:PRO:PRODUCT:R', 'WEB:PRO:PRODUCT:C', 'WEB:PRO:PRODUCT:U', 'WEB:PRO:PRODUCT:D'],
    },
    PRODUCT_GROUP: {
        code: 'WEB:PRO:GROUP',
        read: 'WEB:PRO:GROUP:R',
        create: 'WEB:PRO:GROUP:C',
        edit: 'WEB:PRO:GROUP:U',
        delete: 'WEB:PRO:GROUP:D',
        all: ['WEB:PRO:GROUP:R', 'WEB:PRO:GROUP:C', 'WEB:PRO:GROUP:U', 'WEB:PRO:GROUP:D'],
    },
    /*==================== COMPONENT ====================*/
    ASSET_LIST: {
        code: 'WEB:PRO:ASSET',
        read: 'WEB:PRO:ASSET:R',
        create: 'WEB:PRO:ASSET:C',
        edit: 'WEB:PRO:ASSET:U',
        delete: 'WEB:PRO:ASSET:D',
        all: ['WEB:PRO:ASSET:R', 'WEB:PRO:ASSET:C', 'WEB:PRO:ASSET:U', 'WEB:PRO:ASSET:D'],
    },
    POSM_LIST: {
        code: 'WEB:PRO:POSM',
        read: 'WEB:PRO:POSM:R',
        create: 'WEB:PRO:POSM:C',
        edit: 'WEB:PRO:POSM:U',
        delete: 'WEB:PRO:POSM:D',
        all: ['WEB:PRO:POSM:R', 'WEB:PRO:POSM:C', 'WEB:PRO:POSM:U', 'WEB:PRO:POSM:D'],
    },
    EMPTY_KEG_LIST: {
        code: 'WEB:PRO:EMPTY',
        read: 'WEB:PRO:EMPTY:R',
        create: 'WEB:PRO:EMPTY:C',
        edit: 'WEB:PRO:EMPTY:U',
        delete: 'WEB:PRO:EMPTY:D',
        all: ['WEB:PRO:EMPTY:R', 'WEB:PRO:EMPTY:C', 'WEB:PRO:EMPTY:U', 'WEB:PRO:EMPTY:D'],
    },
    RING_PULL_LIST: {
        code: 'WEB:RRRED:ITEM',
        read: 'WEB:RRRED:ITEM:R',
        create: 'WEB:RRRED:ITEM:C',
        edit: 'WEB:RRRED:ITEM:U',
        delete: 'WEB:RRRED:ITEM:D',
        all: ['WEB:RRRED:ITEM:R', 'WEB:RRRED:ITEM:C', 'WEB:RRRED:ITEM:U', 'WEB:RRRED:ITEM:D'],
    },
    PALLET_LIST: {
        code: 'WEB:PRO:PALLET',
        read: 'WEB:PRO:PALLET:R',
        create: 'WEB:PRO:PALLET:C',
        edit: 'WEB:PRO:PALLET:U',
        delete: 'WEB:PRO:PALLET:D',
        all: ['WEB:PRO:PALLET:R', 'WEB:PRO:PALLET:C', 'WEB:PRO:PALLET:U', 'WEB:PRO:PALLET:D'],
    },
    SIGNAGE_LIST: {
        code: 'WEB:PRO:SIGNAGE',
        read: 'WEB:PRO:SIGNAGE:R',
        create: 'WEB:PRO:SIGNAGE:C',
        edit: 'WEB:PRO:SIGNAGE:U',
        delete: 'WEB:PRO:SIGNAGE:D',
        all: ['WEB:PRO:SIGNAGE:R', 'WEB:PRO:SIGNAGE:C', 'WEB:PRO:SIGNAGE:U', 'WEB:PRO:SIGNAGE:D'],
    },
    DEVICE_LIST: {
        code: 'WEB:DEVICE:ASSET',
        read: 'WEB:DEVICE:ASSET:R',
        create: 'WEB:DEVICE:ASSET:C',
        edit: 'WEB:DEVICE:ASSET:U',
        delete: 'WEB:DEVICE:ASSET:D',
        all: ['WEB:DEVICE:ASSET:R', 'WEB:DEVICE:ASSET:C', 'WEB:DEVICE:ASSET:U', 'WEB:DEVICE:ASSET:D'],
    },
    SAP_MATERIAL_LIST: {
        code: 'WEB:PRO:SAP',
        read: 'WEB:PRO:SAP:R',
        create: 'WEB:PRO:SAP:C',
        edit: 'WEB:PRO:SAP:U',
        delete: 'WEB:PRO:SAP:D',
        all: ['WEB:PRO:SAP:R', 'WEB:PRO:SAP:C', 'WEB:PRO:SAP:U', 'WEB:PRO:SAP:D'],
    },
}