export const SYSTEM_PERMISSION_CODE = {
    DASHBOARD_DEALER: {
        code: 'WEB:DB:DEALER',
        read: 'WEB:DB:DEALER:R',
        create: 'WEB:DB:DEALER:C',
        edit: 'WEB:DB:DEALER:U',
        delete: 'WEB:DB:DEALER:D',
        all: ['WEB:DB:DEALER:R', 'WEB:DB:DEALER:C', 'WEB:DB:DEALER:U', 'WEB:DB:DEALER:D'],
    },
    DASHBOARD_SALES: {
        code: 'WEB:DB:SALE',
        read: 'WEB:DB:SALE:R',
        create: 'WEB:DB:SALE:C',
        edit: 'WEB:DB:SALE:U',
        delete: 'WEB:DB:SALE:D',
        all: ['WEB:DB:SALE:R', 'WEB:DB:SALE:C', 'WEB:DB:SALE:U', 'WEB:DB:SALE:D'],
    },
    USER: {
        code: 'WEB:USER:USER',
        read: 'WEB:USER:USER:R',
        create: 'WEB:USER:USER:C',
        edit: 'WEB:USER:USER:U',
        delete: 'WEB:USER:USER:D',
        all: ['WEB:USER:USER:R', 'WEB:USER:USER:C', 'WEB:USER:USER:U', 'WEB:USER:USER:D'],
    },
    ROLE: {
        code: 'WEB:USER:ROLE',
        read: 'WEB:USER:ROLE:R',
        create: 'WEB:USER:ROLE:C',
        edit: 'WEB:USER:ROLE:U',
        delete: 'WEB:USER:ROLE:D',
        all: ['WEB:USER:ROLE:R', 'WEB:USER:ROLE:C', 'WEB:USER:ROLE:U', 'WEB:USER:ROLE:D'],
    },
}