import { PermissionModule } from "src/app/shared/models/role-permission";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

export const PROGRAM_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    PROGRAM TITLE
    ==================================================*/
    {
        id: 'PROGRAM-TITLE',
        name: 'Program',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    PROMOTION
    ==================================================*/
    {
        id: 'PROGRAM-PROMOTION',
        name: 'Promotion',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-PROMOTION-PROMOTION-READ',
                name: 'View',
                code: CODE.PROMOTION.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-PROMOTION-CREATE',
                name: 'Create',
                code: CODE.PROMOTION.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-PROMOTION-UPDATE',
                name: 'Edit',
                code: CODE.PROMOTION.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-PROMOTION-DELETE',
                name: 'Active/Inactive',
                code: CODE.PROMOTION.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    WEDDING
    ==================================================*/
    {
        id: 'PROGRAM-WEDDING',
        name: 'Wedding',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-PROMOTION-WEDDING-READ',
                name: 'View',
                code: CODE.WEDDING.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-WEDDING-CREATE',
                name: 'Create',
                code: CODE.WEDDING.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-WEDDING-UPDATE',
                name: 'Edit',
                code: CODE.WEDDING.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-PROMOTION-WEDDING-DELETE',
                name: 'Active/Inactive',
                code: CODE.WEDDING.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    INCENTIVE
    ==================================================*/
    {
        id: 'PROGRAM-INCENTIVE',
        name: 'Outlet Incentive',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'PROGRAM-INCENTIVE-TEMPLATE',
                name: 'Outlet Incentive Template',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PROMOTION-INCENTIVE-READ',
                        name: 'View',
                        code: CODE.INCENTIVE_TEMPLATE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PROMOTION-INCENTIVE-CREATE',
                        name: 'Create',
                        code: CODE.INCENTIVE_TEMPLATE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PROMOTION-INCENTIVE-UPDATE',
                        name: 'Edit',
                        code: CODE.INCENTIVE_TEMPLATE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PROMOTION-INCENTIVE-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.INCENTIVE_TEMPLATE.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'PROGRAM-OUTLET-AGREEMENT',
                name: 'Outlet Incentive List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-AGREEMENT-READ',
                        name: 'View',
                        code: CODE.OUTLET_AGREEMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-AGREEMENT-CREATE',
                        name: 'Create',
                        code: CODE.OUTLET_AGREEMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-AGREEMENT-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTLET_AGREEMENT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-AGREEMENT-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OUTLET_AGREEMENT.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'PROGRAM-INCENTIVE-REWARD',
                name: 'Outlet Incentive Reward',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-AGREEMENT-ACHIEVEMENT-READ',
                        name: 'View',
                        code: CODE.INCENTIVE_REWARD.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    SURVEY
    ==================================================*/
    {
        id: 'PROGRAM-SURVEY',
        name: 'Survey',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'SURVEY-SURVEY',
                name: 'Survey',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-SURVEY-READ',
                        name: 'View',
                        code: CODE.SURVEY.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-SURVEY-CREATE',
                        name: 'Create',
                        code: CODE.SURVEY.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-SURVEY-UPDATE',
                        name: 'Edit',
                        code: CODE.SURVEY.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-SURVEY-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.SURVEY.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'SURVEY-HISTORY',
                name: 'Survey History',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-SURVEY-HISTORY-READ',
                        name: 'View',
                        code: CODE.SURVEY_HISTORY.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                ]
            },
        ],
    },

    /*==================================================
    PARTNERSHIP
    ==================================================*/
    {
        id: 'PROGRAM-PARTNERSHIP',
        name: 'Partnership',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'PROGRAM-PARTNERSHIP-INCENTIVE-TEMPLATE',
                name: 'Partnership Template',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PARTNERSHIP-INCENTIVE-READ',
                        name: 'View',
                        code: CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-INCENTIVE-CREATE',
                        name: 'Create',
                        code: CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-INCENTIVE-UPDATE',
                        name: 'Edit',
                        code: CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-INCENTIVE-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'PROGRAM-PARTNERSHIP-OUTLET-AGREEMENT',
                name: 'Partnership Agreement List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PARTNERSHIP-FIRM-AGREEMENT-READ',
                        name: 'View',
                        code: CODE.PARTNERSHIP_AGREEMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-FIRM-AGREEMENT-CREATE',
                        name: 'Create',
                        code: CODE.PARTNERSHIP_AGREEMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-FIRM-AGREEMENT-UPDATE',
                        name: 'Edit',
                        code: CODE.PARTNERSHIP_AGREEMENT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PARTNERSHIP-FIRM-AGREEMENT-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.PARTNERSHIP_AGREEMENT.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'PROGRAM-PARTNERSHIP-INCENTIVE-REWARD',
                name: 'Partnership Reward',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PARTNERSHIP-FIRM-AGREEMENT-ACHIEVEMENT-READ',
                        name: 'View',
                        code: CODE.PARTNERSHIP_INCENTIVE_REWARD.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },
]