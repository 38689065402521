import { SYSTEM_PERMISSION_CODE } from './based-permission-code/user-role-permission-code';
import { INBOUND_ORDER_PERMISSION_CODE } from './based-permission-code/inbound-order-permission-code';
import { OUTBOUND_ORDER_PERMISSION_CODE } from './based-permission-code/outbound-order-permission-code';
import { INVENTORY_PERMISSION_CODE } from './based-permission-code/inventory-permission-code';
import { MASTER_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE } from './based-permission-code/master-outlet-dealer-downline-permission-code';
import { MASTER_CALL_PLAN_PERMISSION_CODE } from './based-permission-code/master-call-plan-permission-code';
import { MATERIAL_PERMISSION_CODE } from './based-permission-code/material-permission-code';
import { KPI_MOA_OUTLET_PRICE_MAP_PERMISSION_CODE } from './based-permission-code/kpi-moa-outlet-price-map-permission-code';
import { PROGRAM_REPORT_PERMISSION_CODE } from './based-permission-code/program-report-permission-code';
import { CONFIG_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE } from './based-permission-code/config-outlet-dealer-downline-permission-code';
import { CONFIG_MATERIAL_INVENTORY_PERMISSION_CODE } from './based-permission-code/config-material-inventory-permission-code';
import { GENERAL_CONFIG_PERMISSION_CODE } from './based-permission-code/general-config-permission-code';
import { TRANSLATE_FILE_STATUS_LOG } from './based-permission-code/traslate-file-status-log-permission-code';
import { REPORT_PERMISSION_CODE } from './based-permission-code/report-permission-code';
import { INBOUND_RETURN_PERMISSION_CODE } from './based-permission-code/inbound-return-permission-code';

export const ALL_PERMISSION_CODE = {
    /*==================================================
    = The order of each module doesn't matter.
    = The codes are arrange by hierracy level of the navigations, e.g Level 1, 2, 3, ..., sidebar or table navigation (in report).
    ==================================================*/
    SAMPLE: {
        code: 'AAA',
        read: 'AAA:READ',
        create: 'AAA:CREATE',
        edit: 'AAA:UPDATE',
        delete: 'AAA:DELETE',
        all: ['AAA:READ', 'AAA:CREATE', 'AAA:UPDATE', 'AAA:DELETE'],
    },
    ...SYSTEM_PERMISSION_CODE,
    ...INBOUND_ORDER_PERMISSION_CODE,
    ...INBOUND_RETURN_PERMISSION_CODE,
    ...OUTBOUND_ORDER_PERMISSION_CODE,
    ...INVENTORY_PERMISSION_CODE,
    ...MASTER_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE,
    ...MASTER_CALL_PLAN_PERMISSION_CODE,
    ...MATERIAL_PERMISSION_CODE,
    ...KPI_MOA_OUTLET_PRICE_MAP_PERMISSION_CODE,
    ...PROGRAM_REPORT_PERMISSION_CODE,
    ...CONFIG_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE,
    ...CONFIG_MATERIAL_INVENTORY_PERMISSION_CODE,
    ...GENERAL_CONFIG_PERMISSION_CODE,
    ...TRANSLATE_FILE_STATUS_LOG,
    ...REPORT_PERMISSION_CODE,
};