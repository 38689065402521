import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './shared/services/global.service';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    people$ = [{ name: 'AAAA' }, { name: 'BBB' }, { name: 'CCC' }];
    selectedPeople = [{ name: 'AAAA' }];

    constructor(public translate: TranslateService, private globalService: GlobalService) {
        translate.addLangs(['en', 'kh']);
        translate.setDefaultLang('en');

        if (localStorage.getItem('lang')) {
            translate.use(localStorage.getItem('lang'));
        } else {
            localStorage.setItem('lang', 'en');
        }

        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|kh/) ? browserLang : 'en');

        if (!localStorage.getItem('udid')) {
            this.globalService.getUDID();
        }

        /***** Unregister chart's label *****/
        Chart.plugins.unregister(ChartDataLabels);
    }
}
