import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Profile } from '../app-state/profile.model';
import { AppState } from '../app-state/app.state';
import { BehaviorSubject } from 'rxjs';
import { Dealer } from '../models/dealer';

@Injectable({
    providedIn: 'root',
})
export class CurrentUserService {
    profile: Profile;
    isCensus: boolean;
    isDealerAdmin: boolean;
    dealerAdmin: Dealer;
    asyncProfile = new BehaviorSubject(null);
    permissions = new BehaviorSubject(null);

    constructor(private store: Store<AppState>) {
        // Read data from ngrx/store
        this.store.select('profile').subscribe((res: any) => {
            this.profile = res[0];
            this.isCensus = res[0] && res[0].isCensus ? true : false;
            this.isDealerAdmin = res[0] && res[0].dealer ? true : false;
            this.dealerAdmin = res[0] && res[0].dealer ? res[0].dealer : null;
            this.asyncProfile.next(this.profile);
            const permissions = this.profile && this.profile.permissions ? this.profile.permissions : [];
            this.permissions.next(permissions);
        });
    }

    logOut() {
        /***** Don't remove every thing from local storage, remove only what u want *****/
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedinExpire');
        if (!localStorage.getItem('token')) {
            location.reload();
        }
    }
}
