import { Profile } from './profile.model';
import * as ProfileAction from './profile.actions';


export function reducer(state: Profile[] = [], action: ProfileAction.Actions) {

    switch (action.type) {
        case ProfileAction.ADD_PROFILE:
            return [...state, action.payload];
        case ProfileAction.REMOVE_PROFILE:
            state.splice(action.payload, 1);
            return state;
        default:
            return state;
    }
}
