export const CONFIG_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE = {
    /*==================== OUTLET ====================*/
    BUSINESS_NATURE: {
        code: 'WEB:CLASS:BUSINESS',
        read: 'WEB:CLASS:BUSINESS:R',
        create: 'WEB:CLASS:BUSINESS:C',
        edit: 'WEB:CLASS:BUSINESS:U',
        delete: 'WEB:CLASS:BUSINESS:D',
        all: ['WEB:CLASS:BUSINESS:R', 'WEB:CLASS:BUSINESS:C', 'WEB:CLASS:BUSINESS:U', 'WEB:CLASS:BUSINESS:D'],
    },
    CHANNEL_TYPE: {
        code: 'WEB:CLASS:CHANNEL',
        read: 'WEB:CLASS:CHANNEL:R',
        create: 'WEB:CLASS:CHANNEL:C',
        edit: 'WEB:CLASS:CHANNEL:U',
        delete: 'WEB:CLASS:CHANNEL:D',
        all: ['WEB:CLASS:CHANNEL:R', 'WEB:CLASS:CHANNEL:C', 'WEB:CLASS:CHANNEL:U', 'WEB:CLASS:CHANNEL:D'],
    },
    OUTLET_TYPE: {
        code: 'WEB:CLASS:TYPE',
        read: 'WEB:CLASS:TYPE:R',
        create: 'WEB:CLASS:TYPE:C',
        edit: 'WEB:CLASS:TYPE:U',
        delete: 'WEB:CLASS:TYPE:D',
        all: ['WEB:CLASS:TYPE:R', 'WEB:CLASS:TYPE:C', 'WEB:CLASS:TYPE:U', 'WEB:CLASS:TYPE:D'],
    },
    VOLUME_CLASS: {
        code: 'WEB:CLASS:VOLUME',
        read: 'WEB:CLASS:VOLUME:R',
        create: 'WEB:CLASS:VOLUME:C',
        edit: 'WEB:CLASS:VOLUME:U',
        delete: 'WEB:CLASS:VOLUME:D',
        all: ['WEB:CLASS:VOLUME:R', 'WEB:CLASS:VOLUME:C', 'WEB:CLASS:VOLUME:U', 'WEB:CLASS:VOLUME:D'],
    },

    /*==================== DEALER ====================*/
    DEALER_REGION: {
        code: 'WEB:DEALER:REGION',
        read: 'WEB:DEALER:REGION:R',
        create: 'WEB:DEALER:REGION:C',
        edit: 'WEB:DEALER:REGION:U',
        delete: 'WEB:DEALER:REGION:D',
        all: ['WEB:DEALER:REGION:R', 'WEB:DEALER:REGION:C', 'WEB:DEALER:REGION:U', 'WEB:DEALER:REGION:D'],
    },

    /*==================== DOWNLNE & EMPLOYEE ====================*/
    DEPARTMENT_MASTER: {
        code: 'WEB:EMP:DEPARTMENT',
        read: 'WEB:EMP:DEPARTMENT:R',
        create: 'WEB:EMP:DEPARTMENT:C',
        edit: 'WEB:EMP:DEPARTMENT:U',
        delete: 'WEB:EMP:DEPARTMENT:D',
        all: ['WEB:EMP:DEPARTMENT:R', 'WEB:EMP:DEPARTMENT:C', 'WEB:EMP:DEPARTMENT:U', 'WEB:EMP:DEPARTMENT:D'],
    },
    EMPLOYEE_POSITION: {
        code: 'WEB:EMP:POSITION',
        read: 'WEB:EMP:POSITION:R',
        create: 'WEB:EMP:POSITION:C',
        edit: 'WEB:EMP:POSITION:U',
        delete: 'WEB:EMP:POSITION:D',
        all: ['WEB:EMP:POSITION:R', 'WEB:EMP:POSITION:C', 'WEB:EMP:POSITION:U', 'WEB:EMP:POSITION:D'],
    },
}