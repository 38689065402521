export const OUTBOUND_ORDER_PERMISSION_CODE = {
    /*==================== SALE ORDER ====================*/
    SALE_ORDER: {
        code: 'WEB:SO:ORDER',
        read: 'WEB:SO:ORDER:R',
        create: 'WEB:SO:ORDER:C',
        edit: 'WEB:SO:ORDER:U',
        delete: 'WEB:SO:ORDER:D',
        all: ['WEB:SO:ORDER:R', 'WEB:SO:ORDER:C', 'WEB:SO:ORDER:U', 'WEB:SO:ORDER:D']
    },
    SO_RING_PULL_REDEMPTION: {
        code: 'WEB:RRP:ORDER',
        read: 'WEB:RRP:ORDER:R',
        create: 'WEB:RRP:ORDER:C',
        edit: 'WEB:RRP:ORDER:U',
        delete: 'WEB:RRP:ORDER:D',
        all: ['WEB:RRP:ORDER:R', 'WEB:RRP:ORDER:C', 'WEB:RRP:ORDER:U', 'WEB:RRP:ORDER:D']
    },
    PRODUCT_REIMBURSEMENT: {
        code: 'WEB:PRB:ORDER',
        read: 'WEB:PRB:ORDER:R',
        create: 'WEB:PRB:ORDER:C',
        edit: 'WEB:PRB:ORDER:U',
        delete: 'WEB:PRB:ORDER:D',
        all: ['WEB:PRB:ORDER:R', 'WEB:PRB:ORDER:C', 'WEB:PRB:ORDER:U', 'WEB:PRB:ORDER:D']
    },
    OUTBOUND_CLAIM: {
        code: 'WEB:CLAIM:ORDER',
        read: 'WEB:CLAIM:ORDER:R',
        create: 'WEB:CLAIM:ORDER:C',
        edit: 'WEB:CLAIM:ORDER:U',
        delete: 'WEB:CLAIM:ORDER:D',
        all: ['WEB:CLAIM:ORDER:R', 'WEB:CLAIM:ORDER:C', 'WEB:CLAIM:ORDER:U', 'WEB:CLAIM:ORDER:D']
    },
    MANUAL_CLAIM: {
        code: 'WEB:CLAIM:MANUAL',
        read: 'WEB:CLAIM:MANUAL:R',
        create: 'WEB:CLAIM:MANUAL:C',
        edit: 'WEB:CLAIM:MANUAL:U',
        delete: 'WEB:CLAIM:MANUAL:D',
        all: ['WEB:CLAIM:MANUAL:R', 'WEB:CLAIM:MANUAL:C', 'WEB:CLAIM:MANUAL:U', 'WEB:CLAIM:MANUAL:D']
    },
    SALE_ORDER_DELIVERY_ORDER: {
        code: 'WEB:SO:DELIVERY',
        read: 'WEB:SO:DELIVERY:R',
        create: 'WEB:SO:DELIVERY:C',
        edit: 'WEB:SO:DELIVERY:U',
        delete: 'WEB:SO:DELIVERY:D',
        all: ['WEB:SO:DELIVERY:R', 'WEB:SO:DELIVERY:C', 'WEB:SO:DELIVERY:U', 'WEB:SO:DELIVERY:D']
    },
    SALE_ORDER_DELIVERY_ROUTE: {
        code: 'WEB:SO:ROUTE',
        read: 'WEB:SO:ROUTE:R',
        create: 'WEB:SO:ROUTE:C',
        edit: 'WEB:SO:ROUTE:U',
        delete: 'WEB:SO:ROUTE:D',
        all: ['WEB:SO:ROUTE:R', 'WEB:SO:ROUTE:C', 'WEB:SO:ROUTE:U', 'WEB:SO:ROUTE:D']
    },
    SALE_ORDER_INVOICE: {
        code: 'WEB:SO:INVOICE',
        read: 'WEB:SO:INVOICE:R',
        create: 'WEB:SO:INVOICE:C',
        edit: 'WEB:SO:INVOICE:U',
        delete: 'WEB:SO:INVOICE:D',
        all: ['WEB:SO:INVOICE:R', 'WEB:SO:INVOICE:C', 'WEB:SO:INVOICE:U', 'WEB:SO:INVOICE:D']
    },
    SALE_ORDER_PAYMENT: {
        code: 'WEB:SO:PAYMENT',
        read: 'WEB:SO:PAYMENT:R',
        create: 'WEB:SO:PAYMENT:C',
        edit: 'WEB:SO:PAYMENT:U',
        delete: 'WEB:SO:PAYMENT:D',
        all: ['WEB:SO:PAYMENT:R', 'WEB:SO:PAYMENT:C', 'WEB:SO:PAYMENT:U', 'WEB:SO:PAYMENT:D']
    },

    /*==================== SALE ORDER RETURN (PRODUCT & EMPTY KEG)====================*/
    PRODUCT_RETURN: {
        code: 'WEB:SO:RETURN',
        read: 'WEB:SO:RETURN:R',
        create: 'WEB:SO:RETURN:C',
        edit: 'WEB:SO:RETURN:U',
        delete: 'WEB:SO:RETURN:D',
        all: ['WEB:SO:RETURN:R', 'WEB:SO:RETURN:C', 'WEB:SO:RETURN:U', 'WEB:SO:RETURN:D']
    },
    EMPTY_KEG_RETURN: {
        code: 'WEB:BUY:ORDER',
        read: 'WEB:BUY:ORDER:R',
        create: 'WEB:BUY:ORDER:C',
        edit: 'WEB:BUY:ORDER:U',
        delete: 'WEB:BUY:ORDER:D',
        all: ['WEB:BUY:ORDER:R', 'WEB:BUY:ORDER:C', 'WEB:BUY:ORDER:U', 'WEB:BUY:ORDER:D']
    },

    /*==================== PRODUCT RETURN (TRANSACTION & PRODUCT RETURN)====================*/
    PRODUCT_RETURN_TRANSACTION: {
        code: 'WEB:PRT:APPROVAL',
        read: 'WEB:PRT:APPROVAL:R',
        create: 'WEB:PRT:APPROVAL:C',
        edit: 'WEB:PRT:APPROVAL:U',
        delete: 'WEB:PRT:APPROVAL:D',
        all: ['WEB:PRT:APPROVAL:R', 'WEB:PRT:APPROVAL:C', 'WEB:PRT:APPROVAL:U', 'WEB:PRT:APPROVAL:D']
    },
    PRODUCT_RETURN_APPROVE: {
        code: 'WEB:PRT:ORDER',
        read: 'WEB:PRT:ORDER:R',
        create: 'WEB:PRT:ORDER:C',
        edit: 'WEB:PRT:ORDER:U',
        delete: 'WEB:PRT:ORDER:D',
        all: ['WEB:PRT:ORDER:R', 'WEB:PRT:ORDER:C', 'WEB:PRT:ORDER:U', 'WEB:PRT:ORDER:D']
    },
    PRODUCT_ROTATION_TRANSACTION: {
        code: 'WEB:RTT:APPROVAL',
        read: 'WEB:RTT:APPROVAL:R',
        create: 'WEB:RTT:APPROVAL:C',
        edit: 'WEB:RTT:APPROVAL:U',
        delete: 'WEB:RTT:APPROVAL:D',
        all: ['WEB:RTT:APPROVAL:R', 'WEB:RTT:APPROVAL:C', 'WEB:RTT:APPROVAL:U', 'WEB:RTT:APPROVAL:D']
    },
    PRODUCT_ROTATION_APPROVE: {
        code: 'WEB:RTT:ORDER',
        read: 'WEB:RTT:ORDER:R',
        create: 'WEB:RTT:ORDER:C',
        edit: 'WEB:RTT:ORDER:U',
        delete: 'WEB:RTT:ORDER:D',
        all: ['WEB:RTT:ORDER:R', 'WEB:RTT:ORDER:C', 'WEB:RTT:ORDER:U', 'WEB:RTT:ORDER:D']
    },
    /*==================== DIRECT SALE ====================*/
    DIRECT_SALES_STOCK_REQUEST: {
        code: 'WEB:DRS:REQUEST',
        read: 'WEB:DRS:REQUEST:R',
        create: 'WEB:DRS:REQUEST:C',
        edit: 'WEB:DRS:REQUEST:U',
        delete: 'WEB:DRS:REQUEST:D',
        all: ['WEB:DRS:REQUEST:R', 'WEB:DRS:REQUEST:C', 'WEB:DRS:REQUEST:U', 'WEB:DRS:REQUEST:D']
    },
    DIRECT_SALES_STOCK_REPLENISH: {
        code: 'WEB:DRS:REPLENIS',
        read: 'WEB:DRS:REPLENIS:R',
        create: 'WEB:DRS:REPLENIS:C',
        edit: 'WEB:DRS:REPLENIS:U',
        delete: 'WEB:DRS:REPLENIS:D',
        all: ['WEB:DRS:REPLENIS:R', 'WEB:DRS:REPLENIS:C', 'WEB:DRS:REPLENIS:U', 'WEB:DRS:REPLENIS:D']
    },
    DIRECT_SALES_STOCK_TRANSFER: {
        code: 'WEB:DRS:TFO',
        read: 'WEB:DRS:TFO:R',
        create: 'WEB:DRS:TFO:C',
        edit: 'WEB:DRS:TFO:U',
        delete: 'WEB:DRS:TFO:D',
        all: ['WEB:DRS:TFO:R', 'WEB:DRS:TFO:C', 'WEB:DRS:TFO:U', 'WEB:DRS:TFO:D']
    },
    DIRECT_SALES_VAN_ROUTE: {
        code: 'WEB:DRS:ROUTE',
        read: 'WEB:DRS:ROUTE:R',
        create: 'WEB:DRS:ROUTE:C',
        edit: 'WEB:DRS:ROUTE:U',
        delete: 'WEB:DRS:ROUTE:D',
        all: ['WEB:DRS:ROUTE:R', 'WEB:DRS:ROUTE:C', 'WEB:DRS:ROUTE:U', 'WEB:DRS:ROUTE:D']
    },

    /*==================== SIGNAGE TRANSACTION ====================*/
    SIGNAGE_REQUEST_TRANSACTION: {
        code: 'WEB:SIGN:APPROVAL',
        read: 'WEB:SIGN:APPROVAL:R',
        create: 'WEB:SIGN:APPROVAL:C',
        edit: 'WEB:SIGN:APPROVAL:U',
        delete: 'WEB:SIGN:APPROVAL:D',
        all: ['WEB:SIGN:APPROVAL:R', 'WEB:SIGN:APPROVAL:C', 'WEB:SIGN:APPROVAL:U', 'WEB:SIGN:APPROVAL:D']
    },
    SIGNAGE_REQUEST_DEPLOY: {
        code: 'WEB:SIGN:ORDER',
        read: 'WEB:SIGN:ORDER:R',
        create: 'WEB:SIGN:ORDER:C',
        edit: 'WEB:SIGN:ORDER:U',
        delete: 'WEB:SIGN:ORDER:D',
        all: ['WEB:SIGN:ORDER:R', 'WEB:SIGN:ORDER:C', 'WEB:SIGN:ORDER:U', 'WEB:SIGN:ORDER:D']
    },
    SIGNAGE_RETURN: {
        code: 'WEB:SIGN:RETURN',
        read: 'WEB:SIGN:RETURN:R',
        create: 'WEB:SIGN:RETURN:C',
        edit: 'WEB:SIGN:RETURN:U',
        delete: 'WEB:SIGN:RETURN:D',
        all: ['WEB:SIGN:RETURN:R', 'WEB:SIGN:RETURN:C', 'WEB:SIGN:RETURN:U', 'WEB:SIGN:RETURN:D']
    },

    /*==================== ASSET TRANSACTION ====================*/
    ASSET_REQUEST_TRANSACTION: {
        code: 'WEB:ASS:APPROVAL',
        read: 'WEB:ASS:APPROVAL:R',
        create: 'WEB:ASS:APPROVAL:C',
        edit: 'WEB:ASS:APPROVAL:U',
        delete: 'WEB:ASS:APPROVAL:D',
        all: ['WEB:ASS:APPROVAL:R', 'WEB:ASS:APPROVAL:C', 'WEB:ASS:APPROVAL:U', 'WEB:ASS:APPROVAL:D']
    },
    ASSET_REQUEST_DEPLOY: {
        code: 'WEB:ASS:ORDER',
        read: 'WEB:ASS:ORDER:R',
        create: 'WEB:ASS:ORDER:C',
        edit: 'WEB:ASS:ORDER:U',
        delete: 'WEB:ASS:ORDER:D',
        all: ['WEB:ASS:ORDER:R', 'WEB:ASS:ORDER:C', 'WEB:ASS:ORDER:U', 'WEB:ASS:ORDER:D']
    },
    ASSET_RETURN: {
        code: 'WEB:ASS:RETURN',
        read: 'WEB:ASS:RETURN:R',
        create: 'WEB:ASS:RETURN:C',
        edit: 'WEB:ASS:RETURN:U',
        delete: 'WEB:ASS:RETURN:D',
        all: ['WEB:ASS:RETURN:R', 'WEB:ASS:RETURN:C', 'WEB:ASS:RETURN:U', 'WEB:ASS:RETURN:D']
    },

    /*==================== POSM TRANSACTION ====================*/
    POSM_TRANSACTION: {
        code: 'WEB:POSM:APPROVAL',
        read: 'WEB:POSM:APPROVAL:R',
        create: 'WEB:POSM:APPROVAL:C',
        edit: 'WEB:POSM:APPROVAL:U',
        delete: 'WEB:POSM:APPROVAL:D',
        all: ['WEB:POSM:APPROVAL:R', 'WEB:POSM:APPROVAL:C', 'WEB:POSM:APPROVAL:U', 'WEB:POSM:APPROVAL:D']
    },
    POSM_REQUEST: {
        code: 'WEB:POSM:REQUEST',
        read: 'WEB:POSM:REQUEST:R',
        create: 'WEB:POSM:REQUEST:C',
        edit: 'WEB:POSM:REQUEST:U',
        delete: 'WEB:POSM:REQUEST:D',
        all: ['WEB:POSM:REQUEST:R', 'WEB:POSM:REQUEST:C', 'WEB:POSM:REQUEST:U', 'WEB:POSM:REQUEST:D']
    },
    POSM_RETURN: {
        code: 'WEB:POSM:RETURN',
        read: 'WEB:POSM:RETURN:R',
        create: 'WEB:POSM:RETURN:C',
        edit: 'WEB:POSM:RETURN:U',
        delete: 'WEB:POSM:RETURN:D',
        all: ['WEB:POSM:RETURN:R', 'WEB:POSM:RETURN:C', 'WEB:POSM:RETURN:U', 'WEB:POSM:RETURN:D']
    },
    POSM_DEPLOY: {
        code: 'WEB:POSM:ORDER',
        read: 'WEB:POSM:ORDER:R',
        create: 'WEB:POSM:ORDER:C',
        edit: 'WEB:POSM:ORDER:U',
        delete: 'WEB:POSM:ORDER:D',
        all: ['WEB:POSM:ORDER:R', 'WEB:POSM:ORDER:C', 'WEB:POSM:ORDER:U', 'WEB:POSM:ORDER:D']
    },

    /*==================== NEW OUTLET REQUEST ====================*/
    NEW_OUTLET_REQUEST_LIST: {
        code: 'WEB:OUTLET:APPROVAL',
        read: 'WEB:OUTLET:APPROVAL:R',
        create: 'WEB:OUTLET:APPROVAL:C',
        edit: 'WEB:OUTLET:APPROVAL:U',
        delete: 'WEB:OUTLET:APPROVAL:D',
        all: ['WEB:OUTLET:APPROVAL:R', 'WEB:OUTLET:APPROVAL:C', 'WEB:OUTLET:APPROVAL:U', 'WEB:OUTLET:APPROVAL:D']
    },
    NEW_OUTLET_REQUEST_HISTORY: {
        code: 'WEB:OUTLET:HISTORY',
        read: 'WEB:OUTLET:HISTORY:R',
        create: 'WEB:OUTLET:HISTORY:C',
        edit: 'WEB:OUTLET:HISTORY:U',
        delete: 'WEB:OUTLET:HISTORY:D',
        all: ['WEB:OUTLET:HISTORY:R', 'WEB:OUTLET:HISTORY:C', 'WEB:OUTLET:HISTORY:U', 'WEB:OUTLET:HISTORY:D']
    },

    /*==================== RING PULL REDUMPTION ====================*/
    RING_PULL_SUBMISSION: {
        code: 'WEB:RRSUB:REQUEST',
        read: 'WEB:RRSUB:REQUEST:R',
        create: 'WEB:RRSUB:REQUEST:C',
        edit: 'WEB:RRSUB:REQUEST:U',
        delete: 'WEB:RRSUB:REQUEST:D',
        all: ['WEB:RRSUB:REQUEST:R', 'WEB:RRSUB:REQUEST:C', 'WEB:RRSUB:REQUEST:U', 'WEB:RRSUB:REQUEST:D']
    },
    RING_PULL_REDUMPTION: {
        code: 'WEB:RRRED:REQUEST',
        read: 'WEB:RRRED:REQUEST:R',
        create: 'WEB:RRRED:REQUEST:C',
        edit: 'WEB:RRRED:REQUEST:U',
        delete: 'WEB:RRRED:REQUEST:D',
        all: ['WEB:RRRED:REQUEST:R', 'WEB:RRRED:REQUEST:C', 'WEB:RRRED:REQUEST:U', 'WEB:RRRED:REQUEST:D']
    },

    /*==================== BA/SA REQUEST RETURN ====================*/
    BA_REQUEST_PENDING: {
        code: 'WEB:BARQ:PENDING',
        read: 'WEB:BARQ:PENDING:R',
        create: 'WEB:BARQ:PENDING:C',
        edit: 'WEB:BARQ:PENDING:U',
        delete: 'WEB:BARQ:PENDING:D',
        all: ['WEB:BARQ:PENDING:R', 'WEB:BARQ:PENDING:C', 'WEB:BARQ:PENDING:U', 'WEB:BARQ:PENDING:D']
    },
    BA_DEPLOY: {
        code: 'WEB:BARQ:DEPLOY',
        read: 'WEB:BARQ:DEPLOY:R',
        create: 'WEB:BARQ:DEPLOY:C',
        edit: 'WEB:BARQ:DEPLOY:U',
        delete: 'WEB:BARQ:DEPLOY:D',
        all: ['WEB:BARQ:DEPLOY:R', 'WEB:BARQ:DEPLOY:C', 'WEB:BARQ:DEPLOY:U', 'WEB:BARQ:DEPLOY:D']
    },
    BA_RETURN_PENDING: {
        code: 'WEB:BART:PENDING',
        read: 'WEB:BART:PENDING:R',
        create: 'WEB:BART:PENDING:C',
        edit: 'WEB:BART:PENDING:U',
        delete: 'WEB:BART:PENDING:D',
        all: ['WEB:BART:PENDING:R', 'WEB:BART:PENDING:C', 'WEB:BART:PENDING:U', 'WEB:BART:PENDING:D']
    },
    BA_RETURN: {
        code: 'WEB:BART:RETURN',
        read: 'WEB:BART:RETURN:R',
        create: 'WEB:BART:RETURN:C',
        edit: 'WEB:BART:RETURN:U',
        delete: 'WEB:BART:RETURN:D',
        all: ['WEB:BART:RETURN:R', 'WEB:BART:RETURN:C', 'WEB:BART:RETURN:U', 'WEB:BART:RETURN:D']
    }
};
