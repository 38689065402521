import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class AppTranslateService {

    constructor(private translate: TranslateService) {
    }


    keysArrayInToAString(keys: string[]) {
        if (!keys || !keys.length) return '';
        const lang = this.translate.currentLang;
        let text = '';
        this.translate.get(keys).subscribe(obj => {
            keys.forEach(k => {
                if (obj[k] && lang == 'en') text += obj[k] + ' ';
                else if (obj[k] && lang == 'kh') text += obj[k];
            })
        });
        return text.trim();
    }

    /***** Translate keys string that separated by any sign *****/
    keysStringWithSign(keysString: string, sign = ',') {
        if (!keysString || !keysString.length) return keysString;
        const arr = keysString.split(sign);
        const cleanArray = (arr || []).map(s => s.trim());
        let translateArray = [];
        this.translate.get(cleanArray).subscribe(obj => {
            translateArray = Object.keys(obj).map(k => obj[k]);
        });
        return translateArray.join(sign);
    }

    keysArrayValue(keys: string[]) {
        if (!keys || !keys.length) return [];
        let arr = [];
        this.translate.get(keys).subscribe(obj => {
            Object.keys(obj).forEach(k => {
                if (obj[k]) arr.push(obj[k]);
            })
        });
        return arr;
    }

    currentLang() {
        return this.translate.currentLang;
    }
}