import { PermissionModule } from "src/app/shared/models/role-permission";

export const LOGIN_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    Login 
    ==================================================*/
    {
        id: 'LOGIN',
        name: 'Login',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** DMS Admin *****/
            {
                id: 'LOGIN-DMS-ADMIN',
                name: 'DMS',
                code: 'LOGIN:DMS:ADMIN',
                classes: '',
                isCheck: false,
                visible: true,
            },
            /***** DMS Client *****/
            {
                id: 'LOGIN-DMS-CLIENT',
                name: 'SFA',
                code: 'LOGIN:DMS:CLIENT',
                classes: '',
                isCheck: false,
                visible: true,
            }
        ]
    },
]