import { PermissionModule } from "src/app/shared/models/role-permission";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

export const DASHBOARD_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    DASHBOARD 
    ==================================================*/
    {
        id: 'DASHBOARD',
        name: 'Dashboard',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-DASHBOARD-DEALER',
                name: 'Dealer',
                code: CODE.DASHBOARD_DEALER.read,
                classes: '',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-DASHBOARD-SALE',
                name: 'Sales',
                code: CODE.DASHBOARD_SALES.read,
                classes: '',
                isCheck: false,
                visible: true,
            }
        ]
    },
]