export const PROGRAM_REPORT_PERMISSION_CODE = {
    /*==================== PROMOTION ====================*/
    PROMOTION: {
        code: 'WEB:PMT:PROMOTION',
        read: 'WEB:PMT:PROMOTION:R',
        create: 'WEB:PMT:PROMOTION:C',
        edit: 'WEB:PMT:PROMOTION:U',
        delete: 'WEB:PMT:PROMOTION:D',
        all: ['WEB:PMT:PROMOTION:R', 'WEB:PMT:PROMOTION:C', 'WEB:PMT:PROMOTION:U', 'WEB:PMT:PROMOTION:D'],
    },

    /*==================== WEDDING ====================*/
    WEDDING: {
        code: 'WEB:PMT:WEDDING',
        read: 'WEB:PMT:WEDDING:R',
        create: 'WEB:PMT:WEDDING:C',
        edit: 'WEB:PMT:WEDDING:U',
        delete: 'WEB:PMT:WEDDING:D',
        all: ['WEB:PMT:WEDDING:R', 'WEB:PMT:WEDDING:C', 'WEB:PMT:WEDDING:U', 'WEB:PMT:WEDDING:D'],
    },

    /*==================== INCENTIVE ====================*/
    INCENTIVE_TEMPLATE: {
        code: 'WEB:PMT:INCENTIVE',
        read: 'WEB:PMT:INCENTIVE:R',
        create: 'WEB:PMT:INCENTIVE:C',
        edit: 'WEB:PMT:INCENTIVE:U',
        delete: 'WEB:PMT:INCENTIVE:D',
        all: ['WEB:PMT:INCENTIVE:R', 'WEB:PMT:INCENTIVE:C', 'WEB:PMT:INCENTIVE:U', 'WEB:PMT:INCENTIVE:D'],
    },
    OUTLET_AGREEMENT: {
        code: 'WEB:INC:INCENTIVE',
        read: 'WEB:INC:INCENTIVE:R',
        create: 'WEB:INC:INCENTIVE:C',
        edit: 'WEB:INC:INCENTIVE:U',
        delete: 'WEB:INC:INCENTIVE:D',
        all: ['WEB:INC:INCENTIVE:R', 'WEB:INC:INCENTIVE:C', 'WEB:INC:INCENTIVE:U', 'WEB:INC:INCENTIVE:D'],
    },
    INCENTIVE_REWARD: {
        code: 'WEB:INC:ACHIEVEMENT',
        read: 'WEB:INC:ACHIEVEMENT:R',
        create: 'WEB:INC:ACHIEVEMENT:C',
        edit: 'WEB:INC:ACHIEVEMENT:U',
        delete: 'WEB:INC:ACHIEVEMENT:D',
        all: ['WEB:INC:ACHIEVEMENT:R', 'WEB:INC:ACHIEVEMENT:C', 'WEB:INC:ACHIEVEMENT:U', 'WEB:INC:ACHIEVEMENT:D'],
    },

    /*==================== SURVEY ====================*/
    SURVEY: {
        code: 'WEB:SUR:SURVEY',
        read: 'WEB:SUR:SURVEY:R',
        create: 'WEB:SUR:SURVEY:C',
        edit: 'WEB:SUR:SURVEY:U',
        delete: 'WEB:SUR:SURVEY:D',
        all: ['WEB:SUR:SURVEY:R', 'WEB:SUR:SURVEY:C', 'WEB:SUR:SURVEY:U', 'WEB:SUR:SURVEY:D'],
    },
    SURVEY_HISTORY: {
        code: 'WEB:SUR:HISTORY',
        read: 'WEB:SUR:HISTORY:R',
        create: 'WEB:SUR:HISTORY:C',
        edit: 'WEB:SUR:HISTORY:U',
        delete: 'WEB:SUR:HISTORY:D',
        all: ['WEB:SUR:HISTORY:R', 'WEB:SUR:HISTORY:C', 'WEB:SUR:HISTORY:U', 'WEB:SUR:HISTORY:D'],
    },

    /*==================== PARTNERSHIP INCENTIVE ====================*/
    PARTNERSHIP_INCENTIVE_TEMPLATE: {
        code: 'WEB:PNS:INCENTIVE',
        read: 'WEB:PNS:INCENTIVE:R',
        create: 'WEB:PNS:INCENTIVE:C',
        edit: 'WEB:PNS:INCENTIVE:U',
        delete: 'WEB:PNS:INCENTIVE:D',
        all: ['WEB:PNS:INCENTIVE:R', 'WEB:PNS:INCENTIVE:C', 'WEB:PNS:INCENTIVE:U', 'WEB:PNS:INCENTIVE:D'],
    },
    PARTNERSHIP_AGREEMENT: {
        code: 'WEB:PNS:AGREEMENT',
        read: 'WEB:PNS:AGREEMENT:R',
        create: 'WEB:PNS:AGREEMENT:C',
        edit: 'WEB:PNS:AGREEMENT:U',
        delete: 'WEB:PNS:AGREEMENT:D',
        all: ['WEB:PNS:AGREEMENT:R', 'WEB:PNS:AGREEMENT:C', 'WEB:PNS:AGREEMENT:U', 'WEB:PNS:AGREEMENT:D'],
    },
    PARTNERSHIP_INCENTIVE_REWARD: {
        code: 'WEB:PNS:ACHIEVEMENT',
        read: 'WEB:PNS:ACHIEVEMENT:R',
        create: 'WEB:PNS:ACHIEVEMENT:C',
        edit: 'WEB:PNS:ACHIEVEMENT:U',
        delete: 'WEB:PNS:ACHIEVEMENT:D',
        all: ['WEB:PNS:ACHIEVEMENT:R', 'WEB:PNS:ACHIEVEMENT:C', 'WEB:PNS:ACHIEVEMENT:U', 'WEB:PNS:ACHIEVEMENT:D'],
    },

    /*==================== REPORT ====================*/
    REPORT: {
        code: 'WEB:REPORT:REPORT',
        read: 'WEB:REPORT:REPORT:R',
        create: 'WEB:REPORT:REPORT:C',
        edit: 'WEB:REPORT:REPORT:U',
        delete: 'WEB:REPORT:REPORT:D',
        all: ['WEB:REPORT:REPORT:R', 'WEB:REPORT:REPORT:C', 'WEB:REPORT:REPORT:U', 'WEB:REPORT:REPORT:D'],
    },
}