import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    url: string = this.globalService.url;
    udid: string = Math.floor((1 + Math.random()) * 0x10000000000000000000).toString(20);
    latitude = null;
    longitude = null;
    authorization = this.globalService.authorization;
    clientId = this.globalService.clientId;

    constructor(private globalService: GlobalService, private http: HttpClient) {}

    getLoginHeader(token: string) {
        // get current location
        window.navigator.geolocation.getCurrentPosition((res) => {
            this.latitude = res.coords.latitude;
            this.longitude = res.coords.longitude;
        });

        return new HttpHeaders({
            token: token,
            'Content-Type': 'application/json',
            'x-udid': this.udid,
            'x-os-version': '',
            'x-language': window.navigator.language || '',
            'x-platform': 'web',
            'x-app-version': '',
            'x-forwarded-for': '',
            'x-latitude': this.latitude || '',
            'x-longitude': this.longitude || '',
            'x-login-platform': 'web',
            'x-user-agent': window.navigator.userAgent || '',
            'x-device-token': 'jkhvjdsgfdhgsfhgsfdgshdfguiy43h8y8dfduhgbfhjg'
        });
    }

    getAuthorizeHeader() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            authorization: this.authorization,
            // udid: Math.floor((1 + Math.random()) * 0x1000000000).toString(16),
            'x-platform': 'WEB',
            'x-udid': localStorage.getItem('udid')
        });
    }

    requestToken() {
        return this.http.post(
            `${this.url}/v1/auth/authorize`,
            {
                clientId: this.clientId
            },
            {
                headers: this.getAuthorizeHeader()
            }
        );
    }

    login(token: string, body: object) {
        return this.http.post(`${this.url}/v1/auth/login`, body, {
            headers: this.getLoginHeader(token)
        });
    }

    forgotPassword(email: string, token: string) {
        return this.http.post(`${this.url}/v1/auth/forget-password`, email, {
            headers: this.getLoginHeader(token)
        });
    }

    changePassword(body: object) {
        return this.http.put(`${this.url}/v1/auth/password`, body, {
            headers: this.globalService.getHeader()
        });
    }

    getUserProfile() {
        return this.http.get(`${this.url}/v1/auth/profile`, {
            headers: this.globalService.getHeader()
        });
    }

    getURL(param: string, auth: string) {
        return this.http.get(`${this.url}/v1/auth/forget-password/password?resetToken=${param}`, {
            headers: this.getLoginHeader(auth)
        });
    }

    postResetPassword(body: object, auth: string) {
        return this.http.post(`${this.url}/v1/auth/forget-password/password`, body, {
            headers: this.getLoginHeader(auth)
        });
    }

    forceChangePassword(body: object) {
        return this.http.put(`${this.url}/v1/auth/reset/password`, body, {
            headers: this.globalService.getHeader()
        });
    }

    subscribeNotification(token) {
        return this.http.post(this.url + '/v1/notifications/subscribe', { token }, { headers: this.globalService.getHeader() });
    }
}
