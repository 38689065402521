export const MASTER_CALL_PLAN_PERMISSION_CODE = {
    /*==================== INVENTORY ====================*/
    CALL_PLAN_LIST: {
        code: 'WEB:OUTLET:PLAN',
        read: 'WEB:OUTLET:PLAN:R',
        create: 'WEB:OUTLET:PLAN:C',
        edit: 'WEB:OUTLET:PLAN:U',
        delete: 'WEB:OUTLET:PLAN:D',
        all: ['WEB:OUTLET:PLAN:R', 'WEB:OUTLET:PLAN:C', 'WEB:OUTLET:PLAN:U', 'WEB:OUTLET:PLAN:D'],
    },
    AUDIT_PLAN_LIST: {
        code: 'WEB:OUTLET:AUDIT',
        read: 'WEB:OUTLET:AUDIT:R',
        create: 'WEB:OUTLET:AUDIT:C',
        edit: 'WEB:OUTLET:AUDIT:U',
        delete: 'WEB:OUTLET:AUDIT:D',
        all: ['WEB:OUTLET:AUDIT:R', 'WEB:OUTLET:AUDIT:C', 'WEB:OUTLET:AUDIT:U', 'WEB:OUTLET:AUDIT:D'],
    },
    CALL_PLAN_BY_DAY: {
        code: 'WEB:OUTLET:DAY',
        read: 'WEB:OUTLET:DAY:R',
        create: 'WEB:OUTLET:DAY:C',
        edit: 'WEB:OUTLET:DAY:U',
        delete: 'WEB:OUTLET:DAY:D',
        all: ['WEB:OUTLET:DAY:R', 'WEB:OUTLET:DAY:C', 'WEB:OUTLET:DAY:U', 'WEB:OUTLET:DAY:D'],
    },
    CALL_PLAN_BY_DATE: {
        code: 'WEB:OUTLET:DATE',
        read: 'WEB:OUTLET:DATE:R',
        create: 'WEB:OUTLET:DATE:C',
        edit: 'WEB:OUTLET:DATE:U',
        delete: 'WEB:OUTLET:DATE:D',
        all: ['WEB:OUTLET:DATE:R', 'WEB:OUTLET:DATE:C', 'WEB:OUTLET:DATE:U', 'WEB:OUTLET:DATE:D'],
    },
    CALL_PLAN_COVERAGE: {
        code: 'WEB:OUTLET:COV',
        read: 'WEB:OUTLET:COV:R',
        create: 'WEB:OUTLET:COV:C',
        edit: 'WEB:OUTLET:COV:U',
        delete: 'WEB:OUTLET:COV:D',
        all: ['WEB:OUTLET:COV:R', 'WEB:OUTLET:COV:C', 'WEB:OUTLET:COV:U', 'WEB:OUTLET:COV:D'],
    },
    CALL_PLAN_HOLIDAY: {
        code: 'WEB:HLD:HLD',
        read: 'WEB:HLD:HLD:R',
        create: 'WEB:HLD:HLD:C',
        edit: 'WEB:HLD:HLD:U',
        delete: 'WEB:HLD:HLD:D',
        all: ['WEB:HLD:HLD:R', 'WEB:HLD:HLD:C', 'WEB:HLD:HLD:U', 'WEB:HLD:HLD:D'],
    },
};