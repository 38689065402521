export const CONFIG_MATERIAL_INVENTORY_PERMISSION_CODE = {
    /*==================== MATERIAL ====================*/
    TREE_COMPANY: {
        code: 'WEB:TREE:COMPANY',
        read: 'WEB:TREE:COMPANY:R',
        create: 'WEB:TREE:COMPANY:C',
        edit: 'WEB:TREE:COMPANY:U',
        delete: 'WEB:TREE:COMPANY:D',
        all: ['WEB:TREE:COMPANY:R', 'WEB:TREE:COMPANY:C', 'WEB:TREE:COMPANY:U', 'WEB:TREE:COMPANY:D'],
    },
    TREE_DIVISION: {
        code: 'WEB:TREE:DIVISION',
        read: 'WEB:TREE:DIVISION:R',
        create: 'WEB:TREE:DIVISION:C',
        edit: 'WEB:TREE:DIVISION:U',
        delete: 'WEB:TREE:DIVISION:D',
        all: ['WEB:TREE:DIVISION:R', 'WEB:TREE:DIVISION:C', 'WEB:TREE:DIVISION:U', 'WEB:TREE:DIVISION:D'],
    },
    TREE_CATEGORY: {
        code: 'WEB:TREE:CATEGORY',
        read: 'WEB:TREE:CATEGORY:R',
        create: 'WEB:TREE:CATEGORY:C',
        edit: 'WEB:TREE:CATEGORY:U',
        delete: 'WEB:TREE:CATEGORY:D',
        all: ['WEB:TREE:CATEGORY:R', 'WEB:TREE:CATEGORY:C', 'WEB:TREE:CATEGORY:U', 'WEB:TREE:CATEGORY:D'],
    },
    TREE_BRAND: {
        code: 'WEB:TREE:BRAND',
        read: 'WEB:TREE:BRAND:R',
        create: 'WEB:TREE:BRAND:C',
        edit: 'WEB:TREE:BRAND:U',
        delete: 'WEB:TREE:BRAND:D',
        all: ['WEB:TREE:BRAND:R', 'WEB:TREE:BRAND:C', 'WEB:TREE:BRAND:U', 'WEB:TREE:BRAND:D'],
    },
    DISPLAY_GROUP: {
        code: 'WEB:TREE:DISPLAY',
        read: 'WEB:TREE:DISPLAY:R',
        create: 'WEB:TREE:DISPLAY:C',
        edit: 'WEB:TREE:DISPLAY:U',
        delete: 'WEB:TREE:DISPLAY:D',
        all: ['WEB:TREE:DISPLAY:R', 'WEB:TREE:DISPLAY:C', 'WEB:TREE:DISPLAY:U', 'WEB:TREE:DISPLAY:D'],
    },

    /*==================== INVENTORY ====================*/
    WAREHOUSE: {
        code: 'WEB:DEALER:WAREHOUSE',
        read: 'WEB:DEALER:WAREHOUSE:R',
        create: 'WEB:DEALER:WAREHOUSE:C',
        edit: 'WEB:DEALER:WAREHOUSE:U',
        delete: 'WEB:DEALER:WAREHOUSE:D',
        all: ['WEB:DEALER:WAREHOUSE:R', 'WEB:DEALER:WAREHOUSE:C', 'WEB:DEALER:WAREHOUSE:U', 'WEB:DEALER:WAREHOUSE:D'],
    },
    ZONE: {
        code: 'WEB:DEALER:ZONE',
        read: 'WEB:DEALER:ZONE:R',
        create: 'WEB:DEALER:ZONE:C',
        edit: 'WEB:DEALER:ZONE:U',
        delete: 'WEB:DEALER:ZONE:D',
        all: ['WEB:DEALER:ZONE:R', 'WEB:DEALER:ZONE:C', 'WEB:DEALER:ZONE:U', 'WEB:DEALER:ZONE:D'],
    },
    VAN: {
        code: 'WEB:DEALER:VAN',
        read: 'WEB:DEALER:VAN:R',
        create: 'WEB:DEALER:VAN:C',
        edit: 'WEB:DEALER:VAN:U',
        delete: 'WEB:DEALER:VAN:D',
        all: ['WEB:DEALER:VAN:R', 'WEB:DEALER:VAN:C', 'WEB:DEALER:VAN:U', 'WEB:DEALER:VAN:D'],
    },
    DOWNLINE_STORAGE: {
        code: 'WEB:ZONE:DEALER',
        read: 'WEB:ZONE:DEALER:R',
        create: 'WEB:ZONE:DEALER:C',
        edit: 'WEB:ZONE:DEALER:U',
        delete: 'WEB:ZONE:DEALER:D',
        all: ['WEB:ZONE:DEALER:R', 'WEB:ZONE:DEALER:C', 'WEB:ZONE:DEALER:U', 'WEB:ZONE:DEALER:D'],
    },
    INVENTORY_CLASS: {
        code: 'WEB:CLASS:CLASS',
        read: 'WEB:CLASS:CLASS:R',
        create: 'WEB:CLASS:CLASS:C',
        edit: 'WEB:CLASS:CLASS:U',
        delete: 'WEB:CLASS:CLASS:D',
        all: ['WEB:CLASS:CLASS:R', 'WEB:CLASS:CLASS:C', 'WEB:CLASS:CLASS:U', 'WEB:CLASS:CLASS:D'],
    },
}