export const GENERAL_CONFIG_PERMISSION_CODE = {
    /*==================== GENERAL CONFIG ====================*/
    BANK: {
        code: 'WEB:BANK:BANK',
        read: 'WEB:BANK:BANK:R',
        create: 'WEB:BANK:BANK:C',
        edit: 'WEB:BANK:BANK:U',
        delete: 'WEB:BANK:BANK:D',
        all: ['WEB:BANK:BANK:R', 'WEB:BANK:BANK:C', 'WEB:BANK:BANK:U', 'WEB:BANK:BANK:D'],
    },
    REGION: {
        code: 'WEB:GEO:REGION',
        read: 'WEB:GEO:REGION:R',
        create: 'WEB:GEO:REGION:C',
        edit: 'WEB:GEO:REGION:U',
        delete: 'WEB:GEO:REGION:D',
        all: ['WEB:GEO:REGION:R', 'WEB:GEO:REGION:C', 'WEB:GEO:REGION:U', 'WEB:GEO:REGION:D'],
    },
    GEO: {
        code: 'WEB:GEO:GEO',
        read: 'WEB:GEO:GEO:R',
        create: 'WEB:GEO:GEO:C',
        edit: 'WEB:GEO:GEO:U',
        delete: 'WEB:GEO:GEO:D',
        all: ['WEB:GEO:GEO:R', 'WEB:GEO:GEO:C', 'WEB:GEO:GEO:U', 'WEB:GEO:GEO:D'],
    },
    DEFAULT_ZONE: {
        code: 'WEB:ZONE:DEFAULT',
        read: 'WEB:ZONE:DEFAULT:R',
        create: 'WEB:ZONE:DEFAULT:C',
        edit: 'WEB:ZONE:DEFAULT:U',
        delete: 'WEB:ZONE:DEFAULT:D',
        all: ['WEB:ZONE:DEFAULT:R', 'WEB:ZONE:DEFAULT:C', 'WEB:ZONE:DEFAULT:U', 'WEB:ZONE:DEFAULT:D'],
    },
    REASON: {
        code: 'WEB:WORD:REASON',
        read: 'WEB:WORD:REASON:R',
        create: 'WEB:WORD:REASON:C',
        edit: 'WEB:WORD:REASON:U',
        delete: 'WEB:WORD:REASON:D',
        all: ['WEB:WORD:REASON:R', 'WEB:WORD:REASON:C', 'WEB:WORD:REASON:U', 'WEB:WORD:REASON:D'],
    },
    EXCHANGE_RATE: {
        code: 'WEB:RATE:RATE',
        read: 'WEB:RATE:RATE:R',
        create: 'WEB:RATE:RATE:C',
        edit: 'WEB:RATE:RATE:U',
        delete: 'WEB:RATE:RATE:D',
        all: ['WEB:RATE:RATE:R', 'WEB:RATE:RATE:C', 'WEB:RATE:RATE:U', 'WEB:RATE:RATE:D'],
    },
    CONVERSION: {
        code: 'WEB:CON:CON',
        read: 'WEB:CON:CON:R',
        create: 'WEB:CON:CON:C',
        edit: 'WEB:CON:CON:U',
        delete: 'WEB:CON:CON:D',
        all: ['WEB:CON:CON:R', 'WEB:CON:CON:C', 'WEB:CON:CON:U', 'WEB:CON:CON:D'],
    },
}