import { PermissionModule } from "src/app/shared/models/role-permission";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

export const SYSTEM_USER_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    SYSTEM USER TITLE
    ==================================================*/
    {
        id: 'SYSTEM-USER-TITLE',
        name: 'System User ',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /***** User *****/
    {
        id: 'USER',
        name: 'User',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-USER-READ',
                name: 'View',
                code: CODE.USER.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-USER-CREATE',
                name: 'Create',
                code: CODE.USER.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-USER-UPDATE',
                name: 'Edit',
                code: CODE.USER.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-USER-DELETE',
                name: 'Active/Inactive',
                code: CODE.USER.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },
    /***** Role *****/
    {
        id: 'ROLE',
        name: 'Role ',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-ROLE-READ',
                name: 'View',
                code: CODE.ROLE.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-ROLE-CREATE',
                name: 'Create',
                code: CODE.ROLE.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-ROLE-UPDATE',
                name: 'Edit',
                code: CODE.ROLE.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-ROLE-DELETE',
                name: 'Active/Inactive',
                code: CODE.ROLE.delete,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },
]