export const INBOUND_RETURN_PERMISSION_CODE = {
    /*==================== MARKET RETURN ====================*/
    INB_RT_MARKET_RETURN: {
        code: 'INB:RTMK:RETURN',
        read: 'INB:RTMK:RETURN:R',
        create: 'INB:RTMK:RETURN:C',
        edit: 'INB:RTMK:RETURN:U',
        delete: 'INB:RTMK:RETURN:D',
        all: ['INB:RTMK:RETURN:R', 'INB:RTMK:RETURN:C', 'INB:RTMK:RETURN:U', 'INB:RTMK:RETURN:D'],
    },
    INB_RT_MARKET_QTY: {
        code: 'INB:RTMK:QTY',
        read: 'INB:RTMK:QTY:R',
        create: 'INB:RTMK:QTY:C',
        edit: 'INB:RTMK:QTY:U',
        delete: 'INB:RTMK:QTY:D',
        all: ['INB:RTMK:QTY:R', 'INB:RTMK:QTY:C', 'INB:RTMK:QTY:U', 'INB:RTMK:QTY:D'],
    },
    INB_RT_MARKET_SALES: {
        code: 'INB:RTMK:SALES',
        read: 'INB:RTMK:SALES:R',
        create: 'INB:RTMK:SALES:C',
        edit: 'INB:RTMK:SALES:U',
        delete: 'INB:RTMK:SALES:D',
        all: ['INB:RTMK:SALES:R', 'INB:RTMK:SALES:C', 'INB:RTMK:SALES:U', 'INB:RTMK:SALES:D'],
    },
    INB_RT_MARKET_DMS_DO: {
        code: 'INB:RTMK:DMSDO',
        read: 'INB:RTMK:DMSDO:R',
        create: 'INB:RTMK:DMSDO:C',
        edit: 'INB:RTMK:DMSDO:U',
        delete: 'INB:RTMK:DMSDO:D',
        all: ['INB:RTMK:DMSDO:R', 'INB:RTMK:DMSDO:C', 'INB:RTMK:DMSDO:U', 'INB:RTMK:DMSDO:D'],
    },
    INB_RT_MARKET_SAP_DO: {
        code: 'INB:RTMK:SAPDO',
        read: 'INB:RTMK:SAPDO:R',
        create: 'INB:RTMK:SAPDO:C',
        edit: 'INB:RTMK:SAPDO:U',
        delete: 'INB:RTMK:SAPDO:D',
        all: ['INB:RTMK:SAPDO:R', 'INB:RTMK:SAPDO:C', 'INB:RTMK:SAPDO:U', 'INB:RTMK:SAPDO:D'],
    },
    INB_RT_MARKET_INV: {
        code: 'INB:RTMK:INV',
        read: 'INB:RTMK:INV:R',
        create: 'INB:RTMK:INV:C',
        edit: 'INB:RTMK:INV:U',
        delete: 'INB:RTMK:INV:D',
        all: ['INB:RTMK:INV:R', 'INB:RTMK:INV:C', 'INB:RTMK:INV:U', 'INB:RTMK:INV:D'],
    },

    /*==================== PURCHASE RETURN ====================*/
    INB_RT_PURCHASE_RETURN: {
        code: 'INB:RTPO:RETURN',
        read: 'INB:RTPO:RETURN:R',
        create: 'INB:RTPO:RETURN:C',
        edit: 'INB:RTPO:RETURN:U',
        delete: 'INB:RTPO:RETURN:D',
        all: ['INB:RTPO:RETURN:R', 'INB:RTPO:RETURN:C', 'INB:RTPO:RETURN:U', 'INB:RTPO:RETURN:D'],
    },
    INB_RT_PURCHASE_SALES: {
        code: 'INB:RTPO:SALES',
        read: 'INB:RTPO:SALES:R',
        create: 'INB:RTPO:SALES:C',
        edit: 'INB:RTPO:SALES:U',
        delete: 'INB:RTPO:SALES:D',
        all: ['INB:RTPO:SALES:R', 'INB:RTPO:SALES:C', 'INB:RTPO:SALES:U', 'INB:RTPO:SALES:D'],
    },
    INB_RT_PURCHASE_DMS_DO: {
        code: 'INB:RTPO:DMSDO',
        read: 'INB:RTPO:DMSDO:R',
        create: 'INB:RTPO:DMSDO:C',
        edit: 'INB:RTPO:DMSDO:U',
        delete: 'INB:RTPO:DMSDO:D',
        all: ['INB:RTPO:DMSDO:R', 'INB:RTPO:DMSDO:C', 'INB:RTPO:DMSDO:U', 'INB:RTPO:DMSDO:D'],
    },
    INB_RT_PURCHASE_INV: {
        code: 'INB:RTPO:INV',
        read: 'INB:RTPO:INV:R',
        create: 'INB:RTPO:INV:C',
        edit: 'INB:RTPO:INV:U',
        delete: 'INB:RTPO:INV:D',
        all: ['INB:RTPO:INV:R', 'INB:RTPO:INV:C', 'INB:RTPO:INV:U', 'INB:RTPO:INV:D'],
    },

    /*==================== GRATIS RETURN ====================*/
    INB_RT_GRATIS_RETURN: {
        code: 'INB:RTGT:RETURN',
        read: 'INB:RTGT:RETURN:R',
        create: 'INB:RTGT:RETURN:C',
        edit: 'INB:RTGT:RETURN:U',
        delete: 'INB:RTGT:RETURN:D',
        all: ['INB:RTGT:RETURN:R', 'INB:RTGT:RETURN:C', 'INB:RTGT:RETURN:U', 'INB:RTGT:RETURN:D'],
    },
    INB_RT_GRATIS_SALES: {
        code: 'INB:RTGT:SALES',
        read: 'INB:RTGT:SALES:R',
        create: 'INB:RTGT:SALES:C',
        edit: 'INB:RTGT:SALES:U',
        delete: 'INB:RTGT:SALES:D',
        all: ['INB:RTGT:SALES:R', 'INB:RTGT:SALES:C', 'INB:RTGT:SALES:U', 'INB:RTGT:SALES:D'],
    },
    INB_RT_GRATIS_DMS_DO: {
        code: 'INB:RTGT:DMSDO',
        read: 'INB:RTGT:DMSDO:R',
        create: 'INB:RTGT:DMSDO:C',
        edit: 'INB:RTGT:DMSDO:U',
        delete: 'INB:RTGT:DMSDO:D',
        all: ['INB:RTGT:DMSDO:R', 'INB:RTGT:DMSDO:C', 'INB:RTGT:DMSDO:U', 'INB:RTGT:DMSDO:D'],
    },
    INB_RT_GRATIS_INV: {
        code: 'INB:RTGT:INV',
        read: 'INB:RTGT:INV:R',
        create: 'INB:RTGT:INV:C',
        edit: 'INB:RTGT:INV:U',
        delete: 'INB:RTGT:INV:D',
        all: ['INB:RTGT:INV:R', 'INB:RTGT:INV:C', 'INB:RTGT:INV:U', 'INB:RTGT:INV:D'],
    },

    /*==================== RIGN PULL RETURN ====================*/
    INB_RT_RING_RETURN: {
        code: 'INB:RTRP:RETURN',
        read: 'INB:RTRP:RETURN:R',
        create: 'INB:RTRP:RETURN:C',
        edit: 'INB:RTRP:RETURN:U',
        delete: 'INB:RTRP:RETURN:D',
        all: ['INB:RTRP:RETURN:R', 'INB:RTRP:RETURN:C', 'INB:RTRP:RETURN:U', 'INB:RTRP:RETURN:D'],
    },
    INB_RT_RING_SALES: {
        code: 'INB:RTRP:SALES',
        read: 'INB:RTRP:SALES:R',
        create: 'INB:RTRP:SALES:C',
        edit: 'INB:RTRP:SALES:U',
        delete: 'INB:RTRP:SALES:D',
        all: ['INB:RTRP:SALES:R', 'INB:RTRP:SALES:C', 'INB:RTRP:SALES:U', 'INB:RTRP:SALES:D'],
    },
    INB_RT_RING_DMS_DO: {
        code: 'INB:RTRP:DMSDO',
        read: 'INB:RTRP:DMSDO:R',
        create: 'INB:RTRP:DMSDO:C',
        edit: 'INB:RTRP:DMSDO:U',
        delete: 'INB:RTRP:DMSDO:D',
        all: ['INB:RTRP:DMSDO:R', 'INB:RTRP:DMSDO:C', 'INB:RTRP:DMSDO:U', 'INB:RTRP:DMSDO:D'],
    },
    INB_RT_RING_INV: {
        code: 'INB:RTRP:INV',
        read: 'INB:RTRP:INV:R',
        create: 'INB:RTRP:INV:C',
        edit: 'INB:RTRP:INV:U',
        delete: 'INB:RTRP:INV:D',
        all: ['INB:RTRP:INV:R', 'INB:RTRP:INV:C', 'INB:RTRP:INV:U', 'INB:RTRP:INV:D'],
    },

    /*==================== EMPTY RETURN ====================*/
    INB_RT_EMPTY_RETURN: {
        code: 'INB:RTMT:RETURN',
        read: 'INB:RTMT:RETURN:R',
        create: 'INB:RTMT:RETURN:C',
        edit: 'INB:RTMT:RETURN:U',
        delete: 'INB:RTMT:RETURN:D',
        all: ['INB:RTMT:RETURN:R', 'INB:RTMT:RETURN:C', 'INB:RTMT:RETURN:U', 'INB:RTMT:RETURN:D'],
    },
    INB_RT_EMPTY_SALES: {
        code: 'INB:RTMT:SALES',
        read: 'INB:RTMT:SALES:R',
        create: 'INB:RTMT:SALES:C',
        edit: 'INB:RTMT:SALES:U',
        delete: 'INB:RTMT:SALES:D',
        all: ['INB:RTMT:SALES:R', 'INB:RTMT:SALES:C', 'INB:RTMT:SALES:U', 'INB:RTMT:SALES:D'],
    },
    INB_RT_EMPTY_DMS_DO: {
        code: 'INB:RTMT:DMSDO',
        read: 'INB:RTMT:DMSDO:R',
        create: 'INB:RTMT:DMSDO:C',
        edit: 'INB:RTMT:DMSDO:U',
        delete: 'INB:RTMT:DMSDO:D',
        all: ['INB:RTMT:DMSDO:R', 'INB:RTMT:DMSDO:C', 'INB:RTMT:DMSDO:U', 'INB:RTMT:DMSDO:D'],
    },
    INB_RT_EMPTY_INV: {
        code: 'INB:RTMT:INV',
        read: 'INB:RTMT:INV:R',
        create: 'INB:RTMT:INV:C',
        edit: 'INB:RTMT:INV:U',
        delete: 'INB:RTMT:INV:D',
        all: ['INB:RTMT:INV:R', 'INB:RTMT:INV:C', 'INB:RTMT:INV:U', 'INB:RTMT:INV:D'],
    },
}