import { LOGIN_PERMISSION_MODULE } from './log-in-permission-module';
import { DASHBOARD_PERMISSION_MODULE } from './dashboard-permission-module';
import { SYSTEM_USER_PERMISSION_MODULE } from './system-user-permission-module';
import { TRANSACTION_PERMISSION_MODULE } from './transaction-permission-module';
import { INVENTORY_PERMISSION_MODULE } from './inventory-permission-module';
import { MASTER_DATA_PERMISSION_MODULE } from './master-data-permission-module';
import { PROGRAM_PERMISSION_MODULE } from './program-permission-module';
import { REPORT_PERMISSION_MODULE } from './report-permission-module';
import { SETTING_PERMISSION_MODULE } from './setting-permission-module';

export {
    LOGIN_PERMISSION_MODULE,
    DASHBOARD_PERMISSION_MODULE,
    SYSTEM_USER_PERMISSION_MODULE,
    TRANSACTION_PERMISSION_MODULE,
    INVENTORY_PERMISSION_MODULE,
    MASTER_DATA_PERMISSION_MODULE,
    PROGRAM_PERMISSION_MODULE,
    REPORT_PERMISSION_MODULE,
    SETTING_PERMISSION_MODULE,
}