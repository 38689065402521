export const MASTER_OUTLET_DEALER_DOWNLINE_PERMISSION_CODE = {
    /*==================== OUTLET ====================*/
    OUTLET_LIST: {
        code: 'WEB:OUTLET:OUTLET',
        read: 'WEB:OUTLET:OUTLET:R',
        create: 'WEB:OUTLET:OUTLET:C',
        edit: 'WEB:OUTLET:OUTLET:U',
        delete: 'WEB:OUTLET:OUTLET:D',
        all: ['WEB:OUTLET:OUTLET:R', 'WEB:OUTLET:OUTLET:C', 'WEB:OUTLET:OUTLET:U', 'WEB:OUTLET:OUTLET:D'],
    },
    OUTLET_GROUP: {
        code: 'WEB:OUTLET:GROUP',
        read: 'WEB:OUTLET:GROUP:R',
        create: 'WEB:OUTLET:GROUP:C',
        edit: 'WEB:OUTLET:GROUP:U',
        delete: 'WEB:OUTLET:GROUP:D',
        all: ['WEB:OUTLET:GROUP:R', 'WEB:OUTLET:GROUP:C', 'WEB:OUTLET:GROUP:U', 'WEB:OUTLET:GROUP:D'],
    },
    OUTLET_CONTRACT: {
        code: 'WEB:OUTLET:CONTRACT',
        read: 'WEB:OUTLET:CONTRACT:R',
        create: 'WEB:OUTLET:CONTRACT:C',
        edit: 'WEB:OUTLET:CONTRACT:U',
        delete: 'WEB:OUTLET:CONTRACT:D',
        all: ['WEB:OUTLET:CONTRACT:R', 'WEB:OUTLET:CONTRACT:C', 'WEB:OUTLET:CONTRACT:U', 'WEB:OUTLET:CONTRACT:D'],
    },

    /*==================== DEALER ====================*/
    DEALER_LIST: {
        code: 'WEB:DEALER:DEALER',
        read: 'WEB:DEALER:DEALER:R',
        create: 'WEB:DEALER:DEALER:C',
        edit: 'WEB:DEALER:DEALER:U',
        delete: 'WEB:DEALER:DEALER:D',
        all: ['WEB:DEALER:DEALER:R', 'WEB:DEALER:DEALER:C', 'WEB:DEALER:DEALER:U', 'WEB:DEALER:DEALER:D'],
    },

    /*==================== DOWNLINE ====================*/
    DOWNLINE_LIST: {
        code: 'WEB:EMP:DOWNLINE',
        read: 'WEB:EMP:DOWNLINE:R',
        create: 'WEB:EMP:DOWNLINE:C',
        edit: 'WEB:EMP:DOWNLINE:U',
        delete: 'WEB:EMP:DOWNLINE:D',
        all: ['WEB:EMP:DOWNLINE:R', 'WEB:EMP:DOWNLINE:C', 'WEB:EMP:DOWNLINE:U', 'WEB:EMP:DOWNLINE:D'],
    },
    
    /*==================== EMPLOYEE ====================*/
    EMPLOYEE_LIST: {
        code: 'WEB:EMP:USER',
        read: 'WEB:EMP:USER:R',
        create: 'WEB:EMP:USER:C',
        edit: 'WEB:EMP:USER:U',
        delete: 'WEB:EMP:USER:D',
        all: ['WEB:EMP:USER:R', 'WEB:EMP:USER:C', 'WEB:EMP:USER:U', 'WEB:EMP:USER:D'],
    },
    EMPLOYEE_DEVICE_CONTRACT: {
        code: 'WEB:DEVICE:CONTRACT',
        read: 'WEB:DEVICE:CONTRACT:R',
        create: 'WEB:DEVICE:CONTRACT:C',
        edit: 'WEB:DEVICE:CONTRACT:U',
        delete: 'WEB:DEVICE:CONTRACT:D',
        all: ['WEB:DEVICE:CONTRACT:R', 'WEB:DEVICE:CONTRACT:C', 'WEB:DEVICE:CONTRACT:U', 'WEB:DEVICE:CONTRACT:D'],
    },
};