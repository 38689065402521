import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    forgotPwdForm: FormGroup;
    errMsg = '';
    submitted = false;
    emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
    constructor(
        private fb: FormBuilder,
        private _loginService: LoginService,
        private _route: Router
    ) {}

    ngOnInit() {
        this.createForm();
    }
    createForm() {
        this.forgotPwdForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }
    get f() {
        return this.forgotPwdForm.controls;
    }

    form(control) {
        return this.forgotPwdForm.get(control).value;
    }
    onSubmitBtn() {
        this.submitted = true;
        let body;
        if (this.submitted && this.forgotPwdForm.invalid) {
            return;
        }
        if (this.forgotPwdForm.valid) {
            body = {
                email: this.forgotPwdForm.get('email').value.trim(),
            };
            this._loginService.requestToken().subscribe(
                (res: any) => {
                    const auth = `Bearer ${res.token}`;
                    this.forgotPwd(body, auth);
                },
                (err) => {
                    this.errMsg = err.error.message;
                }
            );
        }
    }
    forgotPwd(body: any, auth: string) {
        this._loginService.forgotPassword(body, auth).subscribe(
            (res: any) => {
                this._route.navigate(['/login']);
            },
            (err) => {
                this.errMsg = err.error.message;
            }
        );
    }
}
