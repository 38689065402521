import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageService } from 'src/app/shared/helper-services/alert-message.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    errMsg = '';
    submitted = false;
    resetForm: FormGroup;
    error: boolean;
    auth: string;
    resetToken: string;
    email: string;
    constructor(
        private route: Router,
        private formBuilder: FormBuilder,
        private _loginService: LoginService,
        private activateRoute: ActivatedRoute,
        private _alert: AlertMessageService
    ) {}

    ngOnInit() {
        this.createForm();
        /*
         *  Get data from query param
         */
        this.resetToken = this.activateRoute.snapshot.queryParamMap.get(
            'resetToken'
        );
        this.requetAuth();
    }
    get f() {
        return this.resetForm.controls;
    }

    form(control) {
        return this.resetForm.get(control).value;
    }
    createForm() {
        this.resetForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPwd: ['', Validators.required],
        });
    }

    onSubmit() {
        this.submitted = true;
        if (
            this.resetForm.invalid ||
            this.form('password').trim() !== this.form('confirmPwd').trim()
        ) {
            return;
        }
        if (this.error === false) {
            const body = {
                email: this.email,
                password: this.form('password').trim(),
                confirmCode: this.resetToken,
            };
            this.resetPassword(body);
        }
    }

    requetAuth() {
        this._loginService.requestToken().subscribe(
            (res: any) => {
                this.auth = `Bearer ${res.token}`;
                this.getEmail(this.auth);
                this.error = false;
            },
            (err) => {
                this.error = true;
            }
        );
    }
    /*
     *  Verify and get email
     */
    getEmail(auth: string) {
        this._loginService.getURL(this.resetToken, auth).subscribe(
            (res: any) => {
                this.email = res.email;
            },
            (err: any) => {
                this.errMsg = err.error.message;
            }
        );
    }
    /*
     *  Reset Password
     */
    resetPassword(body: object) {
        this._loginService.postResetPassword(body, this.auth).subscribe(
            (res: any) => {
                this._alert.info('Reset password successfully');
                this.route.navigate(['/login']);
            },
            (err) => {
                this.errMsg = err.error.message;
            }
        );
    }
}
