import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as ProfileAction from 'src/app/shared/app-state/profile.actions';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app-state/app.state';
import { of } from 'rxjs';
import { switchMap, take, tap, filter, map, catchError } from 'rxjs/operators';
import { CurrentUserService } from '../helper-services/current-user.service';
import { LoginService } from '../services/login.service';

const OutletCensusPermission = {
    READ: 'ADMIN:CENSUS:READ',
    CREATE: 'ADMIN:CENSUS:CREATE',
    UPDATE: 'ADMIN:CENSUS:UPDATE',
    DELETE: 'ADMIN:CENSUS:DELETE'
}

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private store: Store<AppState>,
        private _currentUser: CurrentUserService
    ) { }

    getFromStoreOrAPI(): Observable<any> {
        return this.store.pipe(
            // get data from ngrx store
            select('profile'),
            tap((data: any) => {
                // check if ngrx store doesn't has data
                if (!data.length) {
                    // request data from api
                    this._loginService.getUserProfile().subscribe(
                        (res: any) => {
                            if (
                                res.permissions &&
                                res.permissions.includes(
                                    OutletCensusPermission.CREATE
                                )
                            ) {
                                res.isCensus = true;
                            } else {
                                res.isCensus = false;
                            }

                            // set data to ngrx store
                            this.store.dispatch(
                                new ProfileAction.AddProfile(res)
                            );
                        },
                        () => {
                            // error remove localStorage and redirect to login again
                            localStorage.removeItem('token');
                            // localStorage.removeItem('isLoggedinExpire');
                            // localStorage.removeItem('roleCode');
                            this._router.navigate(['/login']);
                        }
                    );
                }
            }),
            // check data in ngrx store again if has data
            filter((data: any) => data.length),
            take(1)
        );
    }
    canActivate() {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}
