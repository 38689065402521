import { PermissionModule } from "src/app/shared/models/role-permission";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

export const INVENTORY_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    INVENTORY TITLE
    ==================================================*/
    {
        id: 'INVENTORY-TITLE',
        name: 'Inventory',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    INVENTORY ON HAND
    ==================================================*/
    {
        id: 'INVENTORY-ON-HAND',
        name: 'Inventory On Hand',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-FIRM-INVENTORY-ON-HAND-READ',
                name: 'View',
                code: CODE.INVENTORY_ON_HAND.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    OVERALL INVENTORY
    ==================================================*/
    {
        id: 'OVERALL-INVENTORY',
        name: 'Overall Inventory',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-FIRM-INVENTORY-OVERALL',
                name: 'Overall Inventory List',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-INVENTORY-OVERALL-READ',
                        name: 'View',
                        code: CODE.OVERALL_INVTORY_LIST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-INVENTORY-SAFE-STOCK',
                name: 'Safe Stock',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-SAFE-STOCK-READ',
                        name: 'View',
                        code: CODE.OVERALL_SAFE_STOCK.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-SAFE-STOCK-CREATE',
                        name: 'Create',
                        code: CODE.OVERALL_SAFE_STOCK.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-SAFE-STOCK-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.OVERALL_SAFE_STOCK.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    INVENTORY TRANSACTION
    ==================================================*/
    {
        id: 'INVENTORY-TRANSACTION',
        name: 'Inventory Transaction',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'INVENTORY-TRANSACTION-GOODS-RECEIVE',
                name: 'Stock Goods Receive',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-GOODS-RECEIVE-READ',
                        name: 'View',
                        code: CODE.INVENTORY_GOODS_RECEIVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-GOODS-RECEIVE-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_GOODS_RECEIVE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-GOODS-RECEIVE-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_GOODS_RECEIVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'INVENTORY-TRANSACTION-ADJUSTMENT',
                name: 'Stock Adjustment',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-ADJUSTMENT-READ',
                        name: 'View',
                        code: CODE.INVENTORY_ADJUSTMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-ADJUSTMENT-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_ADJUSTMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-ADJUSTMENT-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_ADJUSTMENT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'INVENTORY-TRANSACTION-COUNTING',
                name: 'Stock Opening',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-COUNTING-READ',
                        name: 'View',
                        code: CODE.INVENTORY_COUNTING.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-COUNTING-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_COUNTING.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-COUNTING-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_COUNTING.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'INVENTORY-TRANSACTION-TRANSFER',
                name: 'Stock Transfer',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-TRANSFER-READ',
                        name: 'View',
                        code: CODE.INVENTORY_TRANSFER.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-TRANSFER-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_TRANSFER.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-TRANSFER-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_TRANSFER.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'INVENTORY-TRANSACTION-PERSONAL-USE',
                name: 'Stock Internal Use',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-PERSONAL-USE-READ',
                        name: 'View',
                        code: CODE.INVENTORY_PERSONAL_USE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-PERSONAL-USE-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_PERSONAL_USE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-PERSONAL-USE-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_PERSONAL_USE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'INVENTORY-TRANSACTION-RECONCILE',
                name: 'Stock Reconcile',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-RECONCILE-READ',
                        name: 'View',
                        code: CODE.INVENTORY_RECONCILE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-RECONCILE-CREATE',
                        name: 'Create',
                        code: CODE.INVENTORY_RECONCILE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-RECONCILE-UPDATE',
                        name: 'Edit',
                        code: CODE.INVENTORY_RECONCILE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },
]