import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AppTranslateService } from './app-translate.service';

@Injectable({
    providedIn: 'root'
})
export class AlertMessageService {
    constructor(private izitoast: Ng2IzitoastService, private translator: AppTranslateService) {}

    info(text: string) {
        text = this.translator.keysArrayInToAString([text]);
        return this.izitoast.show({
            message: text,
            class: '',
            progressBarColor: '#177246',
            backgroundColor: '#B7EBCE',
            titleColor: '#177246',
            messageColor: '#177246',
            close: true,
            iconColor: '#177246',
            timeout: 10000
        });
    }

    error(text: string) {
        text = this.translator.keysArrayInToAString([text]);
        return this.izitoast.show({
            message: text,
            class: '',
            progressBarColor: '#d8000c',
            backgroundColor: '#F7C1BB',
            titleColor: 'd8000c',
            messageColor: '#d8000c',
            close: true,
            iconColor: '#d8000c',
            timeout: 10000
        });
    }

    notification(payload) {
        return this.izitoast.show({
            theme: 'dark',
            icon: 'icon-person',
            title: payload.title,
            image: payload.image || '/assets/images/default_outlet_profile.png',
            layout: 2,
            imageWidth: 60,
            message: payload.body,
            position: 'topRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
            progressBarColor: 'rgb(0, 255, 184)',
            onOpening: (instance, toast) => {
                console.log('callback abriu!');
            },
            onClosing: (instance, toast, closedBy) => {
                console.log('closedBy: ' + closedBy); // tells if it was closed by 'drag' or 'button'
            },
            onOpened: (instance, toast) => {
                console.log('opensss');
            }
        });
    }
}
