import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CurrentUserService } from '../helper-services/current-user.service';

@Injectable({
    providedIn: 'root',
})
export class AccessModuleGuard implements CanActivate {
    constructor(
        private _currentUser: CurrentUserService,
        private router: Router
    ) {}

    canActivate() {
        if (this._currentUser.isCensus) {
            this.router.navigate(['outlet-census']);
            return false;
        } else return true;
    }
}
