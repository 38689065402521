export const KPI_MOA_OUTLET_PRICE_MAP_PERMISSION_CODE = {
    /*==================== KPI ====================*/
    KPI_DEALER_VOLUME: {
        code: 'WEB:KPI:DEALER',
        read: 'WEB:KPI:DEALER:R',
        create: 'WEB:KPI:DEALER:C',
        edit: 'WEB:KPI:DEALER:U',
        delete: 'WEB:KPI:DEALER:D',
        all: ['WEB:KPI:DEALER:R', 'WEB:KPI:DEALER:C', 'WEB:KPI:DEALER:U', 'WEB:KPI:DEALER:D'],
    },
    KPI_DEALER_POSM: {
        code: 'WEB:KPI:POSM',
        read: 'WEB:KPI:POSM:R',
        create: 'WEB:KPI:POSM:C',
        edit: 'WEB:KPI:POSM:U',
        delete: 'WEB:KPI:POSM:D',
        all: ['WEB:KPI:POSM:R', 'WEB:KPI:POSM:C', 'WEB:KPI:POSM:U', 'WEB:KPI:POSM:D'],
    },
    KPI_DOWNLINE_VOLUME: {
        code: 'WEB:KPI:SR',
        read: 'WEB:KPI:SR:R',
        create: 'WEB:KPI:SR:C',
        edit: 'WEB:KPI:SR:U',
        delete: 'WEB:KPI:SR:D',
        all: ['WEB:KPI:SR:R', 'WEB:KPI:SR:C', 'WEB:KPI:SR:U', 'WEB:KPI:SR:D'],
    },

    /*==================== MOA ====================*/
    MOA_LIST: {
        code: 'WEB:PST:BUDGET',
        read: 'WEB:PST:BUDGET:R',
        create: 'WEB:PST:BUDGET:C',
        edit: 'WEB:PST:BUDGET:U',
        delete: 'WEB:PST:BUDGET:D',
        all: ['WEB:PST:BUDGET:R', 'WEB:PST:BUDGET:C', 'WEB:PST:BUDGET:U', 'WEB:PST:BUDGET:D'],
    },

    /*==================== OUTLET PRICE GROUP ====================*/
    OUTLET_PRICE_GROUP: {
        code: 'WEB:PRICE:GROUP',
        read: 'WEB:PRICE:GROUP:R',
        create: 'WEB:PRICE:GROUP:C',
        edit: 'WEB:PRICE:GROUP:U',
        delete: 'WEB:PRICE:GROUP:D',
        all: ['WEB:PRICE:GROUP:R', 'WEB:PRICE:GROUP:C', 'WEB:PRICE:GROUP:U', 'WEB:PRICE:GROUP:D'],
    },
    OUTLET_MANAGE_PRICE: {
        code: 'WEB:PRICE:PRODUCT',
        read: 'WEB:PRICE:PRODUCT:R',
        create: 'WEB:PRICE:PRODUCT:C',
        edit: 'WEB:PRICE:PRODUCT:U',
        delete: 'WEB:PRICE:PRODUCT:D',
        all: ['WEB:PRICE:PRODUCT:R', 'WEB:PRICE:PRODUCT:C', 'WEB:PRICE:PRODUCT:U', 'WEB:PRICE:PRODUCT:D'],
    },
    OUTLET_MANAGE_PRICE_EFFECTIVE: {
        code: 'WEB:PRICE:PENDING',
        read: 'WEB:PRICE:PENDING:R',
        create: 'WEB:PRICE:PENDING:C',
        edit: 'WEB:PRICE:PENDING:U',
        delete: 'WEB:PRICE:PENDING:D',
        all: ['WEB:PRICE:PENDING:R', 'WEB:PRICE:PENDING:C', 'WEB:PRICE:PENDING:U', 'WEB:PRICE:PENDING:D'],
    },
    
    /*==================== MAP ====================*/
    MAP_LIVE: {
        code: 'WEB:MEP:LIVE',
        read: 'WEB:MEP:LIVE:R',
        create: 'WEB:MEP:LIVE:C',
        edit: 'WEB:MEP:LIVE:U',
        delete: 'WEB:MEP:LIVE:D',
        all: ['WEB:MEP:LIVE:R', 'WEB:MEP:LIVE:C', 'WEB:MEP:LIVE:U', 'WEB:MEP:LIVE:D'],
    },

    /*==================== FRIDGE ====================*/
    FRIDGE: {
        code: 'WEB:FRIDGE:MASTER',
        read: 'WEB:FRIDGE:MASTER:R',
        create: 'WEB:FRIDGE:MASTER:C',
        edit: 'WEB:FRIDGE:MASTER:U',
        delete: 'WEB:FRIDGE:MASTER:D',
        all: ['WEB:FRIDGE:MASTER:R', 'WEB:FRIDGE:MASTER:C', 'WEB:FRIDGE:MASTER:U', 'WEB:FRIDGE:MASTER:D'],
    },

    /*==================== BA/SA ====================*/
    BA_SA: {
        code: 'WEB:BA:MASTER',
        read: 'WEB:BA:MASTER:R',
        create: 'WEB:BA:MASTER:C',
        edit: 'WEB:BA:MASTER:U',
        delete: 'WEB:BA:MASTER:D',
        all: ['WEB:BA:MASTER:R', 'WEB:BA:MASTER:C', 'WEB:BA:MASTER:U', 'WEB:BA:MASTER:D'],
    },
}