import { ALL_PERMISSION_CODE as CODE } from '../assigning/all-permission-code';

const MENU_LV_4 = {
    /*==================== TRANSACTION/ INBOUND / RETURN / MARKET ====================*/
    MENU_LV4_INB_RETURN_MARKET: {
        all: [
            ...CODE.INB_RT_MARKET_RETURN.all,
            ...CODE.INB_RT_MARKET_QTY.all,
            ...CODE.INB_RT_MARKET_SALES.all,
            ...CODE.INB_RT_MARKET_DMS_DO.all,
            ...CODE.INB_RT_MARKET_SAP_DO.all,
            ...CODE.INB_RT_MARKET_INV.all
        ]
    },
    /*==================== TRANSACTION/ INBOUND / RETURN / PURCHASE ====================*/
    MENU_LV4_INB_RETURN_PURCHASE: {
        all: [...CODE.INB_RT_PURCHASE_RETURN.all, ...CODE.INB_RT_PURCHASE_SALES.all, ...CODE.INB_RT_PURCHASE_DMS_DO.all, ...CODE.INB_RT_PURCHASE_INV.all]
    },
    /*==================== TRANSACTION/ INBOUND / RETURN / GRATIS ====================*/
    MENU_LV4_INB_RETURN_GRATIS: {
        all: [...CODE.INB_RT_GRATIS_RETURN.all, ...CODE.INB_RT_GRATIS_SALES.all, ...CODE.INB_RT_GRATIS_DMS_DO.all, ...CODE.INB_RT_GRATIS_INV.all]
    },
    /*==================== TRANSACTION/ INBOUND / RETURN / RING ====================*/
    MENU_LV4_INB_RETURN_RING: {
        all: [...CODE.INB_RT_RING_RETURN.all, ...CODE.INB_RT_RING_SALES.all, ...CODE.INB_RT_RING_DMS_DO.all, ...CODE.INB_RT_RING_INV.all]
    },
    /*==================== TRANSACTION/ INBOUND / RETURN / EMPTY ====================*/
    MENU_LV4_INB_RETURN_EMPTY: {
        all: [...CODE.INB_RT_EMPTY_RETURN.all, ...CODE.INB_RT_EMPTY_SALES.all, ...CODE.INB_RT_EMPTY_DMS_DO.all, ...CODE.INB_RT_EMPTY_INV.all]
    },
    /*==================== TRANSACTION/ OUTBOUND / SIGNAGE / REQUEST ====================*/
    MENU_LV4_SIGNAGE_REQUEST: {
        all: [...CODE.SIGNAGE_REQUEST_TRANSACTION.all, ...CODE.SIGNAGE_REQUEST_DEPLOY.all]
    },
    /*==================== TRANSACTION / OUTBOUND / TRADE ASSETS / REQUEST ====================*/
    MENU_LV4_ASSET_REQUEST: {
        all: [...CODE.ASSET_REQUEST_TRANSACTION.all, ...CODE.ASSET_REQUEST_DEPLOY.all]
    },
    /*==================== TRANSACTION / OUTBOUND / BA SA / REQUEST ====================*/
    MENU_LV4_BA_REQUEST: {
        all: [...CODE.BA_REQUEST_PENDING.all, ...CODE.BA_DEPLOY.all]
    },
    MENU_LV4_BA_RETURN: {
        all: [...CODE.BA_RETURN_PENDING.all, ...CODE.BA_RETURN.all]
    }
};

const MENU_LV_3 = {
    /*==================== INBOUND ORDER ====================*/
    /*==================== TRANSACTION / INBOUND / ORDER ====================*/
    MENU_LV3_PURCHASE_ORDER: {
        all: [
            ...CODE.PURCHASE_ORDER.all,
            ...CODE.PURCHASE_ORDER_SALE.all,
            ...CODE.PURCHASE_ORDER_GOODS_RECEIVE.all,
            ...CODE.PURCHASE_ORDER_INVOICE.all,
            ...CODE.PURCHASE_ORDER_PAYMENT.all
        ]
    },
    /*==================== TRANSACTION / INBOUND / GRATIS CLAIM ====================*/
    MENU_LV3_GRATIS_CLAIM: {
        all: [
            ...CODE.GRATIS_CLAIM_APPROVAL.all,
            ...CODE.GRATIS_CLAIM_HISTORY.all,
            ...CODE.GRATIS_CLAIM.all,
            ...CODE.GRATIS_CLAIM_SALE.all,
            ...CODE.GRATIS_CLAIM_GOODS_RECEIVE.all,
            ...CODE.GRATIS_CLAIM_INVOICE.all,
            ...CODE.GRATIS_CLAIM_PAYMENT.all
        ]
    },
    /*==================== TRANSACTION / INBOUND / RING PULL CLAIM ====================*/
    MENU_LV3_RING_PULL_CLAIM: {
        all: [
            ...CODE.RING_PULL_CLAIM.all,
            ...CODE.RING_PULL_CLAIM_SALE.all,
            ...CODE.RING_PULL_CLAIM_GOODS_RECEIVE.all,
            ...CODE.RING_PULL_CLAIM_INVOICE.all,
            ...CODE.RING_PULL_CLAIM_PAYMENT.all
        ]
    },
    /*==================== TRANSACTION / INBOUND / RETURN ====================*/
    MENU_LV3_INB_RETURN: {
        all: [
            ...MENU_LV_4.MENU_LV4_INB_RETURN_MARKET.all,
            ...MENU_LV_4.MENU_LV4_INB_RETURN_PURCHASE.all,
            ...MENU_LV_4.MENU_LV4_INB_RETURN_GRATIS.all,
            ...MENU_LV_4.MENU_LV4_INB_RETURN_RING.all,
            ...MENU_LV_4.MENU_LV4_INB_RETURN_EMPTY.all
        ]
    },

    /*==================== OUTBOUND ORDER ORDER ====================*/
    /*==================== TRANSACTION / OUTBOUND / ORDER ====================*/
    MENU_LV3_SALES_ORDER: {
        all: [
            ...CODE.SALE_ORDER.all,
            ...CODE.SO_RING_PULL_REDEMPTION.all,
            ...CODE.PRODUCT_REIMBURSEMENT.all,
            ...CODE.OUTBOUND_CLAIM.all,
            ...CODE.MANUAL_CLAIM.all,
            ...CODE.SALE_ORDER_DELIVERY_ORDER.all,
            ...CODE.SALE_ORDER_DELIVERY_ROUTE.all,
            ...CODE.SALE_ORDER_INVOICE.all,
            ...CODE.SALE_ORDER_PAYMENT.all
        ]
    },
    /*==================== TRANSACTION / OUTBOUND / SALE ORDER RETURN ====================*/
    MENU_LV3_ORDER_RETURN: {
        all: [...CODE.PRODUCT_RETURN.all, ...CODE.EMPTY_KEG_RETURN.all]
    },
    /*==================== TRANSACTION / OUTBOUND / PRODUCT RETURN ====================*/
    MENU_LV3_PRODUCT_RETURN: {
        all: [...CODE.PRODUCT_RETURN_TRANSACTION.all, ...CODE.PRODUCT_RETURN_APPROVE.all]
    },
    /*==================== TRANSACTION / OUTBOUND / PRODUCT ROTATION ====================*/
    MENU_LV3_PRODUCT_ROTATION: {
        all: [...CODE.PRODUCT_ROTATION_TRANSACTION.all, ...CODE.PRODUCT_ROTATION_APPROVE.all]
    },
    /*==================== TRANSACTION / OUTBOUND / DIRECT SALES ====================*/
    MENU_LV3_DIRECT_SALES: {
        all: [
            ...CODE.DIRECT_SALES_STOCK_REQUEST.all,
            ...CODE.DIRECT_SALES_STOCK_REPLENISH.all,
            ...CODE.DIRECT_SALES_STOCK_TRANSFER.all,
            ...CODE.DIRECT_SALES_VAN_ROUTE.all
        ]
    },
    /*==================== TRANSACTION / OUTBOUND / SIGNAGE ====================*/
    MENU_LV3_OUTBOUND_SIGNAGE: {
        all: [...MENU_LV_4.MENU_LV4_SIGNAGE_REQUEST.all, ...CODE.ASSET_RETURN.all]
    },
    /*==================== TRANSACTION / OUTBOUND / ASSET ====================*/
    MENU_LV3_OUTBOUND_ASSET: {
        all: [...MENU_LV_4.MENU_LV4_ASSET_REQUEST.all, ...CODE.ASSET_RETURN.all]
    },
    /*==================== TRANSACTION / OUTBOUND / POSM ====================*/
    MENU_LV3_OUTBOUND_POSM: {
        all: [...CODE.POSM_TRANSACTION.all, ...CODE.POSM_REQUEST.all, ...CODE.POSM_RETURN.all, ...CODE.POSM_DEPLOY.all]
    },
    /*==================== TRANSACTION / OUTBOUND / BA ====================*/
    MENU_LV3_OUTBOUND_BA: {
        all: [...MENU_LV_4.MENU_LV4_BA_REQUEST.all, ...MENU_LV_4.MENU_LV4_BA_RETURN.all]
    },
    /*==================== TRANSACTION / OUTBOUND / NEW OUTLET ====================*/
    MENU_LV3_OUTBOUND_NEW_OUTLET: {
        all: [...CODE.NEW_OUTLET_REQUEST_LIST.all, ...CODE.NEW_OUTLET_REQUEST_HISTORY.all]
    },

    /*==================== CALL PLAN ====================*/
    /*==================== MASTER DATA / CALL PLAN / MANAGE ROUTE ====================*/
    MENU_LV3_CALL_MANAGE_ROUTE: {
        all: [...CODE.CALL_PLAN_BY_DAY.all, ...CODE.CALL_PLAN_BY_DATE.all, ...CODE.CALL_PLAN_COVERAGE.all, ...CODE.CALL_PLAN_HOLIDAY.all]
    },

    /*==================== MATERIAL ====================*/
    /*==================== MASTER DATA / MATERIAL / PRODUCT ====================*/
    MENU_LV3_MATERIAL_PRODUCT: {
        all: [...CODE.PRODUCT_LIST.all, ...CODE.PRODUCT_GROUP.all]
    },
    /*==================== MASTER DATA / MATERIAL / COMPONENT ====================*/
    MENU_LV3_MATERIAL_COMPONENT: {
        all: [
            ...CODE.ASSET_LIST.all,
            ...CODE.POSM_LIST.all,
            ...CODE.EMPTY_KEG_LIST.all,
            ...CODE.RING_PULL_LIST.all,
            ...CODE.PALLET_LIST.all,
            ...CODE.SIGNAGE_LIST.all
        ]
    },

    /*==================== KPI TARGET ====================*/
    /*==================== MASTER DATA / KPI TARGET / DEALER ====================*/
    MENU_LV3_KPI_DEALER: {
        all: [...CODE.KPI_DEALER_VOLUME.all, ...CODE.KPI_DEALER_POSM.all]
    },
    /*==================== MASTER DATA / KPI TARGET / DOWNLINE ====================*/
    MENU_LV3_KPI_DOWNLINE: {
        all: [...CODE.KPI_DOWNLINE_VOLUME.all]
    },

    /*==================== MASTER CONFIG ====================*/
    /*==================== SETTING / MASTER COFIG / OUTLET ====================*/
    MENU_LV3_CONFIG_OUTLET: {
        all: [...CODE.BUSINESS_NATURE.all, ...CODE.CHANNEL_TYPE.all, ...CODE.OUTLET_TYPE.all, ...CODE.VOLUME_CLASS.all]
    },

    /*==================== SETTING ====================*/
    /*==================== SETTING / MASTER COFIG / DEALER ====================*/
    MENU_LV3_CONFIG_DEALER: {
        all: [...CODE.DEALER_REGION.all]
    },
    /*==================== SETTING / MASTER COFIG / DOWNLINE ====================*/
    MENU_LV3_CONFIG_DONWLINE: {
        all: [...CODE.DEPARTMENT_MASTER.all, ...CODE.EMPLOYEE_POSITION.all]
    },
    /*==================== SETTING / MASTER COFIG / INVENTORY ====================*/
    MENU_LV3_CONFIG_INVENTORY: {
        all: [...CODE.WAREHOUSE.all, ...CODE.ZONE.all, ...CODE.VAN.all, ...CODE.DOWNLINE_STORAGE.all, ...CODE.INVENTORY_CLASS.all]
    },
    /*==================== SETTING / MASTER COFIG / MATERIAL ====================*/
    MENU_LV3_CONFIG_MATERIAL: {
        all: [...CODE.TREE_COMPANY.all, ...CODE.TREE_DIVISION.all, ...CODE.TREE_CATEGORY.all, ...CODE.TREE_BRAND.all, ...CODE.DISPLAY_GROUP.all]
    },
    /*==================== SETTING / MASTER COFIG / GENERAL ====================*/
    MENU_LV3_CONFIG_GENERAL: {
        all: [
            ...CODE.BANK.all,
            ...CODE.REGION.all,
            ...CODE.GEO.all,
            ...CODE.DEFAULT_ZONE.all,
            ...CODE.REASON.all,
            ...CODE.EXCHANGE_RATE.all,
            ...CODE.CONVERSION.all
        ]
    },

    /*==================== REPORT ====================*/
    /*==================== REPORT / INBOUND / PURCHASE ORDER ====================*/
    MENU_LV3_REP_IB_PURCHASE_ORDER: {
        all: [
            ...CODE.REP_IB_PURCHASE_REMAINING.all,
            ...CODE.REP_IB_PURCHASE_ORDER.all,
            ...CODE.REP_IB_PURCHASE_DELIVERY.all,
            ...CODE.REP_IB_CUST_PAYMENT.all,
            ...CODE.REP_IB_DEALER_PURCHASE.all
        ]
    },
    /*==================== REPORT / INBOUND / RING PULL ====================*/
    MENU_LV3_REP_IB_RING_PULL: {
        all: [...CODE.REP_IB_RING_REMAINING.all, ...CODE.REP_IB_RING_PULL.all, ...CODE.REP_IB_RING_DELIVERY.all]
    },
    /*==================== REPORT / INBOUND / GRATIS ====================*/
    MENU_LV3_REP_IB_GRATIS: {
        all: [...CODE.REP_IB_GRATIS_REMAINING.all, ...CODE.REP_IB_GRATIS_CLAIM.all, ...CODE.REP_IB_GRATIS_DELIVERY.all]
    },
    /*==================== REPORT / INBOUND / SALES REPORT ====================*/
    MENU_LV3_REP_IB_SALES_REPORT: {
        all: [...CODE.REP_IB_SALES_REPORT.all, ...CODE.REP_IB_SALES_SUMMARY.all]
    },
    /*==================== REPORT / INBOUND / RETURN ====================*/
    MENU_LV3_REP_IB_RETURN: {
        all: [...CODE.REP_IB_RETURN_PO.all, ...CODE.REP_IB_RETURN_DO.all]
    },
    /*==================== REPORT / OUTBOUND / SALES REPORT ====================*/
    MENU_LV3_REP_OB_SALES_REPORT: {
        all: [
            ...CODE.REP_OB_DAILY_SHIP_PLAN.all,
            ...CODE.REP_OB_SALE_DAILY_STATUS.all,
            ...CODE.REP_OB_SALE_TRANSACTION.all,
            ...CODE.REP_OB_SALE_SUMMARY.all,
            ...CODE.REP_OB_PROD_RETURN.all,
            ...CODE.REP_OB_PROD_ROTATION.all,
            ...CODE.REP_OB_SO_REMAINING.all
        ]
    },
    /*==================== REPORT / OUTBOUND / KPI AND PERFORMANCE ====================*/
    MENU_LV3_REP_OB_KPI_PERFORMANCE: {
        all: [
            ...CODE.REP_OB_CALL_COMPLIANCE.all,
            ...CODE.REP_OB_SS_CALL_COMPLIANCE.all,
            ...CODE.REP_OB_OUTLET_PERF_SUMMARY.all,
            ...CODE.REP_OB_OUTLET_PERF_SUMMARY_DO.all,
            ...CODE.REP_OB_OUTLET_PERF_SUMMARY_DO_NEW.all,
            ...CODE.REP_OB_MONTHLY_SALE_DEALER_CATEGORY.all,
            ...CODE.REP_OB_MONTHLY_SALE_DEALER_PROD.all,
            ...CODE.REP_OB_PROD_AVAIL_OUTLET.all,
            ...CODE.REP_OB_PROD_AVAIL_SUMMARY.all,
            ...CODE.REP_OB_NEW_OUTLET.all,
            ...CODE.REP_OB_SURVEY_TRANSACTION.all,
            ...CODE.REP_OB_OUTLET_VISIT_SUMMARY.all,
            ...CODE.REP_OB_SALE_PERF_SUMMARY.all,
            ...CODE.REP_OB_PARTNER_INCENTIVE.all
        ]
    },
    /*==================== REPORT / OUTBOUND / PROMOTION AND AGREEMENT ====================*/
    MENU_LV3_REP_OB_PROMOTION_AGREEMENT: {
        all: [...CODE.REP_OB_PROMOTION.all, ...CODE.REP_OB_WAP_AGREEMENT.all, ...CODE.REP_OB_OUTLET_AGREEMENT.all]
    },
    /*==================== REPORT / OUTBOUND / AR-AP ====================*/
    MENU_LV3_REP_OB_AR_AP: {
        all: [...CODE.REP_OB_CUST_PAYMENT.all, ...CODE.REP_OB_DAILY_SALE_INV_PAY.all, ...CODE.REP_OB_DETAIL_INV_INFO.all]
    },
    /*==================== REPORT / OUTBOUND / TRADE MARKETING ====================*/
    MENU_LV3_REP_OB_TRADE_MARKET: {
        all: [
            ...CODE.REP_OB_ASSET_DETAIL_OUTLET.all,
            ...CODE.REP_OB_ASSET_DETAIL_DEALER.all,
            ...CODE.REP_OB_MARKET_PROMO_DETAIL.all,
            ...CODE.REP_OB_MARKET_PROMO_SUMMARY.all,
            ...CODE.REP_OB_OUTLET_PROMO_TRANSACTION.all,
            ...CODE.REP_OB_FRIDGE_DEPLOY_RETURN.all,
            ...CODE.REP_OB_SIGN_REQUEST_DEPLOY.all,
            ...CODE.REP_OB_TAS_MASTER.all,
            ...CODE.REP_OB_TAS_DEPLOY.all,
            ...CODE.REP_OB_TAS_RETURN.all,
            ...CODE.REP_OB_TAS_MOVEMENT.all
        ]
    },
    /*==================== REPORT / OUTBOUND / POSM ====================*/
    MENU_LV3_REP_OB_TOUCH_POINT: {
        all: [...CODE.REP_OB_POSM_REQUEST.all, ...CODE.REP_OB_POSM_DEPLOY.all, ...CODE.REP_OB_POSM_MOVEMENT.all]
    },
    /*==================== REPORT / OUTBOUND / INVENTORY ====================*/
    MENU_LV3_REP_OB_INVENTORY: {
        all: [
            ...CODE.REP_OB_INVENTORY_MOVE_SUMMARY.all,
            ...CODE.REP_OB_STOCK_MOVE_DETAIL.all,
            ...CODE.REP_OB_INV_MOVE_DETAIL.all,
            ...CODE.REP_OB_DAILY_INV_MOVE_DETAIL.all,
            ...CODE.REP_OB_INV_MOVE_ADJ.all,
            ...CODE.REP_OB_INV_MOVE_ADJ_DAILY.all,
            ...CODE.REP_OB_INV_SALE_MOVE.all,
            ...CODE.REP_OB_INV_SALE_MOVE_DAILY.all,
            ...CODE.REP_OB_INV_ON_HAND.all,
            ...CODE.REP_OB_SAFE_STOCK.all,
            ...CODE.REP_OB_STOCK_RECONCILE.all
        ]
    },
    /*==================== REPORT / OUTBOUND / RING PULL CLAIM ====================*/
    MENU_LV3_REP_OB_RING_PULL: {
        all: [
            ...CODE.REP_OB_RING_TRANSACTION.all,
            ...CODE.REP_OB_RING_CLAIM_CUST_DAILY.all,
            ...CODE.REP_OB_RING_CLAIM_CUST_MONTHLY.all,
            ...CODE.REP_OB_RING_CLAIM_DEALER.all,
            ...CODE.REP_OB_RING_REMAIN.all,
            ...CODE.REP_OB_RING_SO.all
        ]
    },
    /*==================== REPORT / OUTBOUND / GRATIS ====================*/
    MENU_LV3_REP_OB_GRATIS: {
        all: [
            ...CODE.REP_OB_GRATIS_PROMO_CATEGORY.all,
            ...CODE.REP_OB_GRATIS_REPORT_SUMMARY.all,
            ...CODE.REP_OB_GRATIS_ALL_DETAIL.all,
            ...CODE.REP_OB_GRATIS_REPORT_DETAIL.all,
            ...CODE.REP_OB_GRATIS_MANUAL.all,
            ...CODE.REP_OB_OUTLET_INCENTIVE_CLAIM.all
        ]
    },
    /*==================== REPORT / OUTBOUND / DOWNLINE ====================*/
    MENU_LV3_REP_OB_DOWNLINE: {
        all: [...CODE.REP_OB_DOWNLINE.all, ...CODE.REP_OB_CALL_PLAN.all, ...CODE.REP_OB_AUDIT_PLAN.all, ...CODE.REP_OB_ATTENDANT.all]
    },
    /*==================== REPORT / OUTBOUND / EMPTY KEG ====================*/
    MENU_LV3_REP_OB_EMPTY: {
        all: [...CODE.REP_OB_EMPTY.all, ...CODE.REP_OB_EMPTY_RETURN_OUTLET.all]
    },
    /*==================== REPORT / OUTBOUND / PALLET ====================*/
    MENU_LV3_REP_OB_PALLET: {
        all: [...CODE.REP_OB_PALLET.all]
    },
    /*==================== REPORT / OUTBOUND / BA/SA ====================*/
    MENU_LV3_REP_OB_BA: {
        all: [...CODE.REP_BA_MASTER.all, ...CODE.REP_BA_RQ_PEDING.all, ...CODE.REP_BA_RQ_DEPLOY.all, ...CODE.REP_BA_RETURN.all, ...CODE.REP_BA_MOVEMENT.all]
    }
};

const MENU_LV_2 = {
    /*==================== TRANSACTION ====================*/
    /*==================== TRANSACTION / INBOUND ORDER ====================*/
    MENU_LV2_INBOUND_ORDER: {
        all: [
            ...MENU_LV_3.MENU_LV3_PURCHASE_ORDER.all,
            ...MENU_LV_3.MENU_LV3_GRATIS_CLAIM.all,
            ...MENU_LV_3.MENU_LV3_RING_PULL_CLAIM.all,
            ...MENU_LV_3.MENU_LV3_INB_RETURN.all
        ]
    },
    /*==================== TRANSACTION / OUTBOUND ORDER ====================*/
    MENU_LV2_OUTBOUND_ORDER: {
        all: [
            ...MENU_LV_3.MENU_LV3_SALES_ORDER.all,
            ...MENU_LV_3.MENU_LV3_PRODUCT_RETURN.all,
            ...MENU_LV_3.MENU_LV3_PRODUCT_ROTATION.all,
            ...CODE.EMPTY_KEG_RETURN.all,
            ...MENU_LV_3.MENU_LV3_DIRECT_SALES.all,
            ...MENU_LV_3.MENU_LV3_OUTBOUND_SIGNAGE.all,
            ...MENU_LV_3.MENU_LV3_OUTBOUND_ASSET.all,
            ...MENU_LV_3.MENU_LV3_OUTBOUND_POSM.all,
            ...MENU_LV_3.MENU_LV3_OUTBOUND_BA.all
        ]
    },

    /*==================== INVENTORY ====================*/
    /*==================== INVENTORY / OVERALL INVENTORY ====================*/
    MENU_LV2_OVERALL_INVENTORY: {
        all: [...CODE.OVERALL_INVTORY_LIST.all, ...CODE.OVERALL_SAFE_STOCK.all]
    },
    /*==================== INVENTORY / INVENTORY TRANSACTION ====================*/
    MENU_LV2_INVENTORY_TRANSACTION: {
        all: [
            ...CODE.INVENTORY_GOODS_RECEIVE.all,
            ...CODE.INVENTORY_ADJUSTMENT.all,
            ...CODE.INVENTORY_COUNTING.all,
            ...CODE.INVENTORY_TRANSFER.all,
            ...CODE.INVENTORY_PERSONAL_USE.all,
            ...CODE.INVENTORY_RECONCILE.all
        ]
    },

    /*==================== MASTER DATA ====================*/
    /*==================== MASTER DATA / OUTLET ====================*/
    MENU_LV2_MASTER_OUTLET: {
        all: [...CODE.OUTLET_LIST.all, ...MENU_LV_3.MENU_LV3_OUTBOUND_NEW_OUTLET.all, ...CODE.OUTLET_GROUP.all, ...CODE.OUTLET_CONTRACT.all]
    },
    /*==================== MASTER DATA / EMPLOYEE ====================*/
    MENU_LV2_MASTER_EMPLOYEE: {
        all: [...CODE.EMPLOYEE_LIST.all, ...CODE.EMPLOYEE_DEVICE_CONTRACT.all]
    },
    /*==================== MASTER DATA / CALL PLAN ====================*/
    MENU_LV2_MASTER_CALL_PLAN: {
        all: [...CODE.CALL_PLAN_LIST.all, ...CODE.AUDIT_PLAN_LIST.all, ...MENU_LV_3.MENU_LV3_CALL_MANAGE_ROUTE.all]
    },
    /*==================== MASTER DATA / MATERIAL ====================*/
    MENU_LV2_MASTER_MATERIAL: {
        all: [...MENU_LV_3.MENU_LV3_MATERIAL_PRODUCT.all, ...MENU_LV_3.MENU_LV3_MATERIAL_COMPONENT.all, ...CODE.DEVICE_LIST.all, ...CODE.SAP_MATERIAL_LIST.all]
    },
    /*==================== MASTER DATA / KPI TARGET ====================*/
    MENU_LV2_MASTER_KPI: {
        all: [...MENU_LV_3.MENU_LV3_KPI_DEALER.all, ...MENU_LV_3.MENU_LV3_KPI_DOWNLINE.all]
    },
    /*==================== MASTER DATA / OUTLET PRICE GROUP ====================*/
    MENU_LV2_MASTER_OUTLET_PRICE_GROUP: {
        all: [...CODE.OUTLET_PRICE_GROUP.all, ...CODE.OUTLET_MANAGE_PRICE.all, ...CODE.OUTLET_MANAGE_PRICE_EFFECTIVE.all]
    },
    /*==================== MASTER DATA / MAP ====================*/
    MENU_LV2_MASTER_MAP: {
        all: [...CODE.MAP_LIVE.all]
    },

    /*==================== PROGRAM ====================*/
    /*==================== PROGRAM / INCENTIVE ====================*/
    MENU_LV2_PROGRAM_INCENTIVE: {
        all: [...CODE.INCENTIVE_TEMPLATE.all, ...CODE.OUTLET_AGREEMENT.all, ...CODE.INCENTIVE_REWARD.all]
    },

    /*==================== PROGRAM / SURVEY ====================*/
    MENU_LV2_PROGRAM_SURVEY: {
        all: [...CODE.SURVEY.all, ...CODE.SURVEY_HISTORY.all]
    },

    /*==================== PROGRAM / PARTNERSHIP ====================*/
    MENU_LV2_PROGRAM_PARTNERSHIP: {
        all: [...CODE.PARTNERSHIP_INCENTIVE_TEMPLATE.all, ...CODE.PARTNERSHIP_AGREEMENT.all, ...CODE.PARTNERSHIP_INCENTIVE_REWARD.all]
    },

    /*==================== SETTING ====================*/
    /*==================== SETTING / MASTER CONFIG ====================*/
    MENU_LV2_SETTING_MASTER: {
        all: [
            ...MENU_LV_3.MENU_LV3_CONFIG_OUTLET.all,
            ...MENU_LV_3.MENU_LV3_CONFIG_DEALER.all,
            ...MENU_LV_3.MENU_LV3_CONFIG_DONWLINE.all,
            ...MENU_LV_3.MENU_LV3_CONFIG_MATERIAL.all,
            ...MENU_LV_3.MENU_LV3_CONFIG_INVENTORY.all,
            ...MENU_LV_3.MENU_LV3_CONFIG_GENERAL.all
        ]
    },

    /*==================== REPORT ====================*/
    /*==================== REPORT / INBOUND ====================*/
    MENU_LV2_REPORT_INBOUND: {
        all: [
            ...MENU_LV_3.MENU_LV3_REP_IB_PURCHASE_ORDER.all,
            ...MENU_LV_3.MENU_LV3_REP_IB_RING_PULL.all,
            ...MENU_LV_3.MENU_LV3_REP_IB_GRATIS.all,
            ...MENU_LV_3.MENU_LV3_REP_IB_SALES_REPORT.all
        ]
    },
    /*==================== REPORT / OUTBOUND ====================*/
    MENU_LV2_REPORT_OUTBOUND: {
        all: [
            ...MENU_LV_3.MENU_LV3_REP_OB_SALES_REPORT.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_KPI_PERFORMANCE.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_PROMOTION_AGREEMENT.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_AR_AP.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_TRADE_MARKET.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_TOUCH_POINT.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_INVENTORY.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_RING_PULL.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_GRATIS.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_DOWNLINE.all,
            ...CODE.REP_OB_OUTLET.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_EMPTY.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_PALLET.all,
            ...MENU_LV_3.MENU_LV3_REP_OB_BA.all
        ]
    },
    /*==================== REPORT / OUTBOUND ====================*/
    MENU_LV2_REPORT_USER: {
        all: [...CODE.REP_US_LOGIN.all]
    }
};

const MENU_LV_1 = {
    /*==================== DASHBOARD ====================*/
    MENU_LV1_DAHSBOARD: {
        all: [...CODE.DASHBOARD_DEALER.all, ...CODE.DASHBOARD_SALES.all]
    },

    /*==================== SYSTEM USER ====================*/
    MENU_LV1_SYSTEM_USER: {
        all: [...CODE.USER.all, ...CODE.ROLE.all]
    },

    /*==================== TRANSACTION ====================*/
    MENU_LV1_TRANSACTION: {
        all: [...MENU_LV_2.MENU_LV2_INBOUND_ORDER.all, ...MENU_LV_2.MENU_LV2_OUTBOUND_ORDER.all]
    },

    /*==================== INVENTORY ====================*/
    MENU_LV1_INVENTORY: {
        all: [...CODE.INVENTORY_ON_HAND.all, ...MENU_LV_2.MENU_LV2_OVERALL_INVENTORY.all, ...MENU_LV_2.MENU_LV2_INVENTORY_TRANSACTION.all]
    },

    /*==================== MASTER DATA ====================*/
    MENU_LV1_MASTER_DATA: {
        all: [
            ...MENU_LV_2.MENU_LV2_MASTER_OUTLET.all,
            ...CODE.DEALER_LIST.all,
            ...CODE.DOWNLINE_LIST.all,
            ...MENU_LV_2.MENU_LV2_MASTER_EMPLOYEE.all,
            ...MENU_LV_2.MENU_LV2_MASTER_CALL_PLAN.all,
            ...MENU_LV_2.MENU_LV2_MASTER_MATERIAL.all,
            ...MENU_LV_2.MENU_LV2_MASTER_KPI.all,
            ...CODE.MOA_LIST.all,
            ...MENU_LV_2.MENU_LV2_MASTER_OUTLET_PRICE_GROUP.all,
            ...MENU_LV_2.MENU_LV2_MASTER_MAP.all,
            ...CODE.FRIDGE.all,
            ...CODE.BA_SA.all
        ]
    },

    /*==================== PROGRAM ====================*/
    MENU_LV1_PROGRAM: {
        all: [...CODE.PROMOTION.all, ...CODE.WEDDING.all, ...MENU_LV_2.MENU_LV2_PROGRAM_INCENTIVE.all, ...MENU_LV_2.MENU_LV2_PROGRAM_SURVEY.all]
    },

    /*==================== SETTING ====================*/
    MENU_LV1_SETTING: {
        all: [...MENU_LV_2.MENU_LV2_SETTING_MASTER.all, ...CODE.TRANSLATION.all, ...CODE.FILE_STATUS.all, ...CODE.DMS_SAP_LOG.all]
    },

    /*==================== REPORT ====================*/
    MENU_LV1_REPORT: {
        all: [...MENU_LV_2.MENU_LV2_REPORT_INBOUND.all, ...MENU_LV_2.MENU_LV2_REPORT_OUTBOUND.all, ...MENU_LV_2.MENU_LV2_REPORT_USER.all]
    }
};

const ALL_MENU = {
    APP_MENU: {
        all: [
            ...MENU_LV_1.MENU_LV1_DAHSBOARD.all,
            ...MENU_LV_1.MENU_LV1_SYSTEM_USER.all,
            ...MENU_LV_1.MENU_LV1_TRANSACTION.all,
            ...MENU_LV_1.MENU_LV1_INVENTORY.all,
            ...MENU_LV_1.MENU_LV1_MASTER_DATA.all,
            ...MENU_LV_1.MENU_LV1_PROGRAM.all,
            ...MENU_LV_1.MENU_LV1_SETTING.all,
            ...MENU_LV_1.MENU_LV1_REPORT.all
        ]
    }
};

export const MENU_PERMISSION_CODE = {
    ...CODE,
    /*========================================
    Parent Menu
    ========================================*/
    ...ALL_MENU,
    ...MENU_LV_1,
    ...MENU_LV_2,
    ...MENU_LV_3,
    ...MENU_LV_4
};
