export const INVENTORY_PERMISSION_CODE = {
    /*==================== INVENTORY ====================*/
    INVENTORY_ON_HAND: {
        code: 'WEB:INV:HAND',
        read: 'WEB:INV:HAND:R',
        create: 'WEB:INV:HAND:C',
        edit: 'WEB:INV:HAND:U',
        delete: 'WEB:INV:HAND:D',
        all: ['WEB:INV:HAND:R', 'WEB:INV:HAND:C', 'WEB:INV:HAND:U', 'WEB:INV:HAND:D'],
    },
    OVERALL_INVTORY_LIST: {
        code: 'WEB:INV:OVERALL',
        read: 'WEB:INV:OVERALL:R',
        create: 'WEB:INV:OVERALL:C',
        edit: 'WEB:INV:OVERALL:U',
        delete: 'WEB:INV:OVERALL:D',
        all: ['WEB:INV:OVERALL:R', 'WEB:INV:OVERALL:C', 'WEB:INV:OVERALL:U', 'WEB:INV:OVERALL:D'],
    },
    OVERALL_SAFE_STOCK: {
        code: 'WEB:INV:SAFE',
        read: 'WEB:INV:SAFE:R',
        create: 'WEB:INV:SAFE:C',
        edit: 'WEB:INV:SAFE:U',
        delete: 'WEB:INV:SAFE:D',
        all: ['WEB:INV:SAFE:R', 'WEB:INV:SAFE:C', 'WEB:INV:SAFE:U', 'WEB:INV:SAFE:D'],
    },
    INVENTORY_GOODS_RECEIVE: {
        code: 'WEB:INV:RECEIVE',
        read: 'WEB:INV:RECEIVE:R',
        create: 'WEB:INV:RECEIVE:C',
        edit: 'WEB:INV:RECEIVE:U',
        delete: 'WEB:INV:RECEIVE:D',
        all: ['WEB:INV:RECEIVE:R', 'WEB:INV:RECEIVE:C', 'WEB:INV:RECEIVE:U', 'WEB:INV:RECEIVE:D'],
    },
    INVENTORY_ADJUSTMENT: {
        code: 'WEB:INV:ADJUST',
        read: 'WEB:INV:ADJUST:R',
        create: 'WEB:INV:ADJUST:C',
        edit: 'WEB:INV:ADJUST:U',
        delete: 'WEB:INV:ADJUST:D',
        all: ['WEB:INV:ADJUST:R', 'WEB:INV:ADJUST:C', 'WEB:INV:ADJUST:U', 'WEB:INV:ADJUST:D'],
    },
    INVENTORY_COUNTING: {
        code: 'WEB:INV:COUNT',
        read: 'WEB:INV:COUNT:R',
        create: 'WEB:INV:COUNT:C',
        edit: 'WEB:INV:COUNT:U',
        delete: 'WEB:INV:COUNT:D',
        all: ['WEB:INV:COUNT:R', 'WEB:INV:COUNT:C', 'WEB:INV:COUNT:U', 'WEB:INV:COUNT:D'],
    },
    INVENTORY_TRANSFER: {
        code: 'WEB:INV:TFO',
        read: 'WEB:INV:TFO:R',
        create: 'WEB:INV:TFO:C',
        edit: 'WEB:INV:TFO:U',
        delete: 'WEB:INV:TFO:D',
        all: ['WEB:INV:TFO:R', 'WEB:INV:TFO:C', 'WEB:INV:TFO:U', 'WEB:INV:TFO:D'],
    },
    INVENTORY_PERSONAL_USE: {
        code: 'WEB:INV:USE',
        read: 'WEB:INV:USE:R',
        create: 'WEB:INV:USE:C',
        edit: 'WEB:INV:USE:U',
        delete: 'WEB:INV:USE:D',
        all: ['WEB:INV:USE:R', 'WEB:INV:USE:C', 'WEB:INV:USE:U', 'WEB:INV:USE:D'],
    },
    INVENTORY_RECONCILE: {
        code: 'WEB:INV:RECONCILE',
        read: 'WEB:INV:RECONCILE:R',
        create: 'WEB:INV:RECONCILE:C',
        edit: 'WEB:INV:RECONCILE:U',
        delete: 'WEB:INV:RECONCILE:D',
        all: ['WEB:INV:RECONCILE:R', 'WEB:INV:RECONCILE:C', 'WEB:INV:RECONCILE:U', 'WEB:INV:RECONCILE:D'],
    },
}