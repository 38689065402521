import { PermissionModule } from "src/app/shared/models/role-permission";
import { environment } from "src/environments/environment";
import { MENU_PERMISSION_CODE as CODE } from "../../navigation/menu-permission-code";

const env = environment.env;

export const TRANSACTION_PERMISSION_MODULE: PermissionModule[] = [
    /*==================================================
    INBOUND TRANSACTION TITLE
    ==================================================*/
    {
        id: 'INBOUND-TRANSACTION-TITLE',
        name: 'Transaction > Inbound Order',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    PURCHASE ORDER
    ==================================================*/
    {
        id: 'PURCHASE-ORDER',
        name: 'Purchase Order',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** PURCHASE ORDER > ORDER *****/
            {
                id: 'PURCHASE-ORDER-PURCHASE-ORDER',
                name: 'Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-ORDER-PRODUCT-READ',
                        name: 'View',
                        code: CODE.PURCHASE_ORDER.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PURCHASE-ORDER-PRODUCT-CREATE',
                        name: 'Create',
                        code: CODE.PURCHASE_ORDER.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** PURCHASE ORDER > SAP SALES ORDER *****/
            {
                id: 'PURCHASE-ORDER-SALE-ORDER',
                name: 'SAP Sale Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-ORDER-PRODUCT-SALE-READ',
                        name: 'View',
                        code: CODE.PURCHASE_ORDER_SALE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** PURCHASE ORDER > GOODS RECEIVE ******/
            {
                id: 'PURCHASE-ORDER-GOODS-RECEIVE',
                name: 'Goods Receive',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-DELIVERY-PURCHASE-ORDER-READ',
                        name: 'View',
                        code: CODE.PURCHASE_ORDER_GOODS_RECEIVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-DELIVERY-PURCHASE-ORDER-UPDATE',
                        name: 'Edit',
                        code: CODE.PURCHASE_ORDER_GOODS_RECEIVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** PURCHASE ORDER > INVOICE ******/
            {
                id: 'PURCHASE-ORDER-INVOICE',
                name: 'Invoice',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-INVOICE-PURCHASE-ORDER-READ',
                        name: 'View',
                        code: CODE.PURCHASE_ORDER_INVOICE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** PURCHASE ORDER > PAYMENT ******/
            {
                id: 'PURCHASE-ORDER-PAYMENT',
                name: 'Payment',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PAYMENT-PURCHASE-ORDER-READ',
                        name: 'View',
                        code: CODE.PURCHASE_ORDER_PAYMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PAYMENT-PURCHASE-ORDER-CREATE',
                        name: 'Create',
                        code: CODE.PURCHASE_ORDER_PAYMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    GRATIS CLAIM
    ==================================================*/
    {
        id: 'GRATIS-CLAIM',
        name: 'Gratis Claim',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** GRATIS CLAIM > APPROVAL CLAIM *****/
            {
                id: 'GRATIS-CLAIM-APPROVAL-CLAIM',
                name: 'Approval Claim',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-APPROVAL-CLAIM-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM_APPROVAL.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-APPROVAL-CLAIM-UPDATE',
                        name: 'Edit',
                        code: CODE.GRATIS_CLAIM_APPROVAL.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** GRATIS CLAIM > APPROVAL CLAIM HISTORY *****/
            {
                id: 'GRATIS-CLAIM-APPROVAL-CLAIM-HISTORY',
                name: 'Approval Claim History',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-APPROVAL-CLAIM-HIS-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM_HISTORY.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                ]
            },
            /***** GRATIS CLAIM > CLAIM *****/
            {
                id: 'GRATIS-CLAIM-GRATIS-CLAIM',
                name: 'Claim',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-CLAIM-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-CLAIM-CREATE',
                        name: 'Create',
                        code: CODE.GRATIS_CLAIM.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** GRATIS CLAIM > SAP SALES ORDER *****/
            {
                id: 'GRATIS-CLAIM-SALE-CLAIM',
                name: 'SAP Sale Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-GRATIS-CLAIM-SALE-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM_SALE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** GRATIS CLAIM > GOODS RECEIVE ******/
            {
                id: 'GRATIS-CLAIM-GOODS-RECEIVE',
                name: 'Goods Receive',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-DELIVERY-GRATIS-CLAIM-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM_GOODS_RECEIVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-DELIVERY-GRATIS-CLAIM-UPDATE',
                        name: 'Edit',
                        code: CODE.GRATIS_CLAIM_GOODS_RECEIVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** GRATIS CLAIM > INVOICE ******/
            {
                id: 'GRATIS-CLAIM-INVOICE',
                name: 'Invoice',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-INVOICE-GRATIS-CLAIM-READ',
                        name: 'View',
                        code: CODE.GRATIS_CLAIM_INVOICE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
            /***** GRATIS CLAIM > PAYMENT ******/
            // {
            //     id: 'GRATIS-CLAIM-PAYMENT',
            //     name: 'Payment',
            //     code: '',
            //     classes: '',
            //     isCheck: false,
            //     visible: true,
            //     subModules: [
            //         {
            //             id: 'WEB-ORDER-PAYMENT-GRATIS-CLAIM-CREATE',
            //             name: 'Create',
            //             code: CODE.GRATIS_CLAIM_PAYMENT.create,
            //             classes: 'auto-chb-view',
            //             isCheck: false,
            //             visible: true,
            //         },
            //     ],
            // },
        ]
    },

    /*==================================================
    RING PULL CLAIM
    ==================================================*/
    {
        id: 'RING-PULL-CLAIM',
        name: 'Ring Pull Claim',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** RING PULL CLAIM > CLAIM *****/
            {
                id: 'RING-PULL-CLAIM-RING-PULL-CLAIM',
                name: 'Claim',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-RING-PULL-CLAIM-READ',
                        name: 'View',
                        code: CODE.RING_PULL_CLAIM.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PURCHASE-RING-PULL-CLAIM-CREATE',
                        name: 'Create',
                        code: CODE.RING_PULL_CLAIM.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** RING PULL CLAIM > SAP SALES ORDER *****/
            {
                id: 'RING-PULL-CLAIM-RING-SALE-CLAIM',
                name: 'SAP Sale Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PURCHASE-RING-PULL-CLAIM-SALE-READ',
                        name: 'View',
                        code: CODE.RING_PULL_CLAIM_SALE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** RING PULL CLAIM > GOODS RECEIVE ******/
            {
                id: 'RING-PULL-CLAIM-GOODS-RECEIVE',
                name: 'Goods Receive',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-DELIVERY-RING-PULL-CLAIM-READ',
                        name: 'View',
                        code: CODE.RING_PULL_CLAIM_GOODS_RECEIVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-DELIVERY-RING-PULL-CLAIM-UPDATE',
                        name: 'Edit',
                        code: CODE.RING_PULL_CLAIM_GOODS_RECEIVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** RING PULL CLAIM > INVOICE ******/
            {
                id: 'RING-PULL-CLAIM-INVOICE',
                name: 'Invoice',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-INVOICE-RING-PULL-CLAIM-READ',
                        name: 'View',
                        code: CODE.RING_PULL_CLAIM_INVOICE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
            /***** RING PULL CLAIM > PAYMENT ******/
            // {
            //     id: 'RING-PULL-CLAIM-PAYMENT',
            //     name: 'Payment',
            //     code: '',
            //     classes: '',
            //     isCheck: false,
            //     visible: true,
            //     subModules: [
            //         {
            //             id: 'WEB-ORDER-PAYMENT-RING-PULL-CLAIM-CREATE',
            //             name: 'Create',
            //             code: CODE.RING_PULL_CLAIM_PAYMENT.create,
            //             classes: 'auto-chb-view',
            //             isCheck: false,
            //             visible: true,
            //         },
            //     ],
            // },
        ]
    },

    /*==================================================
    INBOUND RETURN
    ==================================================*/
    {
        id: 'SAP-RETURN',
        name: 'Return',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'SAP-RETURN-MARKET',
                name: 'Market Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'INB-RT-MARKET-RETURN',
                        name: 'Market Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-RETURN-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-MARKET-RETURN-CREATE',
                                name: 'Create',
                                code: CODE.INB_RT_MARKET_RETURN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-MARKET-QTY',
                        name: 'QTY to SAP',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-QTY-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_QTY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-MARKET-QTY-EDIT',
                                name: 'Edit',
                                code: CODE.INB_RT_MARKET_QTY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-MARKET-SALES',
                        name: 'SAP Sales Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-SALES-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_SALES.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-MARKET-DMS-DO',
                        name: 'Good Issued',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-DMS-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_DMS_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-MARKET-SAP-DO',
                        name: 'SAP Delivery Order',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-SAP-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_SAP_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-MARKET-INV',
                        name: 'Invoice',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-MARKET-INV-READ',
                                name: 'View',
                                code: CODE.INB_RT_MARKET_INV.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                ]
            },
            {
                id: 'SAP-RETURN-PURCHASE',
                name: 'Purchase Return (Accident/Quality Complaint)',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'INB-RT-PURCHASE-RETURN',
                        name: 'Purchase Return (Accident/Quality Complaint)',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-PURCHASE-RETURN-READ',
                                name: 'View',
                                code: CODE.INB_RT_PURCHASE_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-PURCHASE-RETURN-CREATE',
                                name: 'Create',
                                code: CODE.INB_RT_PURCHASE_RETURN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-PURCHASE-SALES',
                        name: 'SAP Sales Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-PURCHASE-SALES-READ',
                                name: 'View',
                                code: CODE.INB_RT_PURCHASE_SALES.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-PURCHASE-DMS-DO',
                        name: 'Good Issued',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-PURCHASE-DMS-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_PURCHASE_DMS_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-PURCHASE-DMS-DO-EDIT',
                                name: 'Edit',
                                code: CODE.INB_RT_PURCHASE_DMS_DO.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-PURCHASE-INV',
                        name: 'Invoice',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-PURCHASE-INV-READ',
                                name: 'View',
                                code: CODE.INB_RT_PURCHASE_INV.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                ]
            },
            {
                id: 'SAP-RETURN-GRATIS',
                name: 'Gratis Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'INB-RT-GRATIS-RETURN',
                        name: 'Gratis Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-GRATIS-RETURN-READ',
                                name: 'View',
                                code: CODE.INB_RT_GRATIS_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-GRATIS-RETURN-CREATE',
                                name: 'Create',
                                code: CODE.INB_RT_GRATIS_RETURN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-GRATIS-SALES',
                        name: 'SAP Sales Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-GRATIS-SALES-READ',
                                name: 'View',
                                code: CODE.INB_RT_GRATIS_SALES.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-GRATIS-DMS-DO',
                        name: 'Good Issued',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-GRATIS-DMS-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_GRATIS_DMS_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-GRATIS-DMS-DO-EDIT',
                                name: 'Edit',
                                code: CODE.INB_RT_GRATIS_DMS_DO.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-GRATIS-INV',
                        name: 'Invoice',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-GRATIS-INV-READ',
                                name: 'View',
                                code: CODE.INB_RT_GRATIS_INV.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                ]
            },
            {
                id: 'SAP-RETURN-RING',
                name: 'Ring Pull Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'INB-RT-RING-RETURN',
                        name: 'Ring Pull Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-RING-RETURN-READ',
                                name: 'View',
                                code: CODE.INB_RT_RING_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-RING-RETURN-CREATE',
                                name: 'Create',
                                code: CODE.INB_RT_RING_RETURN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-RING-SALES',
                        name: 'SAP Sales Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-RING-SALES-READ',
                                name: 'View',
                                code: CODE.INB_RT_RING_SALES.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-RING-DMS-DO',
                        name: 'Good Issued',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-RING-DMS-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_RING_DMS_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-RING-DMS-DO-EDIT',
                                name: 'Edit',
                                code: CODE.INB_RT_RING_DMS_DO.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-RING-INV',
                        name: 'Invoice',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-RING-INV-READ',
                                name: 'View',
                                code: CODE.INB_RT_RING_INV.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                ]
            },
            {
                id: 'SAP-RETURN-EMPTY',
                name: 'Empty Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'INB-RT-EMPTY-RETURN',
                        name: 'Empty Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-EMPTY-RETURN-READ',
                                name: 'View',
                                code: CODE.INB_RT_EMPTY_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-EMPTY-RETURN-CREATE',
                                name: 'Create',
                                code: CODE.INB_RT_EMPTY_RETURN.create,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-EMPTY-SALES',
                        name: 'SAP Sales Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-EMPTY-SALES-READ',
                                name: 'View',
                                code: CODE.INB_RT_EMPTY_SALES.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-EMPTY-DMS-DO',
                        name: 'Good Issued',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-EMPTY-DMS-DO-READ',
                                name: 'View',
                                code: CODE.INB_RT_EMPTY_DMS_DO.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'INB-RT-EMPTY-DMS-DO-EDIT',
                                name: 'Edit',
                                code: CODE.INB_RT_EMPTY_DMS_DO.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'INB-RT-EMPTY-INV',
                        name: 'Invoice',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'INB-RT-EMPTY-INV-READ',
                                name: 'View',
                                code: CODE.INB_RT_EMPTY_INV.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                ]
            },
        ]
    },

    /*==================================================
    OUTBOUND TRANSACTION TITLE
    ==================================================*/
    {
        id: 'OUTBOUND-TRANSACTION-TITLE',
        name: 'Transaction > Outbound Order',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        isTitle: true,
        subModules: []
    },

    /*==================================================
    SALE ORDER
    ==================================================*/
    {
        id: 'SALE-ORDER',
        name: 'Sale Order',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            /***** SALE ORDER > ORDER *****/
            {
                id: 'SALE-ORDER-SALE-ORDER',
                name: 'Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-SALE-READ',
                        name: 'View',
                        code: CODE.SALE_ORDER.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-SALE-CREATE',
                        name: 'Create',
                        code: CODE.SALE_ORDER.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-SALE-UPDATE',
                        name: 'Edit',
                        code: CODE.SALE_ORDER.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > RING PULL REDEMPTION *****/
            {
                id: 'SALE-ORDER-RING-PULL-REDEMTION',
                name: 'Ring Pull Redemption',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-SO-RING-PULL-REDEMPTION-READ',
                        name: 'View',
                        code: CODE.SO_RING_PULL_REDEMPTION.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-SO-RING-PULL-REDEMPTION-CREATE',
                        name: 'Create',
                        code: CODE.SO_RING_PULL_REDEMPTION.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-SO-RING-PULL-REDEMPTION-UPDATE',
                        name: 'Edit',
                        code: CODE.SO_RING_PULL_REDEMPTION.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > PRODUCT REIMBURSEMENT *****/
            {
                id: 'SALE-ORDER-PRODUCT-REIMBURSEMENT',
                name: 'Product Reimbursement',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PRODUCT-REIMBURSEMENT-READ',
                        name: 'View',
                        code: CODE.PRODUCT_REIMBURSEMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PRODUCT-REIMBURSEMENT-CREATE',
                        name: 'Create',
                        code: CODE.PRODUCT_REIMBURSEMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PRODUCT-REIMBURSEMENT-UPDATE',
                        name: 'Edit',
                        code: CODE.PRODUCT_REIMBURSEMENT.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > OUTBOUND CLAIM *****/
            {
                id: 'SALE-ORDER-OUTBOUND-CLAIM',
                name: 'Outlet Incentive Claim',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-OUTBOUND-CLAIM-READ',
                        name: 'View',
                        code: CODE.OUTBOUND_CLAIM.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-OUTBOUND-CLAIM-CREATE',
                        name: 'Create',
                        code: CODE.OUTBOUND_CLAIM.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-OUTBOUND-CLAIM-UPDATE',
                        name: 'Edit',
                        code: CODE.OUTBOUND_CLAIM.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > MANUAL CLAIM *****/
            {
                id: 'SALE-ORDER-MANUAL-CLAIM',
                name: 'Manual Claim',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-MANUAL-CLAIM-READ',
                        name: 'View',
                        code: CODE.MANUAL_CLAIM.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-MANUAL-CLAIM-CREATE',
                        name: 'Create',
                        code: CODE.MANUAL_CLAIM.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-MANUAL-CLAIM-UPDATE',
                        name: 'Edit',
                        code: CODE.MANUAL_CLAIM.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > DELIVERY ORDER ******/
            {
                id: 'SALE-ORDER-DELIVERY-ORDER',
                name: 'Delivery Order',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-DELIVERY-SALE-ORDER-READ',
                        name: 'View',
                        code: CODE.SALE_ORDER_DELIVERY_ORDER.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-DELIVERY-SALE-ORDER-CREATE',
                        name: 'Create',
                        code: CODE.SALE_ORDER_DELIVERY_ORDER.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-DELIVERY-SALE-ORDER-UPDATE',
                        name: 'Edit',
                        code: CODE.SALE_ORDER_DELIVERY_ORDER.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > DELIVERY ROUTE ******/
            {
                id: 'WEB-FIRM-DELIVERY-ROUTE-SALE-ORDER',
                name: 'Delivery Route',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-SALE-ORDER-READ',
                        name: 'View',
                        code: CODE.SALE_ORDER_DELIVERY_ROUTE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-SALE-ORDER-CREATE',
                        name: 'Create',
                        code: CODE.SALE_ORDER_DELIVERY_ROUTE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-SALE-ORDER-UPDATE',
                        name: 'Edit',
                        code: CODE.SALE_ORDER_DELIVERY_ROUTE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                    // {
                    //     id: 'WEB-FIRM-DELIVERY-ROUTE-SALE-ORDER-DELETE',
                    //     name: 'Active/Inactive',
                    //     code: CODE.SALE_ORDER_DELIVERY_ROUTE.delete,
                    //     classes: 'auto-chb-view',
                    //     isCheck: false,
                    //     visible: true,
                    // },
                ]
            },
            /***** SALE ORDER > INVOICE ******/
            {
                id: 'WEB-ORDER-INVOICE-SALE-ORDER',
                name: 'Invoice',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-INVOICE-SALE-ORDER-READ',
                        name: 'View',
                        code: CODE.SALE_ORDER_INVOICE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-INVOICE-SALE-ORDER-CREATE',
                        name: 'Create',
                        code: CODE.SALE_ORDER_INVOICE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            /***** SALE ORDER > PAYMENT ******/
            {
                id: 'WEB-ORDER-PAYMENT-SALE-ORDER',
                name: 'Payment',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-PAYMENT-SALE-ORDER-READ',
                        name: 'View',
                        code: CODE.SALE_ORDER_PAYMENT.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-PAYMENT-SALE-ORDER-CREATE',
                        name: 'Create',
                        code: CODE.SALE_ORDER_PAYMENT.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    SALE ORDER RETURN
    ==================================================*/
    // {
    //     id: 'SALE-ORDER-RETURN-PRODUCT-KEG',
    //     name: 'Sale Order Return',
    //     code: '',
    //     classes: '',
    //     isCheck: false,
    //     visible: true,
    //     subModules: [
    //         {
    //             id: 'WEB-ORDER-RETURN-PRODUCT',
    //             name: 'Product Return',
    //             code: '',
    //             classes: '',
    //             isCheck: false,
    //             visible: true,
    //             subModules: [
    //                 {
    //                     id: 'WEB-ORDER-RETURN-PRODUCT-READ',
    //                     name: 'View',
    //                     code: CODE.PRODUCT_RETURN.read,
    //                     classes: 'chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 },
    //                 {
    //                     id: 'WEB-ORDER-RETURN-PRODUCT-UPDATE',
    //                     name: 'Edit',
    //                     code: CODE.PRODUCT_RETURN.edit,
    //                     classes: 'auto-chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'WEB-ORDER-RETURN-EMPTY-KEG',
    //             name: 'Empty Keg/Bottle Return',
    //             code: '',
    //             classes: '',
    //             isCheck: false,
    //             visible: true,
    //             subModules: [
    //                 {
    //                     id: 'WEB-ORDER-RETURN-EMPTY-KEG-READ',
    //                     name: 'View',
    //                     code: CODE.EMPTY_KEG_RETURN.read,
    //                     classes: 'chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 },
    //                 {
    //                     id: 'WEB-ORDER-RETURN-EMPTY-KEG-UPDATE',
    //                     name: 'Edit',
    //                     code: CODE.EMPTY_KEG_RETURN.edit,
    //                     classes: 'auto-chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 }
    //             ]
    //         }
    //     ]
    // },

    /*==================================================
    PRODUCT RETURN
    ==================================================*/
    {
        id: 'OUTBOUND-PRODUCT-RETURN',
        name: 'Product Return',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-PRODUCT-RETURN-TRANSACTION',
                name: 'Transaction',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PRODUCT-RETURN-TRANSACTION-READ',
                        name: 'View',
                        code: CODE.PRODUCT_RETURN_TRANSACTION.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-RETURN-TRANSACTION-UPDATE',
                        name: 'Edit',
                        code: CODE.PRODUCT_RETURN_TRANSACTION.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-PRODUCT-RETURN-APPROVE',
                name: 'Product Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PRODUCT-RETURN-APPROVE-READ',
                        name: 'View',
                        code: CODE.PRODUCT_RETURN_APPROVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-RETURN-APPROVE-CREATE',
                        name: 'Create',
                        code: CODE.PRODUCT_RETURN_APPROVE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-RETURN-APPROVE-UPDATE',
                        name: 'Edit',
                        code: CODE.PRODUCT_RETURN_APPROVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    PRODUCT ROTATION
    ==================================================*/
    {
        id: 'OUTBOUND-PRODUCT-ROTATION',
        name: 'Product Rotation',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-PRODUCT-ROTATION-TRANSACTION',
                name: 'Transaction',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PRODUCT-ROTATION-TRANSACTION-READ',
                        name: 'View',
                        code: CODE.PRODUCT_ROTATION_TRANSACTION.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-ROTATION-TRANSACTION-UPDATE',
                        name: 'Edit',
                        code: CODE.PRODUCT_ROTATION_TRANSACTION.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-PRODUCT-ROTATION-APPROVE',
                name: 'Product Rotation',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-PRODUCT-ROTATION-APPROVE-READ',
                        name: 'View',
                        code: CODE.PRODUCT_ROTATION_APPROVE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-ROTATION-APPROVE-CREATE',
                        name: 'Create',
                        code: CODE.PRODUCT_ROTATION_APPROVE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-PRODUCT-ROTATION-APPROVE-UPDATE',
                        name: 'Edit',
                        code: CODE.PRODUCT_ROTATION_APPROVE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    EMPTY KEG/BOTTLE RETURN
    ==================================================*/
    {
        id: 'WEB-ORDER-RETURN-EMPTY-KEG',
        name: 'Empty Keg/Bottle Return',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-ORDER-RETURN-EMPTY-KEG-READ',
                name: 'View',
                code: CODE.EMPTY_KEG_RETURN.read,
                classes: 'chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-ORDER-RETURN-EMPTY-KEG-CREATE',
                name: 'Create',
                code: CODE.EMPTY_KEG_RETURN.create,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            },
            {
                id: 'WEB-ORDER-RETURN-EMPTY-KEG-UPDATE',
                name: 'Edit',
                code: CODE.EMPTY_KEG_RETURN.edit,
                classes: 'auto-chb-view',
                isCheck: false,
                visible: true,
            }
        ]
    },

    /*==================================================
    DIRECT SALES
    ==================================================*/
    {
        id: 'DIRECT-SALE',
        name: 'Direct Sale',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-INVENTORY-DIRECT-SALE-REQUEST',
                name: 'Stock Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-REQUEST-READ',
                        name: 'View',
                        code: CODE.DIRECT_SALES_STOCK_REQUEST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-REQUEST-UPDATE',
                        name: 'Edit',
                        code: CODE.DIRECT_SALES_STOCK_REQUEST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-INVENTORY-DIRECT-SALE-REPLENISH',
                name: 'Stock Replenish',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-REPLENISH-READ',
                        name: 'View',
                        code: CODE.DIRECT_SALES_STOCK_REPLENISH.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-REPLENISH-UPDATE',
                        name: 'Edit',
                        code: CODE.DIRECT_SALES_STOCK_REPLENISH.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-INVENTORY-DIRECT-SALE-TRANSFER',
                name: 'Stock Van Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-TRANSFER-READ',
                        name: 'View',
                        code: CODE.DIRECT_SALES_STOCK_TRANSFER.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-DIRECT-SALE-TRANSFER-UPDATE',
                        name: 'Edit',
                        code: CODE.DIRECT_SALES_STOCK_TRANSFER.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-FIRM-DELIVERY-ROUTE-DIRECT-SALE',
                name: 'Van Route',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-DIRECT-SALE-READ',
                        name: 'View',
                        code: CODE.DIRECT_SALES_VAN_ROUTE.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-DIRECT-SALE-CREATE',
                        name: 'Create',
                        code: CODE.DIRECT_SALES_VAN_ROUTE.create,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-DIRECT-SALE-UPDATE',
                        name: 'Edit',
                        code: CODE.DIRECT_SALES_VAN_ROUTE.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-FIRM-DELIVERY-ROUTE-DIRECT-SALE-DELETE',
                        name: 'Active/Inactive',
                        code: CODE.DIRECT_SALES_VAN_ROUTE.delete,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    SIGNAGE TRANSACTION
    ==================================================*/
    {
        id: 'SIGNAGE-TRANSACTION',
        name: 'Signage',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'SIGNAGE-REQUEST',
                name: 'Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-APPROVAL-REQUEST-SIGNAGE',
                        name: 'Approval Transaction',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-APPROVAL-REQUEST-SIGNAGE-READ',
                                name: 'View',
                                code: CODE.SIGNAGE_REQUEST_TRANSACTION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-APPROVAL-REQUEST-SIGNAGE-UPDATE',
                                name: 'Edit',
                                code: CODE.SIGNAGE_REQUEST_TRANSACTION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'WEB-ORDER-REQUEST-SIGNAGE',
                        name: 'Stock Request & Deploy',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-ORDER-REQUEST-SIGNAGE-READ',
                                name: 'View',
                                code: CODE.SIGNAGE_REQUEST_DEPLOY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-ORDER-REQUEST-SIGNAGE-UPDATE',
                                name: 'Edit',
                                code: CODE.SIGNAGE_REQUEST_DEPLOY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            {
                id: 'SIGNAGE-RETURN',
                name: 'Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-RETURN-SIGNAGE-READ',
                        name: 'View',
                        code: CODE.SIGNAGE_RETURN.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-RETURN-SIGNAGE-UPDATE',
                        name: 'Edit',
                        code: CODE.SIGNAGE_RETURN.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    TRADE ASSET TRANSACTION
    ==================================================*/
    {
        id: 'TRADE-ASSET-TRANSACTION',
        name: 'Trade Asset',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'TRADE-ASSET-REQUEST',
                name: 'Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-APPROVAL-REQUEST-ASSSET',
                        name: 'Approval Transaction',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-APPROVAL-REQUEST-ASSSET-READ',
                                name: 'View',
                                code: CODE.ASSET_REQUEST_TRANSACTION.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-APPROVAL-REQUEST-ASSSET-UPDATE',
                                name: 'Edit',
                                code: CODE.ASSET_REQUEST_TRANSACTION.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'WEB-ORDER-REQUEST-ASSET',
                        name: 'Stock Request & Deploy',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-ORDER-REQUEST-ASSET-READ',
                                name: 'View',
                                code: CODE.ASSET_REQUEST_DEPLOY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-ORDER-REQUEST-ASSET-UPDATE',
                                name: 'Edit',
                                code: CODE.ASSET_REQUEST_DEPLOY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            {
                id: 'TRADE-ASSET-RETURN',
                name: 'Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-RETURN-ASSET-READ',
                        name: 'View',
                        code: CODE.ASSET_RETURN.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-RETURN-ASSET-UPDATE',
                        name: 'Edit',
                        code: CODE.ASSET_RETURN.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    POSM TRANSACTION
    ==================================================*/
    {
        id: 'POSM-TRANSACTION',
        name: 'POSM',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'WEB-APPROVAL-REQUEST-POSM',
                name: 'Approval Transaction',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-APPROVAL-REQUEST-POSM-READ',
                        name: 'View',
                        code: CODE.POSM_TRANSACTION.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-APPROVAL-REQUEST-POSM-UPDATE',
                        name: 'Edit',
                        code: CODE.POSM_TRANSACTION.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-INVENTORY-POSM-REQUEST',
                name: 'Stock Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-POSM-REQUEST-READ',
                        name: 'View',
                        code: CODE.POSM_REQUEST.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-POSM-REQUEST-UPDATE',
                        name: 'Edit',
                        code: CODE.POSM_REQUEST.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-INVENTORY-POSM-RETURN',
                name: 'Stock Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-INVENTORY-POSM-RETURN-READ',
                        name: 'View',
                        code: CODE.POSM_RETURN.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-INVENTORY-POSM-RETURN-UPDATE',
                        name: 'Edit',
                        code: CODE.POSM_RETURN.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            },
            {
                id: 'WEB-ORDER-REQUEST-POSM',
                name: 'Deploy',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-ORDER-REQUEST-POSM-READ',
                        name: 'View',
                        code: CODE.POSM_DEPLOY.read,
                        classes: 'chb-view',
                        isCheck: false,
                        visible: true,
                    },
                    {
                        id: 'WEB-ORDER-REQUEST-POSM-UPDATE',
                        name: 'Edit',
                        code: CODE.POSM_DEPLOY.edit,
                        classes: 'auto-chb-view',
                        isCheck: false,
                        visible: true,
                    }
                ]
            }
        ]
    },

    /*==================================================
    RING PULL TRANSACTION
    ==================================================*/
    // {
    //     id: 'RING-PULL-TRANSACTION',
    //     name: 'Ring Pull',
    //     code: '',
    //     classes: '',
    //     isCheck: false,
    //     visible: true,
    //     subModules: [
    //         {
    //             id: 'WEB-REDEEM-REQUEST-RING-PULL-SUBMISSIOIN',
    //             name: 'Ring Pull Submission',
    //             code: '',
    //             classes: '',
    //             isCheck: false,
    //             visible: true,
    //             subModules: [
    //                 {
    //                     id: 'WEB-REDEEM-REQUEST-RING-PULL-SUBMISSIOIN-READ',
    //                     name: 'View',
    //                     code: CODE.RING_PULL_SUBMISSION.read,
    //                     classes: 'chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 },
    //                 {
    //                     id: 'WEB-REDEEM-REQUEST-RING-PULL-SUBMISSIOIN-UPDATE',
    //                     name: 'Edit',
    //                     code: CODE.RING_PULL_SUBMISSION.edit,
    //                     classes: 'auto-chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'WEB-REDEEM-REQUEST-RING-PULL-REDUMPTION',
    //             name: 'Ring Pull Redemption',
    //             code: '',
    //             classes: '',
    //             isCheck: false,
    //             visible: true,
    //             subModules: [
    //                 {
    //                     id: 'WEB-REDEEM-REQUEST-RING-PULL-REDUMPTION-READ',
    //                     name: 'View',
    //                     code: CODE.RING_PULL_REDUMPTION.read,
    //                     classes: 'chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 },
    //                 {
    //                     id: 'WEB-REDEEM-REQUEST-RING-PULL-REDUMPTION-UPDATE',
    //                     name: 'Edit',
    //                     code: CODE.RING_PULL_REDUMPTION.edit,
    //                     classes: 'auto-chb-view',
    //                     isCheck: false,
    //                     visible: true,
    //                 }
    //             ]
    //         }
    //     ]
    // },

    /*==================================================
    BA/SA TRANSACTION
    ==================================================*/
    {
        id: 'BA-SA-TRANSACTION',
        name: 'BA/SA',
        code: '',
        classes: '',
        isCheck: false,
        visible: true,
        subModules: [
            {
                id: 'BA-SA-REQUEST',
                name: 'BA/SA Request',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-BA-REQUEST-PENDING',
                        name: 'Approval Transaction',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-BA-REQUEST-PENDING-READ',
                                name: 'View',
                                code: CODE.BA_REQUEST_PENDING.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-BA-REQUEST-PENDING-UPDATE',
                                name: 'Edit',
                                code: CODE.BA_REQUEST_PENDING.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'WEB-BA-DEPLOY',
                        name: 'Stock Request & Deploy',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-BA-DEPLOY-READ',
                                name: 'View',
                                code: CODE.BA_DEPLOY.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-BA-DEPLOY-UPDATE',
                                name: 'Edit',
                                code: CODE.BA_DEPLOY.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
            {
                id: 'BA-SA-RETURN',
                name: 'BA/SA Return',
                code: '',
                classes: '',
                isCheck: false,
                visible: true,
                subModules: [
                    {
                        id: 'WEB-BA-RETURN-PENDING',
                        name: 'Approval Transaction',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-BA-RETURN-PENDING-READ',
                                name: 'View',
                                code: CODE.BA_RETURN_PENDING.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-BA-RETURN-PENDING-UPDATE',
                                name: 'Edit',
                                code: CODE.BA_RETURN_PENDING.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    },
                    {
                        id: 'WEB-BA-RETURN',
                        name: 'Return',
                        code: '',
                        classes: '',
                        isCheck: false,
                        visible: true,
                        subModules: [
                            {
                                id: 'WEB-BA-RETURN-READ',
                                name: 'View',
                                code: CODE.BA_RETURN.read,
                                classes: 'chb-view',
                                isCheck: false,
                                visible: true,
                            },
                            {
                                id: 'WEB-BA-RETURN-UPDATE',
                                name: 'Edit',
                                code: CODE.BA_RETURN.edit,
                                classes: 'auto-chb-view',
                                isCheck: false,
                                visible: true,
                            }
                        ]
                    }
                ]
            },
        ]
    },
]