import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../services/login.service';
import { AlertMessageService } from './alert-message.service';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging, private loginService: LoginService, private _alert: AlertMessageService) {
        this.angularFireMessaging.messaging.subscribe((msgings) => {
            msgings.onMessage = msgings.onMessage.bind(msgings);
            msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
        });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
            this.loginService.subscribeNotification(token).subscribe((res: any) => {});
        });
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((msg: any) => {
            const payload = {
                title: msg.notification.title,
                body: msg.notification.body || msg.notification.title,
                image: msg.data ? msg.data.image : null,
                link: this.getNotificationLink(msg.data)
            };
            this._alert.notification(payload);
            this.currentMessage.next(msg);
        });
    }

    getNotificationLink(notification) {
        const code = notification.type;
        let link = null;
        switch (code) {
            case 'CNO':
            case 'CCO':
            case 'CPO':
            case 'CFO':
            case 'USO': // Order Detail;
                link = `/outbound-order/sale-order/${notification.referenceId}`;
                break;
            case 'CNOU':
            case 'RJOU':
            case 'APOU': // Outlet Detail
                link = `/outlet/detail/${notification.referenceId}/information`;
                break;
            case 'CNTA':
            case 'RJTA':
            case 'APTA': // Trade Asset Requested Detail
                link = `/trade-assets-request/pending-approval/detail/${notification.referenceId}`;
                break;
            case 'CNPS':
            case 'RJPS':
            case 'APPS': // POSM Requested Detail
                link = `/posm-transaction/pending-approval/${notification.referenceId}`;
                break;
            case 'CNSG':
            case 'RJSG':
            case 'APSG': // Signage Requested Detail
                link = `/signage-request/pending-approval/detail/${notification.referenceId}`;
                break;
            case 'CNSOR':
            case 'CPSOR':
            case 'CCSOR': // Sale Order Return Detail
                // link = '/';
                break;
            case 'CNRPS':
            case 'CCRPS':
            case 'CPRPS':
            case 'CFRPS':
            case 'UPRPS': // Ring Pull Submision Detail
                link = `/ring-pull-submission/${notification.referenceId}`;
                break;
            case 'CNRPR':
            case 'CCRPR':
            case 'CPRPR':
            case 'CFRPR':
            case 'UPRPR': // Ring Pull Redemption Detail
                link = `/ring-pull-redemption/${notification.referenceId}`;
                break;
        }
        return link;
    }
}
